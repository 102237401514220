import { Component, HostBinding, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'sx-content-card-title',
  template: `
    <h3 class="content-card__title">
      <span class="content-card__title-icon" *ngIf="icon">
        <fa-icon [icon]="icon" [fixedWidth]="true"></fa-icon>
      </span>
      <span class="content-card__title-text">
        <ng-content></ng-content>
      </span>
    </h3>
  `,
})
export class ContentCardTitleComponent {
  @HostBinding('class')
  cssClass = 'content-card__title';

  @Input()
  icon: IconDefinition;
}
