import { Component, Input } from '@angular/core';
import { ICONS } from '../../shared/fontawesome.module';

@Component({
  selector: 'sx-candidate-retake-option',
  template: `
    <sx-content-card-inner>
      <sx-content-card-section>
        <sx-content-card-title [icon]="iconTitle">
          {{ 'Exam Retake Option' | translate }}
        </sx-content-card-title>
        <sx-content-card-sub-title>
          {{ 'Exam Retake Option Description' | translate }}
        </sx-content-card-sub-title>
      </sx-content-card-section>
      <sx-content-card-section>
        <sx-ero-form
          [price]="examRetakeOptionPrice"
          [infoUrl]="examRetakeOptionInfoUrl"
          [examTypeId]="examTypeId"
        ></sx-ero-form>
      </sx-content-card-section>
    </sx-content-card-inner>
  `,
})
export class CandidateRetakeOptionComponent {
  @Input() examRetakeOptionPrice: number;
  @Input() examRetakeOptionInfoUrl: string;
  @Input() examTypeId: string;
  iconTitle = ICONS.faSync;
}
