import { Component, HostBinding, Input } from '@angular/core';
import { InfoBox, InfoBoxBox, InfoBoxImage } from '../../core/tenant/tenant';
import { TenantService } from '../../core/tenant/tenant.service';

interface BoxEntry {
  type: 'entry';
  box: InfoBox;
  className: string;
}

interface BoxSpacer {
  type: 'spacer';
  className: string;
}

type Box = BoxEntry | BoxSpacer;

@Component({
  selector: 'sx-info-box',
  template: `
    <div class="container">
      <div class="container__two-cells">
        <div class="container">
          <ng-template ngFor let-box [ngForOf]="boxes">
            <div class="container__one-cell">
              <div [class]="'box box--full-height box--' + box.color">
                <h3 class="box__title">{{ box.title | translate }}</h3>
                <div [innerHTML]="box.content | translate"></div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div *isDesktop class="container__one-cell padding-left">
        <img *ngIf="right" [src]="right.url" [alt]="right.alt | translate" class="img-responsive" />
      </div>
    </div>
  `,
})
export class InfoBoxComponent {
  @HostBinding('class')
  cssClass = 'info-boxes';

  boxes: InfoBoxBox[];

  right: InfoBoxImage;

  constructor(private tenantService: TenantService) {
    this.boxes = tenantService.tenant.infoBoxes;
    this.right = tenantService.tenant.infoBoxImage;
  }
}
