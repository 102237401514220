import { ALL_PAYMENT_METHODS, Tenant, TENANT_ID } from './tenant';
import { PaymentDto } from '../../../../generated/api';
import { COUNTRIES } from '../../util/countries';

export const GOETHE_TENANT: Tenant = {
  paymentMethods: ALL_PAYMENT_METHODS,
  latePaymentMethods: [
    PaymentDto.MethodEnum.CreditCardPostcard,
    PaymentDto.MethodEnum.ExamRetakeOption,
    PaymentDto.MethodEnum.CollectiveInvoice,
  ],
  id: TENANT_ID.GOETHE,
  title: 'Goethe Exams - Official registration',
  tagline: 'Goethe Tagline',
  contactLogoUrl: 'assets/goethe/lo_swex_autorisedprov_goethe.png',
  contactLogoAlt: 'Swiss Exams - Authorised provider for Goethe Exams',
  contactEmail: 'info@swiss-exams.ch',
  contactAddress: `Swiss Exams
Prüfungszentrum des Goethe-Instituts
  
Zürcherstrasse 46
8400 Winterthur`,
  contactPhone: '052 209 04 50',
  contactEmergencyHelpdesk: `0900 884 884 (-.70/min)`,
  entryUrls: [
    'http://localhost:4201',
    'http://localhost:4001',
    'http://goethe-or-test.swiss-exams.ch',
    'https://goethe-or-test.swiss-exams.ch',
    'http://anmeldung-goethe-pruefungen.swiss-exams.ch',
    'https://anmeldung-goethe-pruefungen.swiss-exams.ch',
  ],
  prodUrls: [
    'http://anmeldung-goethe-pruefungen.swiss-exams.ch',
    'https://anmeldung-goethe-pruefungen.swiss-exams.ch',
  ],
  googleTagId: 'GTM-N23CZGB',
  googleTagIdForTest: 'GTM-MH585KTB',
  languages: ['en', 'de'],
  sxLogoUrl: 'assets/goethe/lo_swex_hoch_goethe.png',
  sxLogoAlt: 'Swiss Exams',
  examsLogoUrl: 'assets/goethe/goethe-logo.jpg',
  examsLogoLinkEn: 'https://goethe-pruefungen.swiss-exams.ch/en/exams-and-certifications/goethe',
  examsLogoLinkDe: 'https://goethe-pruefungen.swiss-exams.ch/de/exams-and-certifications/goethe',
  examsLogoLinkFr: 'https://goethe-pruefungen.swiss-exams.ch/fr/exams-and-certifications/goethe',
  examsLogoAlt: 'Goethe Institut',
  footerHelpDeskColumnOne: `
    <strong>Swiss Exams</strong><br>
    Office Winterthur<br>
    Zürcherstrasse 46<br>
    8400 Winterthur
  `,
  requiresPlaceOfBirth: true,
  footerLinks: [
    {
      label: 'swiss-exams.ch',
      url: 'https://swiss-exams.ch',
      openInNewTab: true,
    },
    {
      label: 'Imprint',
      url: 'imprint',
    },
    {
      label: 'Privacy Policy',
      url: 'privacy',
    },
  ],
  footerTermsAndConditions: {
    text: 'Read our General Terms and Conditions, Examination Regulations and Implementation Regulations online. (GOETHE)',
    link: {
      label: 'General Terms and Conditions, Examination Regulations and Implementation Regulations',
      url: 'https://goethe-pruefungen.swiss-exams.ch/de/allgemeine-geschaeftsbedingungen-pruefungsordnungen-und-durchfuehrungsbestimmungen' /* This is currently not used anymore */,
      openInNewTab: true,
    },
  },
  termsAndConditions: [
    {
      label: 'I have read and understood the Terms and Conditions.',
      url: 'https://swiss-exams.ch/sites/default/files/goethe_allgemeine_geschaeftsbedingungen.pdf',
      openInNewTab: true,
    },
    {
      label:
        'I understand that I can only take the exam if I present valid identification on both exam days (written & speaking test).',
      url: 'https://goethe-pruefungen.swiss-exams.ch/exams-and-certifications/goethe/exams/information/identification-id-policy',
      openInNewTab: true,
    },
    {
      label:
        'I hereby confirm that I have read and understood the exam guidelines, implementation regulations and data protection declaration of the Goethe-Institut.',
    },
  ],
  shopSupport: true,
  shopVoucherImageUrl: 'assets/goethe/voucher.png',
  shopVoucherCode: 'GOE19K9N',
  callToActionUrl: 'https://shop.swiss-exams.ch/produkt-kategorie/goethe-deutschpruefungen',
  callToActionLabel: 'I want the best preparation',
  supportedCountries: [...COUNTRIES],
  showSpecialArrangementSection: true,
  showAdditionalInformationOnRegistrationCompletePage: false,
  tenantBasedTranslationsKeys: {
    'School Description': 'School Description Goethe',
  },
  howToRegisterLinkEn:
    'https://goethe-pruefungen.swiss-exams.ch/exams-and-certifications/goethe/registration/how-to-register',
  howToRegisterLinkDe:
    'https://goethe-pruefungen.swiss-exams.ch/de/exams-and-certifications/goethe/registration/how-to-register',
  howToRegisterLinkFr:
    'https://goethe-pruefungen.swiss-exams.ch/fr/exams-and-certifications/goethe/registration/how-to-register',
};
