import { RegistrationDto } from '../../../generated/api';
import { assocPath, path } from 'ramda';
import * as moment from 'moment';

export const paths = [['candidateData', 'personalData', 'dateOfBirth']];

export function sanitizeRegistrationDates(registration: RegistrationDto): RegistrationDto {
  for (const p of paths) {
    let date = path(p, registration);
    if (date) {
      if (!moment.isMoment(date)) {
        date = moment(date);
      }
      const newDate = date.format('YYYY-MM-DD');
      registration = assocPath(p, newDate, registration);
    }
  }
  return registration;
}
