import { Injectable } from '@angular/core';
import {
  UnloadTrackerApi,
  UnloadTrackerRouteGroup,
} from '../shared/unload-tracker/unload-tracker.api';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export function orUnloadTrackerApiFactory(api: OrUnloadTrackerApi) {
  return api;
}

@Injectable()
export class OrUnloadTrackerApi implements UnloadTrackerApi {
  routeGroups: UnloadTrackerRouteGroup[] = [
    {
      paths: ['/candidate', '/summary', '/payment'],
    },
  ];

  constructor(private translateService: TranslateService) {}

  showAppDialog(messages: string[]): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      observer.next(confirm(messages.join('\n')));
      observer.complete();
    });
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
