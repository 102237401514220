import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CET_TIMEZONE } from '.';

@Pipe({
  name: 'sxDateTime',
  pure: true,
})
export class DateTimePipe implements PipeTransform {
  constructor(private date: DatePipe) {}

  transform(value: any, displayWhenNotVailable?: string): any {
    return value
      ? this.date.transform(value, 'dd.MM.yyyy - hh:mm:ss', CET_TIMEZONE)
      : displayWhenNotVailable || '';
  }
}
