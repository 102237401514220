import { Injectable } from '@angular/core';
import { CacheBase } from '../../../util/cache-base';
import { EntrySchoolDto, EntrySchoolsDto, RegistrationApi } from '../../../../../generated/api';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

@Injectable()
export class SchoolsCache extends CacheBase<EntrySchoolDto[], string> {
  constructor(private api: RegistrationApi) {
    super();
  }

  protected fetch(id: string): Observable<EntrySchoolDto[]> {
    return this.api.getSchools().pipe(map((response) => response.schools));
  }

  protected getIdentifier(param: string): string {
    return '';
  }
}
