import { Component, HostBinding } from '@angular/core';
import { ICONS } from '../../shared/fontawesome.module';

@Component({
  selector: 'sx-candidate-billing-address',
  template: `
    <sx-content-card-inner>
      <sx-content-card-section>
        <sx-content-card-title [icon]="iconTitle">
          {{ 'Billing Address' | translate }}
        </sx-content-card-title>
        <sx-content-card-sub-title>
          {{ 'Billing Address Description' | translate }}
        </sx-content-card-sub-title>
      </sx-content-card-section>
      <sx-content-card-section>
        <sx-billing-form></sx-billing-form>
      </sx-content-card-section>
    </sx-content-card-inner>
  `,
})
export class CandidateBillingAddressComponent {
  iconTitle = ICONS.faCreditCard;
}
