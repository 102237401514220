import { Injectable } from '@angular/core';
import { RegistrationApi, RegistrationDto } from '../../../../../generated/api';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RecordDataResolver {
  constructor(private api: RegistrationApi) {}

  resolve(route: ActivatedRouteSnapshot): Observable<RegistrationDto> {
    return this.api.getRecorderRegistration(route.params.recordId, route.params.correlationId);
  }
}
