import { FilterExamsByDto } from '../../../generated/api';
import * as moment from 'moment';

export function createFilterFromObject(obj: Record<string, string>): FilterExamsByDto {
  const filter = { filters: [] };

  if (!obj || !Object.keys(obj).length) {
    return filter;
  }

  filter.filters = Object.keys(obj)
    .filter((key) => !!key && !!obj[key] && typeof obj[key] === 'string')
    .map((key) => {
      const values = obj[key].split(';').map((v) => ({ id: v, name: v }));
      return {
        key,
        values,
      };
    });

  return filter;
}

const localFormat = 'YYYY-MM-DD[T]HH:mm:ss';

export function createFilterFromForm(obj = {}): FilterExamsByDto {
  return {
    filters: Object.keys(obj)
      .filter((key) => !!obj[key])
      .map((key) => {
        let value = obj[key];
        if (moment.isMoment(value)) {
          value = value.format(localFormat);
        }
        const values = Array.isArray(value)
          ? value.filter((v) => !!v).map((v) => ({ id: v, name: v }))
          : [{ id: value, name: value }];

        return {
          key,
          values: values.filter((v) => !!v),
        };
      })
      .filter((v) => v.values.length),
  };
}
