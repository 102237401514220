import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RegistrationExamsResolver } from './providers/registration-exams.resolver';
import { FilterValuesResolver } from './providers/filter-values.resolver';
import { ListViewComponent } from './list.view';
import { InfoBoxComponent } from './components/info-box.component';
import { FilterComponent } from './components/filter.component';
import { ExamsComponent } from './components/exams.component';
import { ListRoutingModule } from './list-routing.module';

const DECLARE_AND_EXPORT = [ListViewComponent];

@NgModule({
  imports: [SharedModule, ListRoutingModule],
  declarations: [...DECLARE_AND_EXPORT, InfoBoxComponent, FilterComponent, ExamsComponent],
  providers: [RegistrationExamsResolver, FilterValuesResolver],
  exports: [...DECLARE_AND_EXPORT],
})
export class ListModule {}
