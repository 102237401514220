import { Tenant } from './tenant';

/**
 * Returns the active tenant based on the entry url and fallback
 * tenant.
 *
 * 1. If a tenant is find based on entry urls, it is taken.
 * 2. Otherwise the tenant matching the fallbackId is taken
 * 3. Otherwise the first tenant is taken, if there is one.
 */
export function findActiveTenant(
  tenants: Tenant[],
  url: string = '',
  fallbackTenant?: string,
): Tenant | undefined {
  let tenant: Tenant;
  if (url) {
    tenant = tenants.find((t: Tenant) => {
      return t.entryUrls.some((entryUrl) => {
        return url.indexOf(entryUrl) === 0;
      });
    });
    if (tenant) {
      return tenant;
    }
  }
  if (!tenant && !url.includes('/health')) {
    console.warn('Tenant not found for url', url);
  }
  if (fallbackTenant) {
    tenant = tenants.find((t: Tenant) => t.id === fallbackTenant);
    if (tenant) {
      return tenant;
    }
  }
  return tenants.length ? tenant[0] : undefined;
}
