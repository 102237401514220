<sx-content-card-title [icon]="titleIcon">{{ 'Payment Method' | translate }} <span class="label--required">*</span></sx-content-card-title>
<sx-content-card-sub-title>
  {{ 'Payment Method Description' | translate }}
</sx-content-card-sub-title>
<form [formGroup]="form" class="margin-top--double">
  <mat-radio-group formControlName="method">
    <div *ngFor="let method of paymentMethods">
      <mat-radio-button [value]="method">
        {{ method | translate }}
      </mat-radio-button>
    </div>
  </mat-radio-group>
  <sx-validation-messages
    [control]="form.controls.method"
    [omitMargin]="true"
  ></sx-validation-messages>
  <mat-expansion-panel
    [expanded]="eroExpanded$ | async"
    [ngClass]="'mat-expansion-panel--frameless'"
  >
    <div class="split margin-top">
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Exam Retake Option Code' | translate }} <span class="label--required">*</span></mat-label>
          <input matInput formControlName="eroCode" />
        </mat-form-field>
        <sx-validation-messages [control]="form.controls.eroCode"></sx-validation-messages>
      </div>
      <div class="split__cell"></div>
    </div>
    <ng-container *ngIf="payForLateFee">
      <div class="info-message info-message--red margin-bottom">
        <div class="info-message__icon">
          <mat-icon>info</mat-icon>
        </div>
        <div class="info-message__content">
          {{
            'The exam results will be published only after you have paid your late entry fee.'
              | translate
          }}
        </div>
      </div>
      <div>{{ 'How do you want to pay for your late entry fee?' | translate }}</div>
      <div class="split margin-top">
        <div class="split__cell">
          <mat-radio-group formControlName="eroLatePaymentMethod">
            <div *ngFor="let method of eroLatePaymentMethods">
              <mat-radio-button [value]="method">
                {{ method | translate }}
              </mat-radio-button>
            </div>
          </mat-radio-group>
          <sx-validation-messages
            [control]="form.controls.eroLatePaymentMethod"
          ></sx-validation-messages>
        </div>
        <div class="split__cell"></div>
      </div>
    </ng-container>
  </mat-expansion-panel>
  <mat-expansion-panel
    [expanded]="collectiveInvoiceCodeExpanded$ | async"
    [ngClass]="'mat-expansion-panel--frameless'"
  >
    <div class="split margin-top">
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Collective Invoice Code' | translate }} <span class="label--required">*</span></mat-label>
          <input matInput formControlName="collectiveInvoiceCode" />
        </mat-form-field>
        <sx-validation-messages
          [control]="form.controls.collectiveInvoiceCode"
        ></sx-validation-messages>
      </div>
      <div class="split__cell"></div>
    </div>
  </mat-expansion-panel>
</form>
