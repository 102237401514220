import { Component, Input } from '@angular/core';
import { RegistrationProcessService } from '../../providers/registration-process.service';
import { FormGroup } from '@ng-stack/forms';
import { Destroyable } from '../../../util/destroyable';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { SpecialArrangementsDto } from '../../../../../generated/api';

@Component({
  selector: 'sx-special-arrangements-form',
  templateUrl: 'special-arrangements-form.component.html',
})
export class SpecialArrangementsFormComponent extends Destroyable {
  @Input() infoUrl: string;
  form: FormGroup<SpecialArrangementsDto>;
  expanded$: Observable<boolean>;

  constructor(private process: RegistrationProcessService) {
    super();
    this.form = process.forms.specialArrangements;
    this.expanded$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((value: SpecialArrangementsDto) => !!value.requiresSpecialArrangements),
      distinctUntilChanged(),
      this.takeUntilDestroyed(),
    );
  }
}
