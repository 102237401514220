import { Injectable } from '@angular/core';
import { CacheBase } from '../../../util/cache-base';
import { EntryReservationDto, RegistrationApi } from '../../../../../generated/api';
import { Observable } from 'rxjs';

@Injectable()
export class ReservationCache extends CacheBase<EntryReservationDto, string> {
  constructor(private api: RegistrationApi) {
    super();
  }

  protected fetch(id: string): Observable<EntryReservationDto> {
    return this.api.getReservation(id);
  }

  protected getIdentifier(param: string): string {
    return param;
  }
}
