import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CandidateViewComponent } from './candidate.view';
import { ExamResolver } from '../shared/providers/resolvers/exam.resolver';
import { EntryProductResolver } from '../shared/providers/resolvers/entry-product.resolver';
import { EntrySchoolsResolver } from '../shared/providers/resolvers/entry-schools.resolver';
import { EntryReservationResolver } from '../shared/providers/resolvers/entry-reservation.resolver';
import { RegistrationInitializedGuard } from '../shared/guards/registration-initialized.guard';
import { UnloadTrackerGuard } from '../shared/unload-tracker/unload-tracker.guard';
import { ConfigInitializedGuard } from '../core/config-initialized.guard';

const ROUTES: Routes = [
  {
    path: 'candidate',
    component: CandidateViewComponent,
    resolve: {
      exam: ExamResolver,
      product: EntryProductResolver,
      schools: EntrySchoolsResolver,
      reservation: EntryReservationResolver,
    },
    canActivate: [ConfigInitializedGuard, RegistrationInitializedGuard],
    canDeactivate: [UnloadTrackerGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class CandidateRoutingModule {}
