/**
 * OrApi
 * Swiss Exams - Microservices - Online Registration Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RegisterResponseDto { 
    redirectUrl?: string;
    redirectTo: RegisterResponseDto.RedirectToEnum;
    recordId: string;
    correlationId: string;
}
export namespace RegisterResponseDto {
    export type RedirectToEnum = 'Result' | 'CreditCardProcessor';
    export const RedirectToEnum = {
        Result: 'Result' as RedirectToEnum,
        CreditCardProcessor: 'CreditCardProcessor' as RedirectToEnum
    };
}


