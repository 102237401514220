<div class="registration-process__back-link">
  <a [routerLink]="['/', 'list']">
    <fa-icon [icon]="backIcon"></fa-icon>
    {{ 'Back to Exams List' | translate }}
  </a>
</div>

<div class="container">
  <div class="container__two-cells">
    <div class="content-card">
      <sx-steps [highlightedStep]="2"></sx-steps>

      <div class="content-card__inner">
        <sx-exam [exam]="exam" [selectedProduct]="(product$ | async)?.name"></sx-exam>
      </div>

      <sx-summary
        [examTypeId]="exam?.examTypeId"
        [examRetakeOptionSupported]="(product$ | async)?.supportsEro"
        [examSubType]="exam?.examSubType"
        [examRetakeOptionPrice]="exam?.retakeOption?.price"
        [hasSpeakingDate]="hasSpeakingDate$ | async"
        [showSpecialArrangementSection]="tenant.showSpecialArrangementSection"
      ></sx-summary>

      <div class="registration-process__action">
        <a [routerLink]="['/', 'candidate']" mat-button color="primary">
          {{ 'Previous' | translate }}
        </a>
        <a [routerLink]="['/', 'payment']" mat-flat-button color="primary" (click)="onSubmit()">
          {{ 'Next' | translate }}
        </a>
      </div>
    </div>
  </div>

  <!-- Sidebar on desktop -->
  <div *isDesktop class="container__one-cell padding-left">
    <sx-sidebar [onlyShowContact]="true"></sx-sidebar>
  </div>

  <!-- Buttons shown on mobile devices only -->
  <div *isBelowDesktop>
    <button
      mat-fab
      color="primary"
      class="mobile-only__fab-button-right"
      aria-label="Information"
      (click)="onShowMobileInfo()"
    >
      <mat-icon>info</mat-icon>
    </button>
  </div>
</div>
