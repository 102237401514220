import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '../../../express.tokens';

@Injectable()
export class LocationService {
  constructor(
    @Optional()
    @Inject(REQUEST)
    private request?,
  ) {}

  getOrigin() {
    if (this.request) {
      const { protocol } = this.request;
      const host = this.request.get('host');
      let port = this.request.get('port');
      port = port && port !== '80' ? `:${port}` : '';
      return `${protocol}://${host}${port}`;
    } else {
      return window.location.origin;
    }
  }
}
