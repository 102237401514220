import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ImprintComponent } from './imprint.component';
import { PrivacyComponent } from './privacy.component';
import { ConfigInitializedGuard } from '../core/config-initialized.guard';

const ROUTES: Routes = [
  {
    path: 'imprint',
    component: ImprintComponent,
    canActivate: [ConfigInitializedGuard],
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    canActivate: [ConfigInitializedGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class StaticPagesRoutingModule {}
