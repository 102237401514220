import { Component, HostListener } from '@angular/core';
import { UnloadTrackerService } from './unload-tracker.service';

@Component({
  selector: 'sx-unload-tracker',
  template: ``,
})
export class UnloadTrackerComponent {
  constructor(private service: UnloadTrackerService) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event) {
    const message = !this.service.dirty ? undefined : this.service.messages.join('\n');
    if (message) {
      event.preventDefault();
      event.returnValue = message;
      return message;
    }
  }
}
