import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { RegistrationExamDto, RegistrationExamProductDto } from '../../../../../generated/api';
import {
  faCalendarDay,
  faClock,
  faCoins,
  faFileAlt,
  faKeyboard,
  faMicrophone,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { formatDate } from '@angular/common';

export interface ExamProduct {
  name: string;
  cssClass: string;
  productId: string;
  productCrmId: string;
  rank: number;
  price: number;
  hasFreeSeats: boolean;
}

@Component({
  selector: 'sx-exam',
  templateUrl: 'exam.component.html',
  styleUrls: ['exam.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExamComponent {
  exam: RegistrationExamDto;
  products: ExamProduct[];
  price: string;
  today = new Date().toISOString();
  hasSpeakingDate = false;
  isLate = false;
  deadline: string | Date;
  isSpeakingOnly = false;

  @Input('exam')
  set _exam(exam: RegistrationExamDto) {
    this.exam = exam;
    this.products = (exam.products || [])
      .map((product: RegistrationExamProductDto) => ({
        cssClass: getProductButtonClass(product),
        name: product.name,
        productId: product.id,
        productCrmId: product.crmId,
        rank: getProductRank(product),
        price: product.price,
        hasFreeSeats: product.hasFreeSeats,
      }))
      .sort((a, b) => a.rank - b.rank);
    this.updatePrice();

    this.deadline = exam.registrationDeadline?.toString().includes('2222-10-28')
      ? undefined
      : exam.registrationDeadline;
    this.isSpeakingOnly =
      (exam.name || '').indexOf('Modul Sprechen') >= 0 ||
      (exam.name || '').indexOf('Expression orale') >= 0 ||
      (exam.name || '').indexOf('- EO') >= 0;
    this.hasSpeakingDate = !this.isSpeakingOnly && !!(exam.speakingDates || []).length;
    this.isLate = moment(exam.registrationDeadline)
      .endOf('day')
      .isBefore(moment(new Date()).endOf('day'));
  }

  selectedProduct: string;

  @Input('selectedProduct')
  set _selectedProduct(selectedProduct: string) {
    this.selectedProduct = selectedProduct;
    switch (selectedProduct) {
      case 'Basic':
        this.selectedProductClass = 'exam__product-label exam__product-label--gold';
        break;
      default:
        this.selectedProductClass = 'exam__product-label exam__product-label--primary';
        break;
    }
    this.updatePrice();
  }

  @Input()
  selectedProductClass: string;

  @HostBinding('class')
  cssClass = 'exam';

  iconWrittenDate = faPen;
  // TODO: Could not find icon from prototype
  iconSpeakingDate = faMicrophone;

  iconPaperBased = faFileAlt;
  iconComputerBased = faKeyboard;

  iconRegistrationDeadline = faCalendarDay;
  iconResultRelease = faClock;
  iconLateEntryFee = faClock;
  iconPrice = faCoins;

  private updatePrice() {
    if (this.selectedProduct) {
      const examProduct = (this.products || []).find((p) => p.name === this.selectedProduct);
      this.price = (examProduct && examProduct.price && `CHF ${examProduct.price}.-`) || '';
    } else {
      this.price = `CHF ${(this.products || [])
        .filter((p) => p.hasFreeSeats)
        .map((p) => p.price + '.-')
        .join(' / ')}`;
    }
  }
}

function getProductButtonClass(product: RegistrationExamProductDto): string {
  switch (product.name) {
    case 'Basic':
      return 'mat-flat-button--gold';
    default:
      return 'mat-flat-button--primary';
  }
}

function getProductRank(product: RegistrationExamProductDto): number {
  switch (product.name) {
    case 'Flex':
      return 0;
    case 'Basic':
      return 1;
    default:
      return 99;
  }
}
