import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sx-content-card',
  template: `
    <div class="content-card__inner">
      <ng-content></ng-content>
    </div>
  `,
})
export class ContentCardComponent {
  @HostBinding('class')
  cssClass = 'content-card';
}
