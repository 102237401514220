import { Component } from '@angular/core';
import { ICONS } from '../shared/fontawesome.module';
import { Title } from '@angular/platform-browser';
import { TenantService } from '../core/tenant/tenant.service';

@Component({
  selector: 'sx-privacy',
  template: `
    <div class="static-page__back-link">
      <a [routerLink]="['/', 'list']">
        <fa-icon [icon]="backIcon"></fa-icon>
        {{ 'Back to Exams List' | translate }}
      </a>
    </div>

    <div [innerHTML]="'Privacy Policy Text' | translate"></div>
    <br />
    <hr />
    <br />
    <div class="ccm-cookie-declaration">
      Bitte aktivieren Sie Javascript, um die Liste aller deklarierten Cookies und ähnlicher
      Techniken zu sehen.
    </div>
  `,
})
export class PrivacyComponent {
  backIcon = ICONS.faArrowLeft;

  constructor(
    private titleService: Title,
    private tenantService: TenantService,
  ) {
    this.setTitle();
  }

  setTitle() {
    if (this.tenantService.isCambridgeTenant) {
      this.titleService.setTitle('Cambridge English Exams - Official registration - privacy');
    } else if (this.tenantService.isTcfTenant) {
      this.titleService.setTitle('TCF Tout Public - Official registration - privacy policy');
    } else if (this.tenantService.isGoetheTenant) {
      this.titleService.setTitle('Goethe Exams - Official registration - privacy policy');
    }
  }
}
