import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function momentMinDateValidator(minDate: Date): ValidatorFn {
  const momentMinDate = moment(minDate);
  return function (control: AbstractControl) {
    const { value } = control;
    if (!value) {
      return value;
    }
    if (!moment.isMoment(value)) {
      return;
    }
    return momentMinDate.isAfter(value)
      ? {
          momentMinDate: {
            minDate: momentMinDate.format('DD.MM.YYYY'),
            actualDate: value.format('DD.MM.YYYY'),
          },
        }
      : undefined;
  };
}
