import { Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { RegistrationDataViewFacade } from '../../shared/providers/registration-data.view-facade';
import { RegistrationProcessService } from '../../shared/providers/registration-process.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import {
  RegistrationExamProductDto,
  RegistrationDto,
  RegistrationExamDto,
  PaymentDto,
} from '../../../../generated/api';
import { ICONS } from '../../shared/fontawesome.module';

export interface PaymentItem {
  position: string;
  currency: string;
  price: number;
  annotation?: string;
}

@Component({
  selector: 'sx-price',
  templateUrl: 'price.component.html',
  providers: [
    //    RegistrationDataViewFacade,
  ],
})
export class PriceComponent {
  readonly titleIcon = ICONS.faMoneyBill;

  readonly items$: Observable<PaymentItem[]>;
  readonly total$: Observable<PaymentItem>;
  readonly showAnnotation$: Observable<boolean>;

  constructor(
    private facade: RegistrationDataViewFacade,
    private process: RegistrationProcessService,
  ) {
    this.items$ = combineLatest([facade.exam$, facade.product$, process.registration$]).pipe(
      map(calculateItems),
      shareReplay({ refCount: true }),
    );
    this.showAnnotation$ = this.items$.pipe(
      map((items) => items.some((item) => !!item.annotation)),
    );
    this.total$ = this.items$.pipe(map(sumUpItems), shareReplay({ refCount: true }));
  }
}

function calculateItems([exam, product, registration]: [
  RegistrationExamDto,
  RegistrationExamProductDto,
  RegistrationDto,
]): PaymentItem[] {
  const items: PaymentItem[] = [];
  if (product) {
    items.push({
      position: 'Exam Fee',
      currency: 'CHF',
      price: product.price,
    });
    if (registration?.payment?.method === PaymentDto.MethodEnum.ExamRetakeOption) {
      items.push({
        position: 'Exam Retake Option Code',
        currency: 'CHF',
        price: -product.price,
      });
    }
    if (exam.isLate && exam.lateEntryFee) {
      items.push({
        position: 'Late Entry Fee',
        currency: 'CHF',
        price: exam.lateEntryFee,
        annotation: '*',
      });
    }
    if (
      registration.candidateData &&
      registration.candidateData.ero &&
      registration.candidateData.ero.eroSelected &&
      exam.retakeOption
    ) {
      // TODO: What to do if retake option is not present?
      items.push({
        position: 'Exam Retake Option',
        currency: 'CHF',
        price: exam.retakeOption.price,
      });
    }
    return items;
  }
}

function sumUpItems(items: PaymentItem[]): PaymentItem {
  return items.reduce(
    (acc, item) => ({
      ...acc,
      price: acc.price + item.price,
    }),
    {
      position: 'Total',
      currency: 'CHF',
      price: 0,
    },
  );
}
