import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function momentMaxDateValidator(maxDate: Date): ValidatorFn {
  const momentMaxDate = moment(maxDate);
  return function (control: AbstractControl) {
    const { value } = control;
    if (!value) {
      return value;
    }
    if (!moment.isMoment(value)) {
      return;
    }
    return momentMaxDate.isBefore(value)
      ? {
          momentMaxDate: {
            maxDate: momentMaxDate.format('DD.MM.YYYY'),
            actualDate: value.format('DD.MM.YYYY'),
          },
        }
      : undefined;
  };
}
