<form [formGroup]="form">
  <div class="checkbox-wrapper">
    <mat-checkbox
      formControlName="requiresSpecialArrangements"
      color="primary"
      name="requiresSpecialArrangements"
    >
      {{ 'I require Special Arrangements' | translate }}
    </mat-checkbox>
    <a *ngIf="infoUrl" class="info-icon-button" [href]="infoUrl" target="_blank">
      <mat-icon color="primary">info</mat-icon>
    </a>
  </div>

  <mat-expansion-panel [expanded]="expanded$ | async" [ngClass]="'mat-expansion-panel--frameless'">
    <div class="margin-top">
      <mat-form-field>
        <mat-label>{{ 'Comment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="specialArrangementsComment"
          formControlName="comment"
          mat-autosize
          matAutosizeMaxRows="6"
        ></textarea>
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.comment"></sx-validation-messages>
    </div>
  </mat-expansion-panel>
</form>
