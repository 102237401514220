import { NgModule } from '@angular/core';
import { PageNotFoundRoutingModule } from './page-not-found-routing.module';
import { PageNotFoundViewComponent } from './page-not-found.view';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [PageNotFoundRoutingModule, SharedModule],
  declarations: [PageNotFoundViewComponent],
})
export class PageNotFoundModule {}
