import { Component } from '@angular/core';
import { TenantService } from '../../core/tenant/tenant.service';
import { ICONS } from '../../shared/fontawesome.module';

@Component({
  selector: 'sx-candidate-school',
  template: `
    <sx-content-card-inner>
      <sx-content-card-section>
        <sx-content-card-title [icon]="iconTitle">{{ 'School' | translate }}</sx-content-card-title>
        <sx-content-card-sub-title>
          {{ schoolDescription | translate }}
        </sx-content-card-sub-title>
      </sx-content-card-section>
      <sx-content-card-section>
        <sx-school-form></sx-school-form>
      </sx-content-card-section>
    </sx-content-card-inner>
  `,
})
export class CandidateSchoolComponent {
  constructor(private tenantService: TenantService) {}

  get schoolDescription(): string {
    return this.tenantService.tenant.tenantBasedTranslationsKeys['School Description'];
  }

  iconTitle = ICONS.faSchool;
}
