<sx-app-server *ngIf="isServer"></sx-app-server>
<header class="root__header">
  <mat-toolbar>
    <mat-toolbar-row ngClass="root__upper-row">
      <div class="root__brand">
        <a [href]="examsLogoLink">
          <img
            class="logo img-responsive"
            [src]="tenant?.examsLogoUrl"
            [alt]="tenant?.examsLogoAlt"
            [title]="tenant?.examsLogoAlt"
          />
        </a>
      </div>
      <div class="root__spacer"></div>
      <div class="root__brand">
        <a [href]="sxLogoLink" title="Swiss Exams Website">
          <img
            class="logo img-responsive"
            [src]="tenant?.sxLogoUrl"
            [alt]="tenant?.sxLogoAlt"
            [title]="tenant?.sxLogoAlt"
          />
        </a>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row ngClass="root__lower-row">
      <div class="root__spacer"></div>
      <div class="root__language" *ngIf="tenant?.languages.length && tenant?.languages.length > 1">
        <ng-template ngFor let-language let-index="index" [ngForOf]="tenant.languages">
          <ng-template [ngIf]="index > 0">|</ng-template>
          <a
            class="root__language__button"
            [ngClass]="{ active: userLang === language }"
            [routerLink]="[]"
            (click)="onChangeLanguage(language)"
          >
            {{ language | translate }}
          </a>
        </ng-template>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<header ngClass="root__header--hidden">
  <mat-toolbar>
    <mat-toolbar-row ngClass="root__upper-row">
      <div class="root__brand">
        <img
          class="logo img-responsive"
          [src]="tenant?.examsLogoUrl"
          [alt]="tenant?.examsLogoAlt"
          [title]="tenant?.examsLogoAlt"
        />
      </div>
      <div class="root__spacer"></div>
      <div class="root__brand">
        <img
          class="logo img-responsive"
          [src]="tenant?.sxLogoUrl"
          [alt]="tenant?.sxLogoAlt"
          [title]="tenant?.sxLogoAlt"
        />
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row ngClass="root__lower-row">
      <div class="root__spacer"></div>
      <div class="root__language" *ngIf="tenant?.languages.length && tenant?.languages.length > 1">
        <ng-template ngFor let-language let-index="index" [ngForOf]="tenant.languages">
          <ng-template [ngIf]="index > 0">|</ng-template>
          <a
            class="root__language__button"
            [ngClass]="{ active: userLang === language }"
            [routerLink]="[]"
            (click)="onChangeLanguage(language)"
          >
            {{ language | translate }}
          </a>
        </ng-template>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<main [@routeAnimations]="prepareRoute(outlet)" class="root__main">
  <router-outlet #outlet="outlet"></router-outlet>
</main>

<footer class="root__footer">
  <sx-footer></sx-footer>
</footer>
