import { Component } from '@angular/core';
import { RegistrationProcessService } from '../../providers/registration-process.service';
import { FormGroup } from '@ng-stack/forms';
import { Destroyable } from '../../../util/destroyable';
import { Observable } from 'rxjs';
import { EntrySchoolDto, SchoolDataDto } from '../../../../../generated/api';
import { RegistrationDataViewFacade } from '../../providers/registration-data.view-facade';

@Component({
  selector: 'sx-school-form',
  templateUrl: 'school-form.component.html',
})
export class SchoolFormComponent extends Destroyable {
  form: FormGroup<SchoolDataDto>;
  schools$: Observable<EntrySchoolDto[]>;

  constructor(
    private process: RegistrationProcessService,
    private data: RegistrationDataViewFacade,
  ) {
    super();
    this.form = process.forms.school;
    this.schools$ = data.schools$;
  }
}
