import { Component, Input } from '@angular/core';
import { ICONS } from '../../shared/fontawesome.module';
import { ExamTypeProvider } from 'src/app/shared/providers/exam-type-provider';
import { ExamTypes } from 'src/app/util/exam-types';

@Component({
  selector: 'sx-summary',
  templateUrl: 'summary.component.html',
})
export class SummaryComponent {
  @Input() examRetakeOptionSupported = false;
  @Input() examRetakeOptionPrice: number;
  @Input() examSubType: string;
  @Input() hasSpeakingDate: boolean;
  @Input() showSpecialArrangementSection: boolean;

  @Input('examTypeId')
  set _examTypeId(examTypeId: string) {
    this.examTypeId = examTypeId;
    this.isLinguaskillExam =
      new ExamTypeProvider().getExamTypeById(examTypeId) === ExamTypes.LINGUASKILL;
  }

  iconTitle = ICONS.faCheckCircle;
  iconSpeaking = ICONS.faMicrophone;
  iconSchool = ICONS.faSchool;
  iconSpecialArrangements = ICONS.faWheelchair;
  iconPersonal = ICONS.faFingerprint;
  iconEro = ICONS.faSync;
  iconBilling = ICONS.faCreditCard;
  examTypeId: string;
  isLinguaskillExam: boolean;
}
