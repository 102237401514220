<form [formGroup]="form">
  <div>
    <mat-checkbox
      formControlName="billingAddressDiffers"
      color="primary"
      name="billingAddressDiffers"
    >
      {{ 'Billing Address is different from Personal Address' | translate }}
    </mat-checkbox>
  </div>
  <mat-expansion-panel [expanded]="expanded$ | async" [ngClass]="'mat-expansion-panel--frameless'">
    <div class="split margin-top" formGroupName="address">
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Company Name' | translate }}</mat-label>
          <input matInput name="billingCompanyName" formControlName="companyName" />
        </mat-form-field>
        <sx-validation-messages
          [control]="addressForm.controls.companyName"
        ></sx-validation-messages>
      </div>
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Salutation' | translate }} <span class="label--required">*</span></mat-label>
          <mat-select name="billingSalutation" formControlName="salutation">
            <mat-option *ngFor="let salutation of salutations" [value]="salutation">
              {{ '' + salutation | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <sx-validation-messages
          [control]="addressForm.controls.salutation"
        ></sx-validation-messages>
      </div>
    </div>

    <div class="split" formGroupName="address">
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'First Name' | translate }} <span class="label--required">*</span></mat-label>
          <input matInput name="billingFirstName" formControlName="firstName" />
        </mat-form-field>
        <sx-validation-messages [control]="addressForm.controls.firstName"></sx-validation-messages>
      </div>
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Last Name' | translate }} <span class="label--required">*</span></mat-label>
          <input matInput name="billingLastName" formControlName="lastName" />
        </mat-form-field>
        <sx-validation-messages [control]="addressForm.controls.lastName"></sx-validation-messages>
      </div>
    </div>

    <div class="split margin-top" formGroupName="address">
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Street / NR' | translate }} <span class="label--required">*</span></mat-label>
          <input matInput name="billingStreet" formControlName="street" />
        </mat-form-field>
        <sx-validation-messages [control]="addressForm.controls.street"></sx-validation-messages>
      </div>
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Additional Address Line' | translate }}</mat-label>
          <input matInput name="billingStreetAddition" formControlName="streetAddition" />
        </mat-form-field>
        <sx-validation-messages
          [control]="addressForm.controls.streetAddition"
        ></sx-validation-messages>
      </div>
    </div>
    <div class="split" formGroupName="address">
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Postal Code' | translate }} <span class="label--required">*</span></mat-label>
          <input matInput name="billingPostalCode" formControlName="postalCode" />
        </mat-form-field>
        <sx-validation-messages
          [control]="addressForm.controls.postalCode"
        ></sx-validation-messages>
      </div>
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'City' | translate }} <span class="label--required">*</span></mat-label>
          <input matInput name="billingCity" formControlName="city" />
        </mat-form-field>
        <sx-validation-messages [control]="addressForm.controls.city"></sx-validation-messages>
      </div>
    </div>
    <div class="split">
      <div class="split__cell" formGroupName="address">
        <mat-form-field>
          <mat-label>{{ 'Country' | translate }} <span class="label--required">*</span></mat-label>
          <mat-select formControlName="country" name="salutation">
            <mat-option [value]="''"></mat-option>
            <mat-option *ngFor="let country of countries" [value]="country">
              {{ 'Countries.' + country | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <sx-validation-messages [control]="addressForm.controls.country"></sx-validation-messages>
      </div>
      <div class="split__cell"></div>
    </div>
  </mat-expansion-panel>
</form>
