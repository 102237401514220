import { Component, HostBinding } from '@angular/core';
import { ICONS } from '../../shared/fontawesome.module';
import { TenantService } from '../../core/tenant/tenant.service';

@Component({
  selector: 'sx-candidate-special-arrangements',
  template: `
    <sx-content-card-inner>
      <sx-content-card-section>
        <sx-content-card-title [icon]="iconTitle">
          {{ 'Special Arrangements' | translate }}
        </sx-content-card-title>
        <sx-content-card-sub-title>
          {{ 'Special Arrangements Description' | translate }}
        </sx-content-card-sub-title>
      </sx-content-card-section>
      <sx-content-card-section>
        <sx-special-arrangements-form
          [infoUrl]="specialArrangementInfoUrl"
        ></sx-special-arrangements-form>
      </sx-content-card-section>
    </sx-content-card-inner>
  `,
})
export class CandidateSpecialArrangementsComponent {
  iconTitle = ICONS.faWheelchair;
  specialArrangementInfoUrl: string;

  constructor(private tenantService: TenantService) {
    this.specialArrangementInfoUrl = tenantService.tenant.specialArrangementsInfoUrl;
  }
}
