/**
 * OrApi
 * Swiss Exams - Microservices - Online Registration Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TenantFilterValueDto } from './tenantFilterValueDto';


export interface TenantFilterValuesDto { 
    key: string;
    name: string;
    type: TenantFilterValuesDto.TypeEnum;
    filterValues?: Array<TenantFilterValueDto>;
}
export namespace TenantFilterValuesDto {
    export type TypeEnum = 'Select' | 'MultiSelect' | 'Date' | 'DateRange' | 'Placeholder';
    export const TypeEnum = {
        Select: 'Select' as TypeEnum,
        MultiSelect: 'MultiSelect' as TypeEnum,
        Date: 'Date' as TypeEnum,
        DateRange: 'DateRange' as TypeEnum,
        Placeholder: 'Placeholder' as TypeEnum
    };
}


