import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { RegistrationExamSpeakingDateDto, SpeakingTestDto } from '../../../../../generated/api';
import { FormGroup } from '@ng-stack/forms';
import { RegistrationProcessService } from '../../providers/registration-process.service';
import { RegistrationDataViewFacade } from '../../providers/registration-data.view-facade';
import { distinctUntilChanged, map, shareReplay, startWith, take } from 'rxjs/operators';
import { TenantService } from '../../../core/tenant/tenant.service';
import { ExamTypes } from 'src/app/util/exam-types';
import { ExamTypeProvider } from 'src/app/shared/providers/exam-type-provider';

@Component({
  selector: 'sx-speaking-form',
  templateUrl: 'speaking-form.component.html',
})
export class SpeakingFormComponent {
  readonly speakingDates$: Observable<RegistrationExamSpeakingDateDto[]>;
  examTypes: typeof ExamTypes = ExamTypes;
  examType$: Observable<ExamTypes>;
  form: FormGroup<SpeakingTestDto>;
  expanded$: Observable<boolean>;

  @Input()
  readonly = false;

  constructor(
    private process: RegistrationProcessService,
    private facade: RegistrationDataViewFacade,
    private examTypeProvider: ExamTypeProvider,
    public tenantService: TenantService,
  ) {
    this.form = this.process.forms.speakingTest;
    this.speakingDates$ = this.facade.exam$.pipe(
      map((exam) => (exam && exam.speakingDates) || []),
      shareReplay(1),
    );

    this.examType$ = this.facade.exam$.pipe(
      map((exam) => this.examTypeProvider.getExamTypeById(exam.examTypeId)),
    );

    this.expanded$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((value: SpeakingTestDto) => !!value.desiredTime),
      distinctUntilChanged(),
    );
  }
}
