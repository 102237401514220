import { Injectable } from '@angular/core';
import { ValidatorFn } from '@ng-stack/forms';
import { CountryProvider } from '../../../shared/providers/countries/country-provider';

@Injectable({ providedIn: 'root' })
export class NationalityValidator {
  constructor(private countryProvider: CountryProvider) {}

  public getValidator(): ValidatorFn {
    return (control) => {
      if (!this.countryProvider.isCountry(control.value)) {
        return { invalidNationality: 'isNotSelectedCountry' };
      }
      return null;
    };
  }
}
