import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import {
  ExamsApi,
  FilterExamsByDto,
  RegistrationExamsPageDto,
  TenantFiltersDto,
  TenantFilterValuesDto,
} from '../../../generated/api';
import { first, map, mergeMap, pluck, takeUntil } from 'rxjs/operators';
import { Destroyable } from '../util/destroyable';
import { createFilterFromForm, createFilterFromObject } from './create-filter-from-object';
import { TenantService } from '../core/tenant/tenant.service';
import { DOCUMENT } from '@angular/common';
import { ExamCache } from '../shared/providers/cache/exam.cache';
import { FilterService } from '../shared/providers/filter.service';
import { flatten } from 'ramda';

@Injectable()
export class ListViewFacade extends Destroyable {
  loading = -1;

  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  private readonly pagesSubject = new BehaviorSubject<RegistrationExamsPageDto[]>([]);
  readonly pages$ = this.pagesSubject.asObservable().pipe(
    map((pages) =>
      pages.map((page) => ({
        ...page,
        // Filter down to display only exams that have a product with free seats
        exams: page.exams.filter((exam) => exam.products.some((product) => product.hasFreeSeats)),
      })),
    ),
  );

  readonly currentPage$: Observable<number> = this.pages$.pipe(
    map((pages) => (pages && pages.length ? pages[pages.length - 1].pageNumber : -1)),
  );

  private _fetchingNextPage = false;

  get fetchingNextPage() {
    return this._fetchingNextPage;
  }

  set fetchingNextPage(fetchingNextPage) {
    this._fetchingNextPage = fetchingNextPage;
    this.isLoading$.next(fetchingNextPage);
  }

  private hasNextPage = true;

  constructor(
    private route: ActivatedRoute,
    private api: ExamsApi,
    private examCache: ExamCache,
    private tenantService: TenantService,
    @Inject(DOCUMENT) private document: Document,
    private filter: FilterService,
  ) {
    super();
    this.route.data
      .pipe(pluck('firstPage'), this.takeUntilDestroyed())
      .subscribe((page) => this.pagesSubject.next([page]));
    this.route.data
      .pipe(pluck('filters'), this.takeUntilDestroyed())
      .subscribe((filters) => (this.filter.filters = filters));

    combineLatest([this.filter.filters$, this.route.queryParams])
      .pipe(map(createQueryFilters), this.takeUntilDestroyed())
      .subscribe((queryFilters) => {
        this.filter.queryFilters = queryFilters;
        this.filter.activeFilters = createFilterFromObject(queryFilters);
      });
    this.filter.filterChanged$
      .pipe(this.takeUntilDestroyed())
      .subscribe(this.onFilterChanged.bind(this));
  }

  cancel$ = new Subject<any>();

  onFilterChanged(filters: any) {
    this.loading = -1;
    this.cancel$.next(null);
    this.filter.activeFilters = createFilterFromForm(filters);
    this.api.listExams(0, createFilterFromForm(filters)).subscribe((page) => {
      this.cancel$.next(null);
      this.hasNextPage = true;
      this.pagesSubject.next([page]);
    });
  }

  onScrolledOrResized() {
    if (!this.tenantService.isBrowser) {
      return;
    }
    if (!this.hasNextPage || this.fetchingNextPage) {
      return;
    }
    const screenHeight = document.documentElement.clientHeight;
    const preloadMargin = screenHeight * 1.2;
    const bounds = document.querySelector('#lazy-load-hint').getBoundingClientRect();
    const { top } = bounds;
    const loadPosition = screenHeight - top + preloadMargin;
    const shouldUpdate = loadPosition > 0;
    if (shouldUpdate) {
      this.fetchNextPage();
    }
  }

  fetchNextPage() {
    this.fetchingNextPage = true;
    combineLatest([this.currentPage$, this.filter.activeFilters$])
      .pipe(
        first(),
        mergeMap(([currentPage, filters]) => {
          return this.api.listExams(currentPage + 1, filters);
        }),
        takeUntil(this.cancel$),
      )
      .subscribe((page) => {
        this.pagesSubject.next([...this.pagesSubject.getValue(), page]);
        page.exams.forEach((exam) => this.examCache.populate(exam.id, exam));
        this.fetchingNextPage = false;
        if (!page.exams.length) {
          this.hasNextPage = false;
        }
      });
  }
}

function createQueryFilters([filters, queryParams]: [TenantFiltersDto, any]) {
  queryParams = queryParams || {};
  const allFilters = flatten(
    filters.filterRows.map((row) => row.filterValues),
  ) as TenantFilterValuesDto[];
  return allFilters.reduce(
    (filterQuery, filter) => ({
      ...filterQuery,
      [filter.key]: queryParams[filter.key] || '',
    }),
    {},
  );
}
