import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  FilterExamsByDto,
  TenantFiltersDto,
  TenantFilterValuesDto,
} from '../../../../generated/api';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { flatten } from 'ramda';
import * as moment from 'moment';

@Injectable()
export class FilterService {
  private readonly filtersSubject = new BehaviorSubject<TenantFiltersDto>({
    filterRows: [],
  });

  readonly filters$ = this.filtersSubject.asObservable();

  set filters(filters: TenantFiltersDto) {
    this.filtersSubject.next(filters);
  }

  get filters() {
    return this.filtersSubject.getValue();
  }

  private readonly queryFiltersSubject = new BehaviorSubject<any>({});
  readonly queryFilters$ = this.queryFiltersSubject.asObservable();

  set queryFilters(queryFilters: any) {
    this.queryFiltersSubject.next(queryFilters);
  }

  get queryFilters() {
    return this.queryFiltersSubject.getValue();
  }

  private activeFiltersSubject = new BehaviorSubject<FilterExamsByDto>({ filters: [] });
  readonly activeFilters$ = this.activeFiltersSubject.asObservable();

  set activeFilters(filters: FilterExamsByDto) {
    this.activeFiltersSubject.next(filters);
  }

  get activeFilters() {
    return this.activeFiltersSubject.getValue();
  }

  private filterChangedSubject = new Subject<any>();
  readonly filterChanged$ = this.filterChangedSubject.asObservable();

  private created = false;

  readonly form = new UntypedFormGroup({});

  onFilterChanged(value) {
    // If date(s) exists, validate it against allowed formats, before sending api request
    if (value.examDateFrom || value.examDateTo) {
      if (
        (value.examDateFrom && this.isValidDateFormat(value.examDateFrom._i)) ||
        (value.examDateTo && this.isValidDateFormat(value.examDateTo._i))
      ) {
        this.filterChangedSubject.next(value);
      }
    } else {
      this.filterChangedSubject.next(value);
    }
  }

  setupForm([filters, queryFilters]: [TenantFiltersDto, any]) {
    if (this.created) {
      return;
    }
    Object.keys(this.form.controls).forEach((key) => this.form.removeControl(key));
    flatten(filters.filterRows.map((row) => row.filterValues)).forEach((filter) =>
      this.form.addControl(filter.key, createFilterControl(filter, queryFilters)),
    );
    this.created = true;
  }

  private isValidDateFormat(date): boolean {
    // If datepicker is used directly, convert object to string date
    if (date?.year) {
      date = `${date.date}.${date.month + 1}.${date.year}`;
    }

    // Matches all combinations of dates with a point as separator
    const dateRegex = new RegExp(/(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.\d{2,4}/);

    return dateRegex.test(date);
  }
}

export function createFilterControl(
  filter: TenantFilterValuesDto,
  queryFilters: any,
): AbstractControl {
  switch (filter.type) {
    case TenantFilterValuesDto.TypeEnum.MultiSelect:
      const labels = queryFilters[filter.key].split(';');
      const values = labels
        .map((l) => {
          if (!!l && filter.key === 'examDate') {
            l = l.slice(0, -4).concat('%year');
          }
          return filter.filterValues.find((fv) => fv.name === l)?.id;
        })
        .filter((id) => !!id);
      return new UntypedFormControl(values);
    case TenantFilterValuesDto.TypeEnum.DateRange:
      /*
      return new FormGroup({
        // TODO: Populate filter
        start: new FormControl(''),
        end: new FormControl(''),
      });
       */
      return new UntypedFormControl(null);
    default:
      return new UntypedFormControl(queryFilters[filter.key] || '');
  }
}
