import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  filter,
  first,
  map,
  pluck,
  shareReplay,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import {
  RegistrationExamProductDto,
  EntryReservationDto,
  EntrySchoolDto,
  RegistrationExamDto,
} from '../../../../generated/api';
import { RegistrationProcessService } from './registration-process.service';
import { Destroyable } from '../../util/destroyable';
import { NotificationService } from './notification.service';
import * as moment from 'moment';
import { path, assocPath } from 'ramda';

/**
 * This view facade is used in the registration views in order to retrieve
 * the exam, product and reservation if applicable.
 */
@Injectable({ providedIn: 'root' })
export class RegistrationDataViewFacade extends Destroyable {
  // TODO: Maybe this should all be moved to registration process service...
  readonly exam$: Observable<RegistrationExamDto>;
  readonly product$: Observable<RegistrationExamProductDto>;
  readonly reservation$: Observable<EntryReservationDto>;
  readonly schools$: Observable<EntrySchoolDto[]>;
  private readonly selectedProductSubject = new Subject<RegistrationExamProductDto>();

  constructor(
    private route: ActivatedRoute,
    private process: RegistrationProcessService,
    private router: Router,
    private notification: NotificationService,
  ) {
    super();
    const data$ = route.data.pipe(shareReplay({ refCount: true }));
    this.exam$ = data$.pipe(
      pluck('exam'),
      tap((exam) =>
        this.process.updateValidation(
          exam && !!(exam.speakingDates || []).length,
          exam.lateEntryFee > 0 && exam.isLate,
        ),
      ),
      shareReplay({ refCount: true }),
    );

    const { method } = this.process.forms.payment.controls;
    method.valueChanges
      .pipe(
        startWith(method.value),
        withLatestFrom(this.exam$),
        map(([_, exam]) => exam),
      )
      .subscribe((exam) => this.process.updateLatePaymentMethodValidation(exam.isLate));

    this.product$ = merge(data$.pipe(pluck('product')), this.selectedProductSubject).pipe(
      shareReplay({ refCount: true }),
    );
    this.reservation$ = data$.pipe(pluck('reservation'), shareReplay({ refCount: true }));
    this.schools$ = data$.pipe(pluck('schools'), shareReplay({ refCount: true }));

    combineLatest([this.exam$, this.product$, this.reservation$])
      .pipe(first())
      .subscribe(this.validate.bind(this));

    this.reservation$
      .pipe(
        filter((r) => !!r),
        first(),
        this.takeUntilDestroyed(),
      )
      .subscribe((reservation) => {
        const schoolForm = this.process.forms.school;
        schoolForm.controls.schoolCrmId.setValue(reservation.schoolCrmId);
        schoolForm.controls.teacherFirstName.setValue(reservation.teacherFirstName || '');
        schoolForm.controls.teacherLastName.setValue(reservation.teacherLastName || '');
      });

    const candidate = process.registration && process.registration.candidateData;
    if (candidate && (!candidate.speaking || !candidate.speaking.interviewDateCrmId)) {
      // Only initialize if we don't have data yet
      this.exam$.pipe(first(), this.takeUntilDestroyed()).subscribe((exam) => {
        const firstTest = exam.speakingDates[0];
        if (firstTest) {
          process.forms.speakingTest.controls.interviewDateCrmId.setValue(firstTest.crmId);
        }
      });
    }
  }

  changeProductTo(product: RegistrationExamProductDto) {
    this.selectedProductSubject.next(product);
    this.process.changeProduct(product.crmId);
  }

  changeEroOptionTo(isSelected: boolean) {
    const eroPath = ['candidateData', 'ero', 'eroSelected'];
    const { registration } = this.process;
    const isSelectedNow = path(eroPath, registration);
    if (isSelectedNow !== isSelected) {
      this.process.forms.ero.controls.eroSelected.setValue(isSelected);
      // this.process.registration = assocPath(eroPath, isSelected, registration);
    }
  }

  private validate([exam, product, reservation]: [
    RegistrationExamDto,
    RegistrationExamProductDto,
    EntryReservationDto,
  ]) {
    if (
      exam.finalRegistrationDeadline &&
      moment(exam.finalRegistrationDeadline)
        .endOf('day')
        .isBefore(moment(moment.now()).startOf('day'))
    ) {
      this.process.tracker.dirty = false;
      this.notification.show(
        'The registration deadline for the exam you selected has passed.',
        false,
      );
      this.router.navigate(['/', 'list']);
      return;
    }

    // Even if there are no seats for the product, when there is a reservation with available seats the user can register
    if (!reservation && !product.hasFreeSeats) {
      this.process.tracker.dirty = false;
      this.notification.show('The exam you selected has no seats left.', false);
      this.router.navigate(['/', 'list']);
      return;
    }
    if (reservation) {
      if (reservation.registrationComplete) {
        this.process.tracker.dirty = false;
        this.notification.show('The reservation is already closed.', false);
        this.router.navigate(['/', 'list']);
        return;
      }
      if (!reservation.hasFreeSeats) {
        this.process.tracker.dirty = false;
        this.notification.show('Your reservation has no seats left.', false);
        this.router.navigate(['/', 'list']);
        return;
      }
    }
  }
}
