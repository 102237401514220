import { Inject, Injectable, Optional } from '@angular/core';
import { Destroyable } from '../../util/destroyable';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { UnloadTracker } from './unload-tracker';
import { map, mergeMap, shareReplay, startWith, tap, withLatestFrom } from 'rxjs/operators';
import {
  DefaultUnloadTrackerApi,
  UNLOAD_TRACKER_API_TOKEN,
  UnloadTrackerApi,
} from './unload-tracker.api';

@Injectable()
export class UnloadTrackerService extends Destroyable {
  readonly dirty$: Observable<boolean>;
  private isDirty: boolean = false;

  get dirty() {
    return this.isDirty;
  }

  readonly messages$: Observable<string[]>;
  private messagesValue: string[] = [];

  get messages() {
    return this.messagesValue;
  }

  private readonly trackersSubject = new BehaviorSubject<UnloadTracker[]>([]);
  readonly trackers$ = this.trackersSubject.asObservable();

  get trackers() {
    return this.trackersSubject.getValue();
  }

  set trackers(trackers: UnloadTracker[]) {
    this.trackersSubject.next(trackers);
  }

  constructor(
    @Inject(UNLOAD_TRACKER_API_TOKEN)
    @Optional()
    private readonly api: UnloadTrackerApi,
  ) {
    super();
    this.api = this.api || new DefaultUnloadTrackerApi();
    const isAnyTrackerDirty$ = this.trackers$.pipe(
      map((trackers) => trackers.map((tracker) => tracker.dirty$)),
      mergeMap((allTrackers$) => {
        if (!allTrackers$.length) {
          return of([]);
        }
        return combineLatest([...allTrackers$]);
      }),
      map((dirtyList) => dirtyList.some((isDirty) => isDirty)),
      shareReplay({ refCount: true }),
    );
    this.dirty$ = isAnyTrackerDirty$.pipe(
      // TODO: Set to false, true is for testing
      startWith(true),
    );

    this.messages$ = combineLatest([isAnyTrackerDirty$, this.trackers$]).pipe(
      map(([_, allTrackers]) => {
        return allTrackers
          .filter((tracker) => tracker.dirty)
          .map((tracker) => this.api.translate(tracker.message));
      }),
      shareReplay({ refCount: true }),
    );

    this.dirty$.pipe(this.takeUntilDestroyed()).subscribe((isDirty) => (this.isDirty = isDirty));
    this.messages$
      .pipe(this.takeUntilDestroyed())
      .subscribe((messages) => (this.messagesValue = messages));
  }

  register(tracker: UnloadTracker) {
    this.trackers = [...this.trackers, tracker];
  }

  unregister(tracker: UnloadTracker) {
    this.trackers = this.trackers.filter((existing) => existing !== tracker);
  }
}
