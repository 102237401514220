import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'sx-confirmation-dialog',
  template: `
    <sx-content-card>
      <sx-content-card-title>{{ data.title || 'Confirm' }}</sx-content-card-title>
      <div mat-dialog-content>
        <p [innerHTML]="data.message | translate"></p>
      </div>
      <mat-divider class="dialog__divider margin-top--double"></mat-divider>
      <div class="dialog__actions">
        <button mat-button (click)="onNoClick()">{{ 'Cancel' | translate }}</button>
        <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>
          {{ 'OK' | translate }}
        </button>
      </div>
    </sx-content-card>
  `,
})
export class ConfirmationDialogComponent {
  @HostBinding('class')
  cssClass = 'dialog';

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmationDialogData,
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }
}
