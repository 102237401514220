import { TranslateLanguageService } from '../translate/translate-language.service';
import { RegistrationProcessService } from './registration-process.service';
import { LocalStorageService } from './local-storage.service';
import { BottomSheetService } from './bottom-sheet.service';
import { DialogService } from './dialog.service';
import { FilterService } from './filter.service';
import { NotificationService } from './notification.service';
import { ExamCache } from './cache/exam.cache';
import { ProductCache } from './cache/product.cache';
import { ReservationCache } from './cache/reservation.cache';
import { SchoolsCache } from './cache/schools.cache';
import { ExamResolver } from './resolvers/exam.resolver';
import { EntryProductResolver } from './resolvers/entry-product.resolver';
import { EntryReservationResolver } from './resolvers/entry-reservation.resolver';
import { EntrySchoolsResolver } from './resolvers/entry-schools.resolver';
import { RecordDataResolver } from './resolvers/record-data.resolver';
import { RegistrationInitializedGuard } from '../guards/registration-initialized.guard';
import { EntryValidationGuard } from '../guards/entry-validation.guard';
import { LocationService } from './location.service';
import { RegistrationDataViewFacade } from './registration-data.view-facade';

export const providers = [
  TranslateLanguageService,
  RegistrationProcessService,
  LocalStorageService,
  BottomSheetService,
  DialogService,
  FilterService,
  NotificationService,
  LocationService,
  // RegistrationDataViewFacade,
  // Caches
  ExamCache,
  ProductCache,
  ReservationCache,
  SchoolsCache,
  // Resolvers
  ExamResolver,
  EntryProductResolver,
  EntryReservationResolver,
  EntrySchoolsResolver,
  RecordDataResolver,
  // Guards
  RegistrationInitializedGuard,
  EntryValidationGuard,
];
