<form [formGroup]="form" autocomplete="off">
  <div class="filter__heading">
    <fa-icon [icon]="faFilter"></fa-icon>
    Filter
    <a
      class="filter__permalink"
      [href]="linkToFilter$ | async"
      *ngIf="linkToFilter$ | async"
      target="_blank"
    >
      <fa-icon [icon]="permalinkIcon"></fa-icon>
      {{ 'Link to this filter selection' | translate }}
    </a>
    <span class="filter__heading__spacer"></span>
    <button mat-button color="primary" type="button" (click)="onClearFilters()">
      {{ 'clear' | translate }}
    </button>
  </div>
  <div class="filter__filters" *ngFor="let row of (filters$ | async)?.filterRows">
    <div class="filter__filter" *ngFor="let filter of row?.filterValues">
      <ng-container [ngSwitch]="filter.type">
        <mat-form-field *ngSwitchCase="FilterTypes.MultiSelect">
          <mat-label>{{ filter.name | translate }}</mat-label>
          <mat-select
            [formControlName]="filter.key"
            [multiple]="true"
            [matTooltip]="getJoinedMultiselectFilterValueNames(filter)"
            matTooltipPosition="below"
          >
            <mat-option *ngFor="let v of filter.filterValues" [value]="v.id">
              {{ v.name | translate: v.translationParams }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="FilterTypes.Select">
          <mat-label>{{ filter.name | translate }}</mat-label>
          <mat-select [formControlName]="filter.key">
            <mat-option *ngFor="let v of filter.filterValues" [value]="v.id">
              {{ v.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngSwitchCase="FilterTypes.Date">
          <mat-label>{{ filter.name | translate }}</mat-label>
          <input
            matInput
            [name]="filter.key"
            [formControlName]="filter.key"
            [matDatepicker]="datePicker"
          />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <div *ngSwitchCase="FilterTypes.Placeholder"></div>
      </ng-container>
    </div>
  </div>
  <div class="mobile-only">
    <a
      class="filter__permalink-mobile"
      [href]="linkToFilter$ | async"
      *ngIf="linkToFilter$ | async"
    >
      <fa-icon [icon]="permalinkIcon"></fa-icon>
      {{ 'Link to this filter selection' | translate }}
    </a>
    <button mat-button color="primary" type="button" (click)="onClearFilters()">
      {{ 'clear' | translate }}
    </button>
  </div>
</form>
