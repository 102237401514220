import { Injectable } from '@angular/core';
import { SelectItem } from '../utils/select-item-interface';

@Injectable({ providedIn: 'root' })
export class EroLevelProvider {
  getLevels(): Array<SelectItem> {
    return [
      { value: undefined, viewValue: '' },
      { value: 'B1', viewValue: 'B1' },
      { value: 'B2', viewValue: 'B2' },
      { value: 'C1', viewValue: 'C1' },
      { value: 'C2', viewValue: 'C2' },
    ];
  }
}
