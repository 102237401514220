import { Component } from '@angular/core';
import { ICONS } from '../shared/fontawesome.module';
import { Title } from '@angular/platform-browser';
import { TenantService } from '../core/tenant/tenant.service';

@Component({
  selector: 'sx-imprint',
  template: `
    <div class="static-page__back-link">
      <a [routerLink]="['/', 'list']">
        <fa-icon [icon]="backIcon"></fa-icon>
        {{ 'Back to Exams List' | translate }}
      </a>
    </div>

    <h3>{{ 'Contact Address' | translate }}</h3>
    <p class="static-page__paragraph">
      Swiss Exams GmbH
      <br />
      Z&uuml;rcherstrasse 46
      <br />
      8400 Winterthur
      <br />
      Schweiz
    </p>
    <p class="static-page__paragraph">
      <a href="mailto:info@swiss-exams.ch" target="_blank">info&#64;swiss-exams.ch</a>
    </p>
    <p class="static-page__paragraph">
      <span>{{ 'Contact person' | translate }}: Peter Kaithan</span>
    </p>

    <div [innerHTML]="'Imprint Text' | translate"></div>
  `,
})
export class ImprintComponent {
  constructor(
    private titleService: Title,
    private tenantService: TenantService,
  ) {
    this.setTitle();
  }

  backIcon = ICONS.faArrowLeft;

  setTitle() {
    if (this.tenantService.isCambridgeTenant) {
      this.titleService.setTitle('Cambridge English Exams - Official registration - imprint');
    } else if (this.tenantService.isTcfTenant) {
      this.titleService.setTitle('TCF Tout Public - Official registration - imprint');
    } else if (this.tenantService.isGoetheTenant) {
      this.titleService.setTitle('Goethe Exams - Official registration - imprint');
    }
  }
}
