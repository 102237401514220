import { Injectable } from '@angular/core';
import { CacheBase } from '../../../util/cache-base';
import { ExamsApi, RegistrationApi, RegistrationExamDto } from '../../../../../generated/api';
import { Observable } from 'rxjs';

@Injectable()
export class ExamCache extends CacheBase<RegistrationExamDto, string> {
  constructor(private api: ExamsApi) {
    super();
  }

  protected fetch(id: string): Observable<RegistrationExamDto> {
    return this.api.getExam(id);
  }

  protected getIdentifier(param: string): string {
    return param;
  }
}
