import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormControl, FormGroup } from '@ng-stack/forms';

export interface ValidationErrorData {
  errors: any;
  control: string;
  path: string;
}

export function collectValidationErrors(
  control: AbstractControl,
  currentKey = '',
  parentPath = '',
): ValidationErrorData[] {
  if (control instanceof UntypedFormGroup || control instanceof UntypedFormGroup) {
    let errors: ValidationErrorData[] = [];
    const keys = Object.keys(control.controls);
    for (const key of keys) {
      const nextKey = key;
      const isFormControl =
        control.controls[key] instanceof UntypedFormControl ||
        control.controls[key] instanceof UntypedFormControl;
      const nextPath = [];
      if (parentPath) {
        nextPath.push(parentPath);
      }
      if (!isFormControl) {
        nextPath.push(key);
      }
      errors = [
        ...errors,
        ...collectValidationErrors(control.controls[key], key, nextPath.join('.')),
      ];
    }
    return errors;
  } else if (control instanceof UntypedFormControl || control instanceof UntypedFormControl) {
    if (control.errors) {
      return [{ control: currentKey, path: parentPath, errors: control.errors }];
    }
  }
  return [];
  // TODO: FormArray not handled, as not required yet
}
