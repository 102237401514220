import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RegistrationApi } from '../../generated/api';
import { Observable } from 'rxjs';

interface QueryMapperOptions {
  name: string;
  value: string;
}

type QueryMapper = (options: QueryMapperOptions) => object;
const identicalQueryMapper: QueryMapper = ({ name, value }) => ({
  [name]: value,
});

const levelMapping = {
  // Cambridge
  KET: 'A2 Key',
  PET: 'B1 Preliminary',
  FCE: 'B2 First',
  CAE: 'C1 Advanced',
  CPE: 'C2 Proficiency',
  'BEC P': 'B1 Business Preliminary',
  'BEC V': 'B2 Business Vantage',
  'BEC H': 'C1 Business Higher',
  'KET S': 'A2 Key for Schools',
  'PET S': 'B1 Preliminary for Schools',
  'FCE S': 'B2 First for Schools',
  'YLE Starters': 'Pre A1 Young Learners Starters',
  'YLE Movers': 'A1 Young Learners Movers',
  'YLE Flyers': 'A2 Young Learners Flyers',
  // Goethe
  'A1 Fit Jugendliche': 'Goethe-Zertifikat A1: Fit in Deutsch (Jugendliche)',
  'A1 Start Erwachsene': 'Goethe-Zertifikat A1: Start Deutsch (Erwachsene)',
  'A2 Erwachsene': 'Goethe-Zertifikat A2',
  'A2 Fit Jugendliche': 'Goethe-Zertifikat A2: Fit in Deutsch (Jugendliche)',
  B1: 'Goethe-Zertifikat B1',
  'B1 Jugendliche': 'Goethe-Zertifikat B1 (Jugendliche)',
  'B1: Hören': 'Goethe-Zertifikat B1: Modul Hören',
  'B1: Hören Jugendliche': 'Goethe-Zertifikat B1: Modul Hören (Jugendliche)',
  'B1: Lesen': 'Goethe-Zertifikat B1: Modul Lesen',
  'B1: Lesen Jugendliche': 'Goethe-Zertifikat B1: Modul Lesen (Jugendliche)',
  'B1: Schreiben': 'Goethe-Zertifikat B1: Modul Schreiben',
  'B1: Schreiben Jugendliche': 'Goethe-Zertifikat B1: Modul Schreiben (Jugendliche)',
  'B1: Sprechen': 'Goethe-Zertifikat B1: Modul Sprechen',
  'B1: Sprechen Jugendliche': 'Goethe-Zertifikat B1: Modul Sprechen (Jugendliche)',
  B2: 'Goethe-Zertifikat B2',
  C1: 'Goethe-Zertifikat C1',
  C2: 'Goethe-Zertifikat C2',
};
const locationMapping = {
  zürich: 'Zürich',
  winterthur: 'Winterthur',
  chur: 'Chur',
  baden: 'Baden',
  basel: 'Basel',
  bern: 'Bern',
  bronschhofen: 'Bronschhofen',
  brugg: 'Brugg',
  bülach: 'Bülach',
  disentis: 'Disentis',
  engadin: 'Engadin',
  frauenfeld: 'Frauenfeld',
  kreuzlingen: 'Kreuzlingen',
  langenthal: 'Langenthal',
  lausanne: 'Lausanne',
  liechtenstein: 'Liechtenstein',
  luzern: 'Luzern',
  'pfäffikon sz': 'Pfäffikon SZ',
  samedan: 'Samedan',
  schiers: 'Schiers',
  siebnen: 'Siebnen',
  'st. gallen': 'St. Gallen',
  sursee: 'Sursee',
  thun: 'Thun',
  vaduz: 'Vaduz',
  weinfelden: 'Weinfelden',
  wetzikon: 'Wetzikon',
  wohlen: 'Wohlen',
};
const mappings: Record<string, QueryMapper> = {
  level: ({ name, value }) => {
    if (!value) {
      return undefined;
    }
    const values = value
      .split(',')
      .map((level) => levelMapping[level])
      .filter((level) => !!level)
      .join(';');
    return { level: values };
  },
  from: ({ name, value }) => ({ examDateFrom: value }),
  to: ({ name, value }) => ({ examDateTo: value }),
  location: ({ name, value }) => ({ location: locationMapping[value] }),
};

@Injectable()
export class LegacyFilterGuard {
  constructor(
    private router: Router,
    private api: RegistrationApi,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): UrlTree | Observable<UrlTree> {
    const queryParams = mapQueryParams(route.queryParams, mappings);
    return this.router.createUrlTree(['/', 'list'], {
      queryParams: {
        ...route.queryParams,
        ...queryParams,
      },
    });
  }
}

function mapQueryParams(queryParams: object, queryParamMappings: Record<string, QueryMapper>) {
  const qp = Object.keys(queryParams)
    .map((name) => ({ name, value: queryParams[name] }))
    .filter(({ name }) => queryParamMappings[name])
    .reduce(
      (query, { name, value }) => ({
        ...query,
        ...queryParamMappings[name]({ name, value }),
      }),
      {},
    );
  return qp;
}

function removeDash20Suffix(name: string): string {
  if (!name || typeof name !== 'string') {
    return undefined;
  }
  if (name.indexOf('_20') > 0) {
    return name.substr(0, name.length - 3);
  }
  return name;
}
