import { BehaviorSubject } from 'rxjs';

export class UnloadTracker {
  private readonly dirtySubject = new BehaviorSubject<boolean>(false);
  readonly dirty$ = this.dirtySubject.asObservable();

  get dirty() {
    return this.dirtySubject.getValue();
  }

  set dirty(dirty: boolean) {
    this.dirtySubject.next(dirty);
  }

  private readonly messageSubject = new BehaviorSubject<string>('');
  readonly message$ = this.messageSubject.asObservable();

  get message() {
    return this.messageSubject.getValue();
  }

  set message(message: string) {
    this.messageSubject.next(message);
  }

  constructor(message: string) {
    this.message = message;
  }
}
