import { Inject, Injectable, Optional } from '@angular/core';
import {
  DefaultUnloadTrackerApi,
  UNLOAD_TRACKER_API_TOKEN,
  UnloadTrackerApi,
  UnloadTrackerRouteGroup,
} from './unload-tracker.api';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TrackedComponent } from './tracked-component';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const true$ = of(true);

@Injectable()
export class UnloadTrackerGuard {
  constructor(
    @Inject(UNLOAD_TRACKER_API_TOKEN)
    @Optional()
    private readonly api: UnloadTrackerApi,
  ) {
    this.api = this.api || new DefaultUnloadTrackerApi();
  }

  canDeactivate(
    component: TrackedComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    if (!component.unloadTracker) {
      return true;
    }

    const groups = this.api.routeGroups;
    const currentGroup: UnloadTrackerRouteGroup = (groups || []).find((group) =>
      group.paths.some((path) => path === currentState.url),
    );

    if (currentGroup && currentGroup.paths.some((path) => path === nextState.url)) {
      return true;
    }

    const stream$ = combineLatest([
      component.unloadTracker.dirty$,
      component.unloadTracker.messages$,
    ]).pipe(
      switchMap(([dirty, messages]) => {
        if (!dirty) {
          return true$;
        }
        return this.api.showAppDialog(component.unloadTracker.messages);
      }),
    );

    return stream$;
  }
}
