import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSchool,
  faUserTie,
  faUserFriends,
  faUsers,
  faExternalLinkAlt,
  faInfoCircle,
  faFilter,
  faPen,
  faChartBar,
  faEnvelope,
  faMicrophone,
  faMicrophoneAlt,
  faCreditCard,
  faSync,
  faFingerprint,
  faWheelchair,
  faCheckCircle,
  faCheck,
  faFileAlt,
  faMoneyBill,
  faArrowLeft,
  faGift,
  faExclamation,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

const IMPORT_AND_EXPORT = [FontAwesomeModule];

// Add an icon to the library for convenient access in other components
export const ICONS = {
  faUserTie,
  faUserFriends,
  faSchool,
  faUsers,
  faExternalLinkAlt,
  faInfoCircle,
  faFilter,
  faPen,
  faChartBar,
  faEnvelope,
  faMicrophone,
  faMicrophoneAlt,
  faCreditCard,
  faSync,
  faFingerprint,
  faWheelchair,
  faCheckCircle,
  faCheck,
  faFileAlt,
  faMoneyBill,
  faArrowLeft,
  faGift,
  faExclamation,
  faExclamationTriangle,
};
const icons = Object.keys(ICONS).map((key) => ICONS[key]);
@NgModule({
  declarations: [],
  imports: [...IMPORT_AND_EXPORT],
  exports: [...IMPORT_AND_EXPORT],
})
export class FontawesomeModule {
  constructor() {
    library.add(...icons);
  }
}
