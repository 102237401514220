import { Inject, Injectable, Optional } from '@angular/core';
import { RegistrationExamDto } from '../../../../../generated/api';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrationProcessService } from '../registration-process.service';
import { ExamCache } from '../cache/exam.cache';
import { REQUEST } from '../../../../express.tokens';

const EMPTY_EXAM: RegistrationExamDto = {
  retakeOption: {
    price: 0,
    name: '',
    id: '',
    crmId: '',
    supportsEro: false,
  },
  isLate: false,
  speakingDates: [],
  registrationDeadline: null,
  locationName: '',
  mainDate: '',
  crmId: '',
  currency: 'CHF',
  id: '',
  lateEntryCurrencyName: '',
  lateEntryFee: 0,
  name: '',
  products: [],
  resultRelease: '',
  type: '',
  examSubType: '',
  finalRegistrationDeadline: '',
  openFrom: '',
  examTypeId: '',
};

@Injectable()
export class ExamResolver {
  constructor(
    private cache: ExamCache,
    private process: RegistrationProcessService,
    @Optional()
    @Inject(REQUEST)
    private request,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<RegistrationExamDto> | RegistrationExamDto {
    // In SSR we want to resolve an empty object so the page can render
    if (this.request) {
      return EMPTY_EXAM;
    }
    return this.cache.get(this.process.registration.examData.examCrmId);
  }
}
