import { Injectable } from '@angular/core';
import { ExamTypes } from '../../util/exam-types';

@Injectable({ providedIn: 'root' })
export class ExamTypeProvider {
  private static readonly LINGUASKILL_TYPE_ID: string = 'b91602c6-e782-ea11-a811-000d3aa874dc';
  private static readonly REGULAR_EXAM_TYPE_ID: string = 'ebd266fd-e2e8-4b69-ae76-0be174b12011';
  private static readonly GOETHE_TYPE_ID: string = 'ee173feb-a9fd-e611-80ff-5065f38bd241';
  private static readonly TCF_TYPE_ID: string = '02ed27a6-a4f1-e911-a984-000d3a3a88e6';
  private static readonly YLE_EXAM_TYPE: string = 'a9393122-a8d5-4996-8787-ea1b36b2876c';

  getExamTypeById(id: string): ExamTypes {
    switch (id) {
      case ExamTypeProvider.LINGUASKILL_TYPE_ID:
        return ExamTypes.LINGUASKILL;
      case ExamTypeProvider.REGULAR_EXAM_TYPE_ID:
        return ExamTypes.REGULAR_EXAM;
      case ExamTypeProvider.GOETHE_TYPE_ID:
        return ExamTypes.GOETHE;
      case ExamTypeProvider.TCF_TYPE_ID:
        return ExamTypes.TCF;
      case ExamTypeProvider.YLE_EXAM_TYPE:
        return ExamTypes.YLE_EXAM;
      default:
        return undefined;
    }
  }
}
