import { Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Tenant } from '../../../core/tenant/tenant';
import { TenantService } from '../../../core/tenant/tenant.service';
import { ICONS } from '../../fontawesome.module';
@Component({
  selector: 'sx-sidebar',
  template: `
    <div class="content-card__inner">
      <div class="sidebar__section" *ngIf="onlyShowContact === false">
        <div class="sidebar__section-content">
          <h3 class="sidebar__title">
            <span class="sidebar__title-icon">
              <fa-icon [icon]="statsIcon"></fa-icon>
            </span>
            <span class="sidebar__title-text">
              {{ 'Upcoming Examsof the next 3 months' | translate }}
            </span>
          </h3>
        </div>
      </div>
      <div class="sidebar__section" *ngIf="onlyShowContact === false">
        <div class="sidebar__section-content">
          <sx-sidebar-stats></sx-sidebar-stats>
        </div>
      </div>
      <div class="sidebar__section">
        <div class="sidebar__section-content">
          <div class="sidebar__section-content__assistance-button__wrapper">
            <a
              [href]="howToRegisterLink"
              target="_blank"
              color="primary"
              class="mat-focus-indicator mat-button mat-flat-button mat-button-base mat-primary sidebar__section-content__assistance-button"
            >
              {{ 'How to register' | translate }}
            </a>
          </div>
          <div class="sidebar__section-content__assistance-button__wrapper">
            <a
              [href]="blockRegistrationLink"
              target="_blank"
              color="primary"
              class="mat-focus-indicator mat-button mat-flat-button mat-button-base mat-primary sidebar__section-content__assistance-button"
            >
              {{ 'Block Registration' | translate }}
            </a>
          </div>
          <div class="sidebar__section-content__assistance-button__wrapper">
            <a
              [href]="assistanceLink"
              target="_blank"
              color="primary"
              class="mat-focus-indicator mat-button mat-flat-button mat-button-base mat-primary sidebar__section-content__assistance-button"
            >
              {{ 'Assistance for Candidates' | translate }}
            </a>
          </div>

          <div class="sidebar__section-content__assistance-button__wrapper">
            <a
              [href]="assistanceLinkForSchoolsAndTeachers"
              target="_blank"
              color="primary"
              class="mat-focus-indicator mat-button mat-flat-button mat-button-base mat-primary sidebar__section-content__assistance-button"
            >
              {{ 'Assistance for Schools and Teachers' | translate }}
            </a>
          </div>
        </div>
      </div>
      <div class="sidebar__section">
        <div class="sidebar__section-content">
          <div *ngIf="tenant?.contactLogoUrl" class="sidebar__section-content-part">
            <img
              [src]="tenant?.contactLogoUrl"
              [alt]="tenant?.contactLogoAlt"
              class="img-responsive"
            />
          </div>
          <div *ngIf="tenant?.contactAddress" class="sidebar__section-content-part">
            <span class="white-space--pre-wrap">{{ tenant.contactAddress }}</span>
          </div>
          <div *ngIf="tenant?.contactEmergencyHelpdesk" class="sidebar__section-content-part">
            <div>{{ 'Is your exam day today?' | translate }}</div>
            <div>{{ 'After-Hours & Weekends Emergency Help-Desk' | translate }}</div>
            <span class="white-space--pre-wrap">{{ tenant.contactEmergencyHelpdesk }}</span>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class SidebarComponent {
  @HostBinding('class')
  cssClass = 'sidebar content-card content-card--long-foot';

  // TODO: We also need to make sure that we only load the stats (for the chart) if it is visible
  @Input()
  onlyShowContact = false;

  assistanceLink = environment.assistanceLink;
  blockRegistrationLink = environment.blockRegistrationLink;

  statsIcon = ICONS.faChartBar;
  contactIcon = ICONS.faEnvelope;

  tenant: Tenant;
  contactLogoUrl: SafeUrl;

  constructor(
    private tenantService: TenantService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
  ) {
    const { tenant } = tenantService;
    this.tenant = tenant;
    if (tenant.contactLogoUrl) {
      this.contactLogoUrl = sanitizer.bypassSecurityTrustUrl(tenant.contactLogoUrl);
    }
  }

  get assistanceLinkForSchoolsAndTeachers(): string {
    if (this.translateService.currentLang === 'de') {
      return environment.assistanceLinkForSchoolsAndTeachersDe;
    }

    return environment.assistanceLinkForSchoolsAndTeachersEn;
  }

  get howToRegisterLink(): string {
    switch (this.translateService.currentLang) {
      case 'de':
        return this.tenant.howToRegisterLinkDe;
      case 'en':
        return this.tenant.howToRegisterLinkEn;
      case 'fr':
        return this.tenant.howToRegisterLinkFr;
      default:
        return this.tenant.howToRegisterLinkEn;
    }
  }
}
