import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST, RESPONSE } from '../../../express.tokens';
import * as url from 'url';
import { environment } from '../../../environments/environment';
import { findActiveTenant } from './find-active-tenant';
import { Tenant, TENANT_ID } from './tenant';
import { TENANTS } from './tenants';

@Injectable({ providedIn: 'root' })
export class TenantService {
  readonly isBrowser: boolean;
  readonly tenant: Tenant;
  readonly message: string;
  readonly isProduction: boolean;
  readonly targetUrl: string;
  readonly host: string;

  constructor(
    @Optional() @Inject(REQUEST) private request: any,
    @Optional() @Inject(RESPONSE) private response: any,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    const message = [`Platform Id: ${platformId}`, `Request: ${!!request}`];

    this.isBrowser = isPlatformBrowser(platformId);
    const targetUrl = this.isBrowser ? getUrlFromLocation() : getUrlFromRequest(request);
    this.targetUrl = targetUrl;
    this.host = this.isBrowser ? '/' : getHostFromRequest(request);

    message.push(`Url: ${targetUrl}`);
    this.tenant = findActiveTenant(TENANTS, targetUrl, environment.fallbackTenant);
    this.message = message.join('\n');
    this.isProduction = this.tenant.prodUrls.some((prodUrl) => targetUrl.indexOf(prodUrl) === 0);
  }

  get isGoetheTenant(): boolean {
    return this.isTenant(TENANT_ID.GOETHE);
  }

  get isTcfTenant(): boolean {
    return this.isTenant(TENANT_ID.TCF);
  }

  get isCambridgeTenant(): boolean {
    return this.isTenant(TENANT_ID.CAMBRIDGE);
  }

  private isTenant(tenantId: TENANT_ID): boolean {
    return this.tenant.id === tenantId;
  }
}

export function getHostFromRequest(req) {
  return url.format({
    protocol: req.protocol,
    host: req.get('host'),
  });
}

export function getUrlFromRequest(req) {
  // TODO: Port does not work. Need to check how to actually get the port number
  // from an express request
  /*
  const port = req.port && req.port !== '80'
    ? `:${req.port}`
    : '';
  return `${req.protocol}://${req.hostname}${port}${req.originalUrl}`;
   */
  return url.format({
    protocol: req.protocol,
    host: req.get('host'),
    pathname: req.originalUrl,
  });
}

export function getUrlFromLocation() {
  const port = location.port && location.port !== '80' ? `:${location.port}` : '';
  return `${location.protocol}//${location.hostname}${port}${location.pathname}`;
}
