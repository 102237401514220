import { NgModule } from '@angular/core';
import { UnloadTrackerComponent } from './unload-tracker.component';
import { UnloadTrackerGuard } from './unload-tracker.guard';

@NgModule({
  imports: [],
  declarations: [UnloadTrackerComponent],
  providers: [UnloadTrackerGuard],
  exports: [UnloadTrackerComponent],
})
export class UnloadTrackerModule {}
