import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { EroDialogComponent, EroDialogData } from '../components/dialogs/ero-dialog/ero.dialog';

@Injectable()
export class DialogService {
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  openEroDialog(data: EroDialogData): Observable<boolean> {
    return this.dialog
      .open(EroDialogComponent, {
        data,
        minWidth: 300,
      })
      .afterClosed();
  }

  confirm(title: string, message: string): Observable<boolean> {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: this.translate.instant(title),
          message: this.translate.instant(message),
        },
      })
      .afterClosed()
      .pipe(map((value) => !!value));
  }
}
