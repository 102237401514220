import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RegistrationApi } from '../../../generated/api';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

@Injectable()
export class LegacyRedirectGuard {
  constructor(
    private router: Router,
    private api: RegistrationApi,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): UrlTree | Observable<UrlTree | boolean> | true {
    const q = route.queryParams;
    const { examId } = q;
    const { productId } = q;
    const legacySchoolId = q.schoolReservation;

    if (!examId) {
      console.warn('examId not provided.');
      return true;
    }
    if (!productId) {
      console.warn('productId not provided.');
      return true;
    }

    if (!legacySchoolId) {
      return this.router.createUrlTree(['register', examId, productId]);
    }

    return of(true).pipe(
      // TODO: This fix is due to application not waiting for initialization
      delay(1500),
      switchMap(() => this.api.getReservationForLegacyLink(examId, legacySchoolId)),
      map((reservation) =>
        this.router.createUrlTree(['register', examId, productId, reservation.crmId]),
      ),
      catchError(() => of(true)),
    );
  }
}
