import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import {
  MEDIA_QUERY_DESKTOP,
  MEDIA_QUERY_PHONE,
  MEDIA_QUERY_TABLET,
} from './directives/media-queries';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  readonly isPhone$: Observable<boolean>;
  readonly isTablet$: Observable<boolean>;
  readonly isDesktop$: Observable<boolean>;
  readonly isBelowDesktop$: Observable<boolean>;
  readonly isAbovePhone$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isPhone$ = this.breakpointObserver.observe(MEDIA_QUERY_PHONE).pipe(
      map((state: BreakpointState) => state.matches),
      distinctUntilChanged(),
      shareReplay({ refCount: true }),
    );

    this.isAbovePhone$ = this.isPhone$.pipe(
      map((value) => !value),
      distinctUntilChanged(),
      shareReplay({ refCount: true }),
    );

    this.isTablet$ = this.breakpointObserver.observe(MEDIA_QUERY_TABLET).pipe(
      map((state: BreakpointState) => state.matches),
      distinctUntilChanged(),
      shareReplay({ refCount: true }),
    );

    this.isDesktop$ = this.breakpointObserver.observe(MEDIA_QUERY_DESKTOP).pipe(
      map((state: BreakpointState) => state.matches),
      distinctUntilChanged(),
      shareReplay({ refCount: true }),
    );

    this.isBelowDesktop$ = this.isDesktop$.pipe(
      map((value) => !value),
      distinctUntilChanged(),
      shareReplay({ refCount: true }),
    );
  }
}
