import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { RegistrationCompleteViewComponent } from './registration-complete.view';
import { ConfigInitializedGuard } from '../core/config-initialized.guard';

const ROUTES: Routes = [
  {
    path: 'complete/:correlationId',
    component: RegistrationCompleteViewComponent,
    canActivate: [ConfigInitializedGuard],
  },
];
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class CompleteRoutingModule {}
