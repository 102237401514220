import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  success(message: string) {
    this.snackBar.open(this.translate.instant(message), null, {
      duration: 5000,
      panelClass: 'success',
    });
  }

  info(message: string) {
    this.snackBar.open(this.translate.instant(message), null, {
      duration: 5000,
    });
  }

  error(message: string) {
    this.snackBar.open(this.translate.instant(message), null, {
      duration: 5000,
      panelClass: 'error',
    });
  }

  show(message: string, success: boolean) {
    if (success) {
      this.success(message);
    } else {
      this.error(message);
    }
  }
}
