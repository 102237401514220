import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { pipes } from './pipes';

const IMPORT_AND_EXPORT = [
  MatSidenavModule,
  MatToolbarModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatSelectModule,
  MatCheckboxModule,
  MatInputModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatButtonModule,
  MatBottomSheetModule,
  MatExpansionModule,
  MatRadioModule,
  MatDialogModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatAutocompleteModule,
];

@NgModule({
  imports: [...IMPORT_AND_EXPORT],
  providers: [
    DatePipe,
    MatMomentDateModule,
    { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
    ...pipes,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
  exports: [...IMPORT_AND_EXPORT],
})
export class MaterialModule {}
