import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, pluck, tap } from 'rxjs/operators';
import { RegistrationExamsPageDto } from '../../../../generated/api';
import { TenantService } from '../../core/tenant/tenant.service';
import { ICONS } from '../../shared/fontawesome.module';
import { FilterService } from '../../shared/providers/filter.service';
import { Destroyable } from '../../util/destroyable';
import { ListViewFacade } from '../list.view-facade';
import { TENANT_ID } from '../../core/tenant/tenant';

@Component({
  selector: 'sx-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss'],
})
export class ExamsComponent extends Destroyable {
  pages$: Observable<RegistrationExamsPageDto[]>;
  hasPages$: Observable<boolean>;
  isExamFilterIELTS = false;

  alertIcon = ICONS.faExclamationTriangle;
  schoolIcon = ICONS.faSchool;
  candidateIcon = ICONS.faUsers;

  today = new Date().toISOString();
  isCambridgeTenant = false;

  constructor(
    private facade: ListViewFacade,
    private tenantService: TenantService,
    private filterService: FilterService,
    private router: ActivatedRoute,
  ) {
    super();
    this.isCambridgeTenant = this.tenantService.tenant.id === TENANT_ID.CAMBRIDGE;
    this.pages$ = this.facade.pages$;
    this.hasPages$ = this.pages$.pipe(map((pages) => pages.some((page) => !!page.exams.length)));

    this.checkIfIELTSIsSelected();
  }

  private checkIfIELTSIsSelected(): void {
    this.isExamFilterIELTS = this.urlParamsContainsIELTS();

    this.filterService.form.valueChanges
      .pipe(
        pluck('level'),
        filter((examFilterIds) => !!examFilterIds),
        tap((examFilterIds: string[]) => {
          this.isExamFilterIELTS = this.fetchIELTSFilterId(examFilterIds);
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe();
  }

  private urlParamsContainsIELTS(): boolean {
    const urlQueryParams = this.router.snapshot.queryParams as {
      level?: string;
    };

    if (urlQueryParams?.level) {
      const examCategories = urlQueryParams.level.split(';');
      return examCategories.includes('IELTS');
    }

    return false;
  }

  private fetchIELTSFilterId(examFilterIds: string[]): boolean {
    const PROD_IELTS_ID = 'f8f922ff-50fe-ea11-a813-000d3aa874dc';
    const TEST_IELTS_ID = 'ac04280c-176a-eb11-a812-000d3abb7951';

    return [PROD_IELTS_ID, TEST_IELTS_ID].some((id) => examFilterIds.includes(id));
  }
}
