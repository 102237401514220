import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { InitViewComponent } from './init.view';
import { ConfigInitializedGuard } from '../core/config-initialized.guard';
import { LegacyRedirectGuard } from './legacy-redirect.guard';
import { RedirectFailedComponent } from './redirect-failed.component';

const ROUTES: Routes = [
  {
    path: 'register/:examId/:productId/:reservationId',
    component: InitViewComponent,
    canActivate: [ConfigInitializedGuard],
  },
  {
    path: 'register/:examId/:productId',
    component: InitViewComponent,
    canActivate: [ConfigInitializedGuard],
  },
  {
    path: 'en-US/Registration/OralExam',
    canActivate: [LegacyRedirectGuard],
    component: RedirectFailedComponent,
  },
  {
    path: 'en/Registration/OralExam',
    canActivate: [LegacyRedirectGuard],
    component: RedirectFailedComponent,
  },
  {
    path: 'de-CH/Registration/OralExam',
    canActivate: [LegacyRedirectGuard],
    component: RedirectFailedComponent,
  },
  {
    path: 'de/Registration/OralExam',
    canActivate: [LegacyRedirectGuard],
    component: RedirectFailedComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class InitRoutingModule {}
