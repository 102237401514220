import { Inject, Injectable, Optional } from '@angular/core';
import { RegistrationApi, EntrySchoolDto } from '../../../../../generated/api';

import { Observable } from 'rxjs';
import { SchoolsCache } from '../cache/schools.cache';
import { REQUEST } from '../../../../express.tokens';

export const EMPTY_SCHOOLS = [];

@Injectable()
export class EntrySchoolsResolver {
  constructor(
    private cache: SchoolsCache,
    @Optional()
    @Inject(REQUEST)
    private request,
  ) {}

  resolve(): Observable<EntrySchoolDto[]> | EntrySchoolDto[] {
    if (this.request) {
      return EMPTY_SCHOOLS;
    }
    return this.cache.get('');
  }
}
