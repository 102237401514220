import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { components } from './components';
import { directives } from './directives';
import { MaterialModule } from './material.module';
import { pipes } from './pipes';
import { providers } from './providers';
import { ResponsiveModule } from './responsive/responsive.module';
import { UnloadTrackerModule } from './unload-tracker/unload-tracker.module';

const IMPORT_AND_EXPORT = [
  CommonModule,
  HttpClientModule,
  ResponsiveModule,
  FontAwesomeModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  RouterModule,
  UnloadTrackerModule,
  NgCircleProgressModule,
];

@NgModule({
  imports: [...IMPORT_AND_EXPORT, TranslateModule.forChild()],
  providers: [DatePipe, ...providers],
  declarations: [...components, ...directives, ...pipes],
  exports: [...IMPORT_AND_EXPORT, ...components, ...directives, TranslateModule, ...pipes],
})
export class SharedModule {}
