import { Component, HostBinding, Input } from '@angular/core';
import { ICONS } from '../../shared/fontawesome.module';

@Component({
  selector: 'sx-candidate-personal-data',
  templateUrl: 'candidate-personal-data.component.html',
})
export class CandidatePersonalDataComponent {
  @Input() examSubType: string;
  @Input() examTypeId: string;
  iconTitle = ICONS.faFingerprint;
}
