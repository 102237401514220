import { Component } from '@angular/core';
import { ICONS } from '../../shared/fontawesome.module';
import { TenantService } from '../../core/tenant/tenant.service';
import { Tenant } from '../../core/tenant/tenant';
import { PaymentDto, SpeakingTestDto } from '../../../../generated/api';
import { RegistrationProcessService } from '../../shared/providers/registration-process.service';
import { FormGroup } from '@ng-stack/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { RegistrationDataViewFacade } from '../../shared/providers/registration-data.view-facade';
import { Destroyable } from '../../util/destroyable';

@Component({
  selector: 'sx-payment-method',
  templateUrl: 'payment-method.component.html',
})
export class PaymentMethodComponent extends Destroyable {
  titleIcon = ICONS.faCreditCard;
  paymentMethods: PaymentDto.MethodEnum[];
  readonly eroLatePaymentMethods: PaymentDto.MethodEnum[] = [
    PaymentDto.EroLatePaymentMethodEnum.CreditCardPostcard,
    PaymentDto.EroLatePaymentMethodEnum.OnlineInvoice,
  ];

  readonly form: FormGroup<PaymentDto>;
  private readonly tenant: Tenant;

  eroExpanded$: Observable<boolean>;
  collectiveInvoiceCodeExpanded$: Observable<boolean>;
  payForLateFee = false;

  constructor(
    private tenantService: TenantService,
    private process: RegistrationProcessService,
    private dataFacade: RegistrationDataViewFacade,
  ) {
    super();

    this.tenant = tenantService.tenant;
    this.form = process.forms.payment;
    this.dataFacade.exam$.pipe(this.takeUntilDestroyed()).subscribe((exam) => {
      this.paymentMethods =
        exam && !exam.isLate ? this.tenant.paymentMethods : this.tenant.latePaymentMethods;
      this.payForLateFee = exam.lateEntryFee > 0 && exam.isLate;
    });

    this.eroExpanded$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((value: PaymentDto) => value.method === PaymentDto.MethodEnum.ExamRetakeOption),
      distinctUntilChanged(),
    );

    // Reset ERO late payment method if payment method is not ERO
    this.eroExpanded$
      .pipe(filter((isEroExpanded: boolean) => !isEroExpanded))
      .subscribe((isEroExpanded: boolean) => {
        this.form.get('eroLatePaymentMethod').reset();
      });

    this.collectiveInvoiceCodeExpanded$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((value: PaymentDto) => value.method === PaymentDto.MethodEnum.CollectiveInvoice),
      distinctUntilChanged(),
    );
  }
}
