import { Inject, Injectable, Optional } from '@angular/core';
import { RegistrationExamProductDto } from '../../../../../generated/api';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrationProcessService } from '../registration-process.service';
import { ProductCache } from '../cache/product.cache';
import { REQUEST } from '../../../../express.tokens';

const EMPTY_PRODUCT: RegistrationExamProductDto = {
  crmId: '',
  id: '',
  name: '',
  price: 0,
  supportsEro: false,
  hasFreeSeats: true,
};

@Injectable()
export class EntryProductResolver {
  constructor(
    private cache: ProductCache,
    private process: RegistrationProcessService,
    @Optional()
    @Inject(REQUEST)
    private request,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<RegistrationExamProductDto> | RegistrationExamProductDto {
    // In SSR we want to resolve an empty object so the page can render
    if (this.request) {
      return EMPTY_PRODUCT;
    }
    const productId = this.process.registration.examData.productCrmId;
    const examId = this.process.registration.examData.examCrmId;
    return this.cache.get({ productId, examId });
  }
}
