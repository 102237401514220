import { Injectable } from '@angular/core';
import { ExamsApi, TenantFiltersDto } from '../../../../generated/api';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/core';
import { TenantService } from '../../core/tenant/tenant.service';
import { catchError, tap } from 'rxjs/operators';

export const FILTER_VALUES_TRANSFER_KEY = makeStateKey<TenantFiltersDto>(
  'TransferState.FilterValues',
);

@Injectable({
  providedIn: 'root',
})
export class FilterValuesResolver {
  constructor(
    private api: ExamsApi,
    private transfer: TransferState,
    private tenantService: TenantService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TenantFiltersDto> | TenantFiltersDto {
    if (this.tenantService.isBrowser) {
      if (this.transfer.hasKey(FILTER_VALUES_TRANSFER_KEY)) {
        const value = this.transfer.get(FILTER_VALUES_TRANSFER_KEY, undefined);
        this.transfer.set(FILTER_VALUES_TRANSFER_KEY, undefined);
        if (value) {
          return value;
        }
      }
      return this.api.getFilterValues().pipe(
        catchError((error) => {
          console.error(error);
          return of({ filterRows: [] });
        }),
      );
    }
    return this.api.getFilterValues().pipe(
      tap((page) => {
        this.transfer.set(FILTER_VALUES_TRANSFER_KEY, page);
      }),
      catchError((error) => {
        console.error(error);
        return of({ filterRows: [] });
      }),
    );
  }
}
