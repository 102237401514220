<form [formGroup]="form">
  <div class="split">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Date' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <mat-select
          name="interviewDate"
          [value]="(speakingDates$ | async)[0]?.crmId"
          formControlName="interviewDateCrmId"
        >
          <mat-option
            *ngFor="let speakingDate of speakingDates$ | async"
            [value]="speakingDate.crmId"
          >
            {{ speakingDate.date | sxDate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.interviewDateCrmId"></sx-validation-messages>
    </div>
    <div class="split__cell"></div>
  </div>
  <div *ngIf="(examType$ | async) !== examTypes.LINGUASKILL">
    <div *ngIf="tenantService.isCambridgeTenant" class="split">
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Interview Partner First Name' | translate }}</mat-label>
          <input matInput name="interviewPartnerFirstName" formControlName="partnerFirstName" />
        </mat-form-field>
        <sx-validation-messages [control]="form.controls.partnerFirstName"></sx-validation-messages>
      </div>
      <div class="split__cell">
        <mat-form-field>
          <mat-label>{{ 'Interview Partner Last Name' | translate }}</mat-label>
          <input matInput name="interviewPartnerLastName" formControlName="partnerLastName" />
        </mat-form-field>
        <sx-validation-messages [control]="form.controls.partnerLastName"></sx-validation-messages>
      </div>
    </div>
    <div *ngIf="tenantService.isCambridgeTenant">
      <mat-checkbox formControlName="desiredTime" color="primary" name="desiredTime">
        {{ 'Desired Time for Speaking Exam' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <mat-expansion-panel
    *ngIf="tenantService.isCambridgeTenant"
    [expanded]="expanded$ | async"
    [ngClass]="'mat-expansion-panel--frameless'"
  >
    <div class="margin-top">
      <mat-form-field>
        <mat-label>
          {{ 'Comment' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <textarea
          matInput
          mat-autosize
          matAutosizeMaxRows="6"
          name="desiredTimeComment"
          formControlName="desiredTimeComment"
        ></textarea>
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.desiredTimeComment"></sx-validation-messages>
    </div>
  </mat-expansion-panel>
</form>
