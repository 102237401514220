import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sx-steps',
  template: `
    <div
      class="registration-steps__step"
      [class.registration-steps__step--highlight]="highlightedStep === 1"
    >
      <div class="registration-steps__step-number">1</div>
      <div class="registration-steps__step-text">{{ 'Personal Data' | translate }}</div>
    </div>
    <div class="registration-steps__divider"></div>
    <div
      class="registration-steps__step"
      [class.registration-steps__step--highlight]="highlightedStep === 2"
    >
      <div class="registration-steps__step-number">2</div>
      <div class="registration-steps__step-text">{{ 'Summary' | translate }}</div>
    </div>
    <div class="registration-steps__divider"></div>
    <div
      class="registration-steps__step"
      [class.registration-steps__step--highlight]="highlightedStep === 3"
    >
      <div class="registration-steps__step-number">3</div>
      <div class="registration-steps__step-text">{{ 'Payment' | translate }}</div>
    </div>
  `,
  styleUrls: ['registration-steps.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationStepsComponent {
  @HostBinding('class')
  cssClass = 'registration-steps content-card__inner';

  @Input()
  highlightedStep = 1;
}
