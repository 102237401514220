import { Component, HostBinding, OnInit } from '@angular/core';
import { RegistrationProcessService } from '../shared/providers/registration-process.service';
import { ICONS } from '../shared/fontawesome.module';
import { TenantService } from '../core/tenant/tenant.service';
import { PaymentDto } from 'generated/api';

@Component({
  selector: 'sx-complete-view',
  templateUrl: './registration-complete.view.html',
  styleUrls: ['./registration-complete.view.scss'],
})
export class RegistrationCompleteViewComponent implements OnInit {
  @HostBinding('class')
  cssClass = 'registration-process';

  backIcon = ICONS.faArrowLeft;
  icon = ICONS.faGift;
  voucher: string;
  callToActionLink: string;
  callToActionLabel: string;
  voucherImageUrl: string;

  constructor(
    private process: RegistrationProcessService,
    public tenantService: TenantService,
  ) {}

  ngOnInit(): void {
    this.process.clear();

    const { tenant } = this.tenantService;
    this.voucherImageUrl = tenant.shopVoucherImageUrl;
    this.voucher = tenant.shopVoucherCode;
    this.callToActionLink = tenant.callToActionUrl;
    this.callToActionLabel = tenant.callToActionLabel;

    this.appendGoogleAnalyticsPurchaseEventToDataLayer();
  }

  /**
   * SEMP-1319: Online Registration - Tracking Update
   * Read GA event data from local storage and append it to the data layer
   * to track the purchase event in Google Analytics.
   */
  private appendGoogleAnalyticsPurchaseEventToDataLayer(): void {
    const examData = localStorage.getItem('GAEVENT-EXAM');
    const productData = localStorage.getItem('GAEVENT-PRODUCT');
    const registrationData = localStorage.getItem('GAEVENT-REGISTRATION');

    let parsedExamData;
    let parsedProductData;
    let parsedRegistrationData;

    if (examData) {
      parsedExamData = JSON.parse(examData);
    }

    if (productData) {
      parsedProductData = JSON.parse(productData);
    }

    if (registrationData) {
      parsedRegistrationData = JSON.parse(registrationData);
    }

    if (!parsedExamData || !parsedProductData || !parsedRegistrationData) {
      console.log('No GA event data found in local storage');
      return;
    }

    const { tenant } = this.tenantService;

    const itemBrand = tenant?.title;
    let itemCategory = '';

    if (tenant?.id === 'cambridge') {
      itemCategory = 'english';
    }

    if (tenant?.id === 'tcf') {
      itemCategory = 'french';
    }

    if (tenant?.id === 'goethe') {
      itemCategory = 'german';
    }

    const itemCategory2 = parsedExamData?.examSubType;

    let itemCategory3 = parsedExamData?.type || '';

    switch (itemCategory3.toLowerCase()) {
      case 'pb': {
        itemCategory3 = 'paper-based';
        break;
      }
      case 'cb': {
        itemCategory3 = 'computer-based';
        break;
      }
      default: {
        itemCategory3 = 'unknown';
      }
    }

    let price = parsedProductData?.price;

    const items = [];

    // Exam item
    items.push({
      item_name: parsedExamData?.name,
      item_id: parsedProductData?.crmId, // ??
      price: parsedProductData?.price,
      item_brand: itemBrand,
      item_category: itemCategory,
      item_category2: itemCategory2,
      item_category3: itemCategory3,
      item_category4: '',
      item_variant: parsedExamData?.locationName,
      quantity: '1',
    });

    // Late entry fee item
    if (parsedExamData?.isLate) {
      price += parsedExamData?.lateEntryFee;

      items.push({
        item_name: 'Late Entry Fee',
        item_id: 'late-entry-fee', // ??
        price: parsedExamData?.lateEntryFee,
        item_brand: itemBrand,
        item_category: itemCategory,
        item_category2: itemCategory2,
        item_category3: itemCategory3,
        item_category4: 'Additional-costs', // fix
        quantity: '1',
      });
    }

    // ERO item
    if (parsedRegistrationData?.candidateData?.ero?.eroSelected) {
      const ERO_PRICE = 26;
      price += ERO_PRICE;

      items.push({
        item_name: 'Exam Retake Option',
        item_id: 'exam-retake-option', // ??
        price: ERO_PRICE,
        item_brand: itemBrand,
        item_category: itemCategory,
        item_category2: itemCategory2,
        item_category3: itemCategory3,
        item_category4: 'Additional-costs', // fix
        quantity: '1',
      });
    }

    // Base64 encoded transaction ID with date only and crm id
    const transactionId = window.btoa(
      `${new Date().toISOString().slice(0, 10)}|${parsedProductData?.crmId}`,
    );

    let paymentMethod = '';

    switch (parsedRegistrationData?.payment?.method) {
      case PaymentDto.MethodEnum.CreditCardPostcard:
        paymentMethod = 'credit-card-postcard';
        break;
      case PaymentDto.MethodEnum.CollectiveInvoice:
        paymentMethod = 'collective-nvoice';
        break;
      case PaymentDto.MethodEnum.OnlineInvoice:
        paymentMethod = 'online-invoice';
        break;
      case PaymentDto.MethodEnum.ExamRetakeOption:
        paymentMethod = 'exam-retake-option';
        break;
    }

    const gaEvent = {
      event: 'purchase',
      ecommerce: {
        currency: 'CHF',
        value: price,
        tax: 0,
        shipping: 0,
        affiliation: tenant.id,
        transaction_id: transactionId,
        payment_method: paymentMethod || 'unknown',
        // coupon: '', // We currently do not have a coupon code
        items: items,
      },
    };

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(gaEvent);

    console.log('dataLayer', (window as any).dataLayer);

    localStorage.removeItem('GAEVENT-EXAM');
    localStorage.removeItem('GAEVENT-PRODUCT');
    localStorage.removeItem('GAEVENT-REGISTRATION');
  }
}
