import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe as AngularDatePipe } from '@angular/common';
import { CET_TIMEZONE } from '.';

@Pipe({
  name: 'sxDate',
  pure: true,
})
export class DatePipe implements PipeTransform {
  constructor(private date: AngularDatePipe) {}

  transform(value: any, displayWhenNotVailable?: string): any {
    return value
      ? `${this.date.transform(value, 'dd.MM.yyyy', CET_TIMEZONE)}`
      : displayWhenNotVailable || '';
  }
}
