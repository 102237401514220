<mat-toolbar color="primary">
  <ng-template [ngIf]="matIcon">
    <mat-icon [ngClass]="'bottom-sheet-container__icon'">{{ matIcon }}</mat-icon>
  </ng-template>
  <ng-template [ngIf]="faIcon">
    <fa-icon [icon]="faIcon" [ngClass]="'bottom-sheet-container__icon'"></fa-icon>
  </ng-template>
  <span>{{ title | translate }}</span>
  <span class="bottom-sheet-container__toolbar-filler"></span>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<ng-template content-host></ng-template>
