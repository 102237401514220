import { Tenant } from './tenant';
import { CAMBRIDGE_TENANT } from './cambridge.tenant';
import { GOETHE_TENANT } from './goethe.tenant';
import { CAMBRIDGE_WHITELABEL_TENANT } from './cambridge-whitelabel.tenant';
import { TCF_TENANT } from './tcf.tenant';

export const TENANTS: Tenant[] = [
  CAMBRIDGE_TENANT,
  GOETHE_TENANT,
  TCF_TENANT,
  CAMBRIDGE_WHITELABEL_TENANT,
];
