import { Component } from '@angular/core';
import { RegistrationProcessService } from '../../shared/providers/registration-process.service';
import { FormGroup } from '@ng-stack/forms';
import { TermsAndConditionsDto } from '../../../../generated/api';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { TenantService } from '../../core/tenant/tenant.service';
import { Link } from '../../core/tenant/tenant';

interface Checkbox {
  value: string;
  checked: boolean;
}

@Component({
  selector: 'sx-terms-and-conditions',
  templateUrl: 'terms-and-conditions.component.html',
})
export class TermsAndConditionComponent {
  titleIcon = faFileAlt;
  termsAndConditions: Link[];
  termsAndConditionsCheckboxes: Checkbox[];
  readonly form: FormGroup<TermsAndConditionsDto>;

  constructor(
    private process: RegistrationProcessService,
    private tenantService: TenantService,
  ) {
    this.form = this.process.forms.termsAndCondition;
    this.initTermsAndConditionsCheckboxes();
    this.termsAndConditions = tenantService.tenant.termsAndConditions;
  }

  onChange() {
    if (this.termsAndConditionsCheckboxes.every((cb) => cb.checked)) {
      this.form.controls.termsAndConditionsAccepted.setValue(true);
    } else {
      this.form.controls.termsAndConditionsAccepted.setValue(false);
    }
  }

  private initTermsAndConditionsCheckboxes() {
    this.termsAndConditionsCheckboxes = [];
    const tcValue = this.form.controls.termsAndConditionsAccepted.value as boolean;

    this.tenantService.tenant.termsAndConditions.forEach((termAndCondition, i) => {
      this.termsAndConditionsCheckboxes.push({
        value: 'tc' + i,
        checked: tcValue,
      });
    });
  }
}
