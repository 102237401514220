import { Component, Input } from '@angular/core';
import { ValidationErrorDto } from '../../../../../generated/api';
import { isString } from '../../../util/guards/isString';
import { isValidationErrors } from '../../../util/guards/validation-error-dto.guard';
import { NotificationService } from '../../providers/notification.service';

type ErrorsState = 'Valid' | 'ValidationErrors' | 'ErrorMessage';
const ErrorsState = {
  Valid: 'Valid' as ErrorsState,
  ValidationErrors: 'ValidationErrors' as ErrorsState,
  ErrorMessage: 'ErrorMessage' as ErrorsState,
};

@Component({
  selector: 'sx-server-validation-errors',
  template: `
    <ng-container [ngSwitch]="state" class="server-error">
      <sx-content-card-inner
        *ngSwitchCase="ErrorsState.ValidationErrors"
        class="server-error__validation-errors"
      >
        <sx-content-card-section>
          <h3>{{ 'Validation error on the following fields occurred' | translate }}</h3>
          <mat-list class="server-error__list">
            <mat-list-item *ngFor="let error of errors" class="server-error__list__item">
              {{ error.field | translate }}
            </mat-list-item>
          </mat-list>
        </sx-content-card-section>
      </sx-content-card-inner>
    </ng-container>
  `,
  styleUrls: ['server-validation-errors.component.scss'],
})
export class ServerValidationErrorsComponent {
  ErrorsState = ErrorsState;

  state: ErrorsState = ErrorsState.Valid;
  errors: ValidationErrorDto[] | string | undefined;

  @Input('errors')
  set _errors(errors: ValidationErrorDto[] | string | undefined) {
    if (isString(errors)) {
      this.errors = undefined;
      this.state = ErrorsState.ErrorMessage;
      this.notifications.error(errors);
    } else if (isValidationErrors(errors)) {
      this.errors = errors;
      this.state = ErrorsState.ValidationErrors;
    } else if (errors) {
      this.errors = undefined;
      this.notifications.error('Unknown error occurred');
      this.state = ErrorsState.ErrorMessage;
    } else {
      this.errors = undefined;
      this.state = ErrorsState.Valid;
    }
  }

  constructor(private notifications: NotificationService) {}
}
