import { AbstractControl, ValidatorFn } from '@angular/forms';

export type ControlGetter = () => AbstractControl;

export function matchesValidator(controlGetter: ControlGetter, subjectName: string): ValidatorFn {
  return (control) => {
    const other = controlGetter();
    return !control || !other || control.value === other.value ? null : { matches: subjectName };
  };
}
