import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ListViewComponent } from './list.view';
import { RegistrationExamsResolver } from './providers/registration-exams.resolver';
import { FilterValuesResolver } from './providers/filter-values.resolver';
import { ConfigInitializedGuard } from '../core/config-initialized.guard';

const ROUTES: Routes = [
  {
    path: 'list',
    component: ListViewComponent,
    canActivate: [ConfigInitializedGuard],
    resolve: {
      firstPage: RegistrationExamsResolver,
      filters: FilterValuesResolver,
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class ListRoutingModule {}
