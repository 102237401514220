import { ALL_PAYMENT_METHODS, Tenant, TENANT_ID } from './tenant';
import { PaymentDto } from '../../../../generated/api';
import { COUNTRIES } from '../../util/countries';

export const CAMBRIDGE_TENANT: Tenant = {
  paymentMethods: ALL_PAYMENT_METHODS,
  latePaymentMethods: [
    PaymentDto.MethodEnum.CreditCardPostcard,
    PaymentDto.MethodEnum.ExamRetakeOption,
    PaymentDto.MethodEnum.CollectiveInvoice,
  ],
  id: TENANT_ID.CAMBRIDGE,
  title: 'Cambridge English Exams - Official registration',
  tagline: 'Cambridge Tagline',
  contactLogoUrl: 'assets/cambridge/lo_swex_autorisedprov_cambridge.png',
  contactLogoAlt: 'Swiss Exams - Authorised provider for Cambridge English Exams',
  contactEmail: 'info@cambridge-exams.ch',
  contactAddress: `Swiss Exams
Cambridge English Exams Centre

Zürcherstrasse 46
8400 Winterthur`,
  contactPhone: '052 209 04 50',
  contactEmergencyHelpdesk: `0900 884 884 (-.70/min)`,
  entryUrls: [
    'http://localhost:4200',
    'http://localhost:4000',
    'http://cambridge-or-test.swiss-exams.ch',
    'https://cambridge-or-test.swiss-exams.ch',
    'http://registration.cambridge-exams.ch',
    'https://registration.cambridge-exams.ch',
  ],
  prodUrls: ['http://registration.cambridge-exams.ch', 'https://registration.cambridge-exams.ch'],
  googleTagId: 'GTM-N23CZGB',
  googleTagIdForTest: 'GTM-MH585KTB',
  languages: ['en', 'de'],
  sxLogoUrl: 'assets/cambridge/lo_swex_autorisedprov_cambridge.png',
  sxLogoAlt: 'Swiss Exams - Authorised provider for Cambridge English Exams',
  examsLogoUrl: 'assets/cambridge/platinum_centre.png',
  examsLogoLinkEn: 'https://cambridge-exams.ch/en/exams-and-certifications/cambridge',
  examsLogoLinkDe: 'https://cambridge-exams.ch/de/exams-and-certifications/cambridge',
  examsLogoLinkFr: 'https://cambridge-exams.ch/fr/exams-and-certifications/cambridge',
  examsLogoAlt: 'Cambridge Assessment English - Authorised Platinum Exam Centre',
  infoBoxes: [
    {
      type: 'box',
      color: 'red',
      title: 'Flex (standard)',
      content: 'Flex Info Box Content',
    },
    {
      type: 'box',
      color: 'gold',
      title: 'Basic',
      content: 'Basic Info Box Content',
    },
  ],
  infoBoxImage: {
    type: 'image',
    url: 'assets/cambridge/Swiss-Exams_Schweiz_alle_rot.png',
    alt: 'Map',
  },
  footerHelpDeskColumnOne: `
    <strong>Swiss Exams</strong><br>
    Office Winterthur<br>
    Zürcherstrasse 46<br>
    8400 Winterthur
  `,
  footerLinks: [
    {
      label: 'swiss-exams.ch',
      url: 'https://swiss-exams.ch',
      openInNewTab: true,
    },
    {
      label: 'Imprint',
      url: 'imprint',
    },
    {
      label: 'Privacy Policy',
      url: 'privacy',
    },
  ],
  footerTermsAndConditions: {
    text: 'Read our General Terms and Conditions online.',
    link: {
      label: 'General Terms and Conditions',
      url: 'https://cambridge-exams.ch/sites/default/files/cambridge_terms_and_conditions.pdf',
      openInNewTab: true,
    },
  },
  termsAndConditions: [
    {
      label: 'I have read and understood the "Terms and Conditions for candidates".',
      url: 'https://cambridge-exams.ch/sites/default/files/cambridge_terms_and_conditions.pdf',
      openInNewTab: true,
    },
    {
      label:
        'I confirm that I am in agreement that my photo is taken on the day of my Speaking Test for security reasons.',
      url: 'https://res.cloudinary.com/swiss-exams/image/upload/v1690460635/consent_form_testdayphoto_432572b473.pdf',
      openInNewTab: true,
    },
    {
      label:
        'I understand that I can only take the exam if I present valid identification on both exam days (written & speaking test).',
      url: 'https://cambridge-exams.ch/exams/ID.php',
      openInNewTab: true,
    },
  ],
  specialArrangementsInfoUrl: 'https://cambridge-exams.ch/special-arrangements',
  examRetakeOptionInfoUrl: 'https://cambridge-exams.ch/exam-retake-option-ero-summary-regulations',
  shopSupport: true,
  shopVoucherImageUrl: 'assets/cambridge/swiss-exams-cambridge-voucher.jpg',
  shopVoucherCode: 'J3LKN4OE',
  callToActionUrl: 'https://shop.swiss-exams.ch/produkt-kategorie/cambridge-exams',
  callToActionLabel: 'Boost Your English Level Now',
  supportedCountries: [...COUNTRIES],
  showSpecialArrangementSection: true,
  showAdditionalInformationOnRegistrationCompletePage: false,
  tenantBasedTranslationsKeys: {
    'School Description': 'School Description',
  },
  howToRegisterLinkEn:
    'https://cambridge-exams.ch/exams-and-certifications/cambridge/registration/how-to-register',
  howToRegisterLinkDe:
    'https://cambridge-exams.ch/de/exams-and-certifications/cambridge/registration/how-to-register',
  howToRegisterLinkFr:
    'https://cambridge-exams.ch/fr/exams-and-certifications/cambridge/registration/how-to-register',
};
