<div class="exam__header">
  <div itemprop="name" class="exam__title">
    {{ exam.name }}
  </div>
  <div class="exam__title_aside">
    {{ exam.mainDate | sxDate }}
    <meta itemprop="startDate" [content]="exam.mainDate" />
  </div>
  <div itemprop="location" class="exam__title_aside">
    {{ exam.locationName }}
  </div>
</div>
<div class="exam__content-row">
  <div class="exam__content-row-item">
    <label class="exam__content-row-item-label">
      {{ isSpeakingOnly ? 'Speaking' : ('Written' | translate) }}
    </label>
    <div class="exam__content-row-item-value">
      <fa-icon [icon]="isSpeakingOnly ? iconSpeakingDate : iconWrittenDate"></fa-icon>
      {{ exam.mainDate | sxDate }}
    </div>
  </div>

  <div class="exam__content-row-item" *ngIf="hasSpeakingDate">
    <label class="exam__content-row-item-label">{{ 'Speaking' | translate }}</label>
    <div class="exam__content-row-item-value">
      <fa-icon [icon]="iconSpeakingDate"></fa-icon>
      <div class="exam__content-row-item__stack">
        <div *ngFor="let speakingDate of exam?.speakingDates">
          {{ speakingDate.date | sxDate }}
        </div>
      </div>
    </div>
  </div>

  <div class="exam__content-row-item">
    <label class="exam__content-row-item-label">{{ 'Type' | translate }}</label>
    <div class="exam__content-row-item-value">
      <fa-icon [icon]="exam.type === 'CB' ? iconComputerBased : iconPaperBased"></fa-icon>
      {{ exam.type | translate }}
    </div>
  </div>

  <div class="exam__content-row-item exam__content-row-item--centered-on-tablet-and-up">
    <label class="exam__content-row-item-label">{{ 'Registration Deadline' | translate }}</label>
    <div
      class="exam__content-row-item-value exam__content-row-item-value--centered-on-tablet-and-up"
    >
      <fa-icon [icon]="iconRegistrationDeadline"></fa-icon>
      {{ (deadline && (deadline | sxDate)) || '-' }}
    </div>
  </div>

  <div class="exam__content-row-item" *ngIf="!hasSpeakingDate"></div>
</div>

<div class="exam__content-row">
  <div class="exam__content-row-item">
    <label class="exam__content-row-item-label">{{ 'Result Release' | translate }}</label>
    <div class="exam__content-row-item-value">
      <fa-icon [icon]="iconResultRelease"></fa-icon>
      {{ exam.resultRelease | sxDate }}
    </div>
  </div>

  <div class="exam__content-row-item">
    <label class="exam__content-row-item-label">{{ 'Price' | translate }}</label>
    <div
      itemprop="offers"
      itemscope
      itemtype="https://schema.org/Offer"
      class="exam__content-row-item-value"
    >
      <fa-icon [icon]="iconPrice"></fa-icon>
      {{ price }}
      <meta itemProp="priceCurrency" content="CHF" />
      <meta itemProp="price" [content]="price" />
    </div>
  </div>

  <div class="exam__content-row-item">
    <ng-template [ngIf]="exam.isLate && exam.lateEntryFee">
      <label class="exam__content-row-item-label">{{ 'Late Entry Fee' | translate }}</label>
      <div class="exam__content-row-item-value">
        <fa-icon [icon]="iconLateEntryFee"></fa-icon>
        CHF {{ exam.lateEntryFee }}.-
      </div>
    </ng-template>
  </div>

  <div class="exam__content-row-item exam__content-row-item--centered">
    <label class="exam__content-row-item-label">
      {{ (selectedProduct ? 'Selected Product' : 'Register Now') | translate }}
    </label>
    <div class="exam__content-row-item-value exam__content-row-item-value--wrap">
      <ng-template [ngIf]="!selectedProduct">
        <ng-template [ngIf]="exam.openFrom > today" [ngIfElse]="examOpenForRegistration">
          {{ 'Not open for registration yet' | translate }}
        </ng-template>
        <ng-template #examOpenForRegistration>
          <ng-template ngFor let-product [ngForOf]="products">
            <ng-template [ngIf]="product.hasFreeSeats">
              <a
                mat-flat-button
                itemprop="url"
                rel="nofollow"
                [routerLink]="['/', 'register', exam.crmId, product.productCrmId]"
                color="primary"
                [ngClass]="'exam__product-button mat-flat-button--short ' + product.cssClass"
              >
                {{ product.name | translate }}
              </a>
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-template>
      <span *ngIf="selectedProduct" [ngClass]="selectedProductClass">
        {{ selectedProduct | translate }}
      </span>
    </div>
    <ng-template [ngIf]="!selectedProduct && exam.openFrom && isLate">
      <label class="exam__content-row-item-label exam__content-row-item--centered">
        {{ 'Seats still available' | translate }}
      </label>
    </ng-template>
  </div>
</div>
<!-- SEMP-402: Commented out till we get the GO from the Customer -->
<!-- <div class="exam__content-row"> -->
<!-- 	<div class="exam_row_item"> -->
<!-- 		<div class="exam__content-row-item-label">All dates are in CET Zurich Time.</div> -->
<!-- 	</div> -->
<!-- </div> -->
