import { FormGroup, FormArray, FormControl } from '@ng-stack/forms';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';

export type FG = UntypedFormGroup | UntypedFormGroup;
export type FA = UntypedFormArray | UntypedFormArray;
export type FC = UntypedFormControl | UntypedFormControl;

export interface TraverseCallbacks {
  onControl(abstract: AbstractControl);

  onFormGroup(group: FG);

  onFormArray(array: FA);

  onFormControl(control: FC);
}

export function traverse(control: AbstractControl, callbacks: Partial<TraverseCallbacks>) {
  traverseControl(control, {
    onControl: callbacks.onControl || ((c: AbstractControl) => undefined),
    onFormArray: callbacks.onFormArray || ((c: FA) => undefined),
    onFormControl: callbacks.onFormControl || ((c: FC) => undefined),
    onFormGroup: callbacks.onFormGroup || ((c: FG) => undefined),
  });
}

function traverseControl(control: AbstractControl, callbacks: TraverseCallbacks) {
  callbacks.onControl(control);
  if (control instanceof UntypedFormControl || control instanceof UntypedFormControl) {
    callbacks.onFormControl(control);
  } else if (control instanceof UntypedFormArray || control instanceof UntypedFormArray) {
    callbacks.onFormArray(control);
    control.controls.forEach((child) => traverseControl(child, callbacks));
  } else if (control instanceof UntypedFormGroup || control instanceof UntypedFormGroup) {
    callbacks.onFormGroup(control);
    Object.keys(control.controls)
      .map((key) => control.controls[key])
      .forEach((child) => traverseControl(child, callbacks));
  }
}
