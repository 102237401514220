import { Tenant } from './tenant';
import { CAMBRIDGE_TENANT } from './cambridge.tenant';
import { COUNTRIES } from '../../util/countries';

export const CAMBRIDGE_WHITELABEL_TENANT: Tenant = {
  ...CAMBRIDGE_TENANT,
  title: 'Exams',
  subTitle: 'Online Registration',
  tagline: 'Tagline',
  contactLogoUrl: '',
  contactLogoAlt: '',
  contactEmail: '',
  contactAddress: `White Label Exams
Exams Centre`,
  contactPhone: '4711 11 74 1',
  contactEmergencyHelpdesk: `4711 11 74 2 (-.70/min)`,
  entryUrls: ['http://localhost:4202', 'http://localhost:4002'],
  sxLogoUrl: '',
  sxLogoAlt: 'Exams',
  examsLogoUrl: '',
  examsLogoLinkEn: '',
  examsLogoLinkDe: '',
  examsLogoLinkFr: '',
  examsLogoAlt: 'Exam Centre',
  infoBoxes: [
    {
      type: 'box',
      color: 'red',
      title: 'Flex (standard)',
      content: 'Flex Info Box Content',
    },
    {
      type: 'box',
      color: 'gold',
      title: 'Basic',
      content: 'Basic Info Box Content',
    },
  ],
  infoBoxImage: {
    type: 'image',
    url: 'assets/cambridge/Swiss-Exams_Schweiz_alle_rot.png',
    alt: 'Map',
  },
  footerLinks: [
    {
      label: 'swiss-exams.ch',
      url: 'https://swiss-exams.ch',
      openInNewTab: true,
    },
    {
      label: 'Imprint',
      url: 'imprint',
    },
  ],
  termsAndConditions: [
    {
      label: 'I have read and understood the Terms and Conditions',
      url: 'https://cambridge-exams.ch/sites/default/files/cambridge_terms_and_conditions.pdf',
      openInNewTab: true,
    },
    {
      label:
        'I confirm that I am in agreement that my photo is taken on the day of my Speaking Test for security reasons',
      url: 'https://cambridge-exams.ch/sites/default/files/consent_form_2016.pdf',
      openInNewTab: true,
    },
    {
      label:
        'I understand that I can only take the exam if I present valid identification on both exam days (written & speaking test)',
      url: 'https://cambridge-exams.ch/exams/ID.php',
      openInNewTab: true,
    },
  ],
  supportedCountries: [...COUNTRIES],
  showSpecialArrangementSection: true,
  tenantBasedTranslationsKeys: {
    'School Description': 'School Description',
  },
  howToRegisterLinkEn: '',
  howToRegisterLinkDe: '',
  howToRegisterLinkFr: '',
};
