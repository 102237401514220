/**
 * OrApi
 * Swiss Exams - Microservices - Online Registration Api
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AddressDto { 
    salutation: AddressDto.SalutationEnum;
    companyName?: string;
    firstName: string;
    lastName: string;
    street: string;
    streetAddition?: string;
    postalCode: string;
    city: string;
    country: string;
}
export namespace AddressDto {
    export type SalutationEnum = 'Mr' | 'Ms';
    export const SalutationEnum = {
        Mr: 'Mr' as SalutationEnum,
        Ms: 'Ms' as SalutationEnum
    };
}


