import { Component, ViewEncapsulation } from '@angular/core';

/**
 * This component is used to include our styles from styles.scss when
 * being rendered on the server. The server rendered page won't come with
 * any styles, hence will look ugly until the css has been laoded.
 *
 * With this component, the styles are also loaded inline on the server renderer.
 */
@Component({
  selector: 'sx-app-server',
  template: ``,
  styleUrls: ['./app-server.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppServerComponent {}
