import {
  Component,
  ComponentFactoryResolver,
  HostBinding,
  Inject,
  OnInit,
  Type,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ContentHostDirective } from '../../directives/content-host.directive';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface BottomSheetContainerData<T> {
  component: Type<T>;
  title: string;
  onInstantiated?: (component: T) => any;
  matIcon?: string;
  faIcon?: IconDefinition;
}

@Component({
  selector: 'sx-bottom-sheet-container',
  templateUrl: 'bottom-sheet-container.component.html',
  styleUrls: ['bottom-sheet-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BottomSheetContainerComponent implements OnInit {
  title: string;
  matIcon?: string;
  faIcon?: IconDefinition;

  @ViewChild(ContentHostDirective, { static: true })
  host: ContentHostDirective;

  @HostBinding('class')
  cssClass = 'bottom-sheet-container';

  constructor(
    @Inject(MatBottomSheetRef)
    private matBottomSheet: MatBottomSheetRef,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    private data: BottomSheetContainerData<any>,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.title = data.title;
    this.matIcon = data.matIcon;
    this.faIcon = data.faIcon;
  }

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.data.component,
    );
    const { viewContainerRef } = this.host;

    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    if (this.data.onInstantiated) {
      this.data.onInstantiated(componentRef.instance);
    }
  }

  close() {
    this.matBottomSheet.dismiss();
  }
}
