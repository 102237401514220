import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentViewComponent } from './payment.view';
import { PriceComponent } from './components/price.component';
import { PaymentMethodComponent } from './components/payment-method.component';
const DECLARE_AND_EXPORT = [PaymentViewComponent];

@NgModule({
  imports: [SharedModule, PaymentRoutingModule],
  declarations: [...DECLARE_AND_EXPORT, PriceComponent, PaymentMethodComponent],
  providers: [],
  exports: [...DECLARE_AND_EXPORT],
})
export class PaymentModule {}
