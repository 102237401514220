import { Injectable } from '@angular/core';
import { ExamsApi, FilterExamsByDto, RegistrationExamsPageDto } from '../../../../generated/api';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { makeStateKey, TransferState } from '@angular/core';
import { TenantService } from '../../core/tenant/tenant.service';
import { catchError, tap } from 'rxjs/operators';
import { createFilterFromObject } from '../create-filter-from-object';
import { ExamCache } from '../../shared/providers/cache/exam.cache';
import { ProductCache } from '../../shared/providers/cache/product.cache';

export const EXAMS_TRANSFER_KEY = makeStateKey<RegistrationExamsPageDto>('TransferState.Exams');

@Injectable({
  providedIn: 'root',
})
export class RegistrationExamsResolver {
  constructor(
    private api: ExamsApi,
    private transfer: TransferState,
    private tenantService: TenantService,
    private examCache: ExamCache,
    private productCache: ProductCache,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<RegistrationExamsPageDto> | RegistrationExamsPageDto {
    const filters: FilterExamsByDto = createFilterFromObject(route.queryParams);

    if (this.tenantService.isBrowser) {
      if (this.transfer.hasKey(EXAMS_TRANSFER_KEY)) {
        const value = this.transfer.get(EXAMS_TRANSFER_KEY, undefined);
        this.transfer.set(EXAMS_TRANSFER_KEY, undefined);
        if (value) {
          return value;
        }
      }
      return this.api.listExams(0, filters).pipe(
        tap((response) =>
          response.exams.forEach((exam) => {
            this.examCache.populate(exam.crmId, exam);
            (exam.products || []).forEach((product) =>
              this.productCache.populate({ productId: product.crmId, examId: exam.crmId }, product),
            );
          }),
        ),
        catchError((error) => {
          console.error(error);
          return of({ exams: [], pageNumber: 0, pageSize: 0 });
        }),
      );
    }
    return this.api.listExams(0, filters).pipe(
      tap((page) => {
        this.transfer.set(EXAMS_TRANSFER_KEY, page);
      }),
      catchError((error) => {
        console.error(error);
        return of({ exams: [], pageNumber: 0, pageSize: 0 });
      }),
    );
  }
}
