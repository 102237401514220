import { inject } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorsModel } from '@ng-stack/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { NationalityValidator } from '../../util/form/validators/nationality-validator';
import {
  AddressDto,
  BillingDto,
  CandidateDto,
  EroDto,
  ExamDataDto,
  PaymentDto,
  PersonalDto,
  RegistrationApi,
  RegistrationDto,
  SchoolDataDto,
  SpeakingTestDto,
  SpecialArrangementsDto,
  TermsAndConditionsDto,
} from '../../../../generated/api';
import { Tenant, TENANT_ID } from '../../core/tenant/tenant';
import { ExamTypes } from '../../util/exam-types';
import {
  collectValidationErrors,
  ValidationErrorData,
} from '../../util/form/collect-validation-errors';
import { setEnabled } from '../../util/form/set-enabled';
import { traverse } from '../../util/form/traverse';
import { matchesValidator } from '../../util/form/validators/matches-validator';
import { momentMaxDateValidator } from '../../util/form/validators/moment-max-date-validators';
import { momentMinDateValidator } from '../../util/form/validators/moment-min-date-validators';
import { phoneNumberValidator } from '../../util/form/validators/phone-number-validator';
import { requiredWhen } from '../../util/form/validators/required-when-validator';
import { ExamTypeProvider } from './exam-type-provider';

export type RegistrationStep = 'Candidate' | 'Terms' | 'Payment';
export const RegistrationStep = {
  Candidate: 'Candidate' as RegistrationStep,
  Terms: 'Terms' as RegistrationStep,
  Payment: 'Payment' as RegistrationStep,
};

export class RegistrationForms {
  readonly examData = createExamDataForm();
  readonly speakingTest: FormGroup<SpeakingTestDto, ValidatorsModel>;
  readonly school = createSchoolForm();
  readonly personalDataAddress = createAddressForm();
  readonly personalData: FormGroup<PersonalDto, ValidatorsModel>;
  readonly billing = createBillingForm();
  readonly billingAddress = this.billing.controls.address;

  readonly termsAndCondition = createTermsAndConditionsForm();
  readonly specialArrangements = createSpecialArrangementsForm();
  readonly payment = createPaymentForm();

  readonly ero: FormGroup<EroDto>;

  readonly candidate: FormGroup<CandidateDto, ValidatorsModel>;

  readonly main: FormGroup<RegistrationDto, ValidatorsModel>;

  readonly candidateErrors$: Observable<ValidationErrorData[]>;

  constructor(
    private tenant: Tenant,
    private examTypeProvider: ExamTypeProvider,
    private registrationApi: RegistrationApi,
  ) {
    this.ero = createEroForm(this.examTypeProvider, this.registrationApi, this.examData);

    this.speakingTest = createSpeakingTestForm(this.tenant);
    this.personalData = createPersonalDataForm(
      this.personalDataAddress,
      this.tenant,
      this.registrationApi,
      this.examTypeProvider,
      this.examData,
    );

    this.candidate = new FormGroup<CandidateDto>({
      speaking: this.speakingTest,
      school: this.school,
      ero: this.ero,
      personalData: this.personalData,
      billing: this.billing,
      specialArrangements: this.specialArrangements,
    });

    this.main = new FormGroup<RegistrationDto>({
      examData: this.examData,
      candidateData: this.candidate,
      termsAndConditions: this.termsAndCondition,
      payment: this.payment,
    });

    this.candidateErrors$ = this.candidate.valueChanges.pipe(
      map(() => collectValidationErrors(this.candidate)),
      shareReplay(1),
    );
  }

  initFormData(registration?: Partial<RegistrationDto>) {
    if (!registration) {
      this.main.reset();
      return;
    }

    const enableSchool = !registration.examData.reservationCrmId;
    setEnabled(this.school.controls.schoolCrmId, enableSchool);
    setEnabled(this.school.controls.schoolName, enableSchool);

    const candidate: Partial<CandidateDto> = registration.candidateData || {};
    setKnownValuesIfPresent(this.examData, registration.examData);
    setKnownValuesIfPresent(this.speakingTest, candidate.speaking);
    setKnownValuesIfPresent(this.school, candidate.school);
    setKnownValuesIfPresent(this.ero, candidate.ero);
    if (candidate.personalData && candidate.personalData.address) {
      setKnownValuesIfPresent(this.personalDataAddress, candidate.personalData.address);
    }
    setKnownValuesIfPresent(this.personalData, candidate.personalData);
    setKnownValuesIfPresent(this.billing, candidate.billing);
    if (candidate.billing && candidate.billing.address) {
      setKnownValuesIfPresent(this.billingAddress, candidate.billing.address);
    }
    setKnownValuesIfPresent(this.specialArrangements, candidate.specialArrangements);
    setKnownValuesIfPresent(this.termsAndCondition, registration.termsAndConditions);
    setKnownValuesIfPresent(this.payment, registration.payment);
    const privatePhone = this.personalData.controls.phonePrivate;
    const workPhone = this.personalData.controls.phoneBusiness;
    workPhone.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      setTimeout(() => {
        privatePhone.updateValueAndValidity();
      }, 0);
    });
    privatePhone.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      setTimeout(() => {
        workPhone.updateValueAndValidity();
      }, 0);
    });
    const { schoolCrmId } = this.school.controls;
    const { schoolName } = this.school.controls;
    schoolCrmId.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      setTimeout(() => {
        schoolName.updateValueAndValidity();
      }, 0);
    });
    schoolName.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      setTimeout(() => {
        schoolCrmId.updateValueAndValidity();
      }, 0);
    });
    this.billing.controls.billingAddressDiffers.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() =>
        setTimeout(
          () =>
            traverse(this.billingAddress, {
              onFormControl: (control) => control.updateValueAndValidity(),
            }),
          0,
        ),
      );
  }

  updateValidation(hasSpeakingDate: boolean, isLate: boolean) {
    const speakingForm = this.candidate.controls.speaking;
    const speaking = this.candidate.controls.speaking.controls;
    if (hasSpeakingDate) {
      speaking.interviewDateCrmId.setValidators(Validators.required);

      if (this.tenant.id !== 'goethe') {
        speaking.partnerFirstName.setValidators(Validators.maxLength(100));
        speaking.partnerLastName.setValidators(Validators.maxLength(100));

        speaking.desiredTimeComment.setValidators([
          requiredWhen(
            () => !!speakingForm && speakingForm.value.desiredTime,
            'a specific time is desired',
          ),
          Validators.maxLength(2000),
        ]);
      }
    } else {
      speaking.interviewDateCrmId.clearValidators();

      if (this.tenant.id !== 'goethe') {
        speaking.partnerFirstName.clearValidators();
        speaking.partnerLastName.clearValidators();
        speaking.desiredTimeComment.clearValidators();
      }
    }
    this.updateLatePaymentMethodValidation(isLate);
  }

  updateLatePaymentMethodValidation(isLate) {
    console.log('updating late payment method validation');
    const { payment } = this;
    const eroPaymentMethod = payment.controls.eroLatePaymentMethod;
    if (isLate && payment.controls.method.value === PaymentDto.MethodEnum.ExamRetakeOption) {
      eroPaymentMethod.setValidators(Validators.required);
    } else {
      eroPaymentMethod.clearValidators();
    }
  }

  getForm(step: RegistrationStep) {
    if (step === RegistrationStep.Candidate) {
      return this.candidate;
    } else if (step === RegistrationStep.Terms) {
      return this.termsAndCondition;
    } else if (step === RegistrationStep.Payment) {
      return this.payment;
    }
    return null;
  }

  onCandidate() {
    traverse(this.candidate, { onFormControl: (control) => control.enable() });
    traverse(this.candidate, {
      onFormControl: (control) => control.updateValueAndValidity(),
    });
    if (this.examData.value.reservationCrmId) {
      this.school.controls.schoolCrmId.disable();
      this.school.controls.schoolName.disable();
    }
  }

  onSummary() {
    traverse(this.candidate, { onFormControl: (control) => control.disable() });
  }
}

export function createExamDataForm(): FormGroup<ExamDataDto> {
  return new FormGroup<ExamDataDto>({
    examCrmId: new FormControl<string>('', Validators.required),
    productCrmId: new FormControl<string>('', Validators.required),
    reservationCrmId: new FormControl<string>(''),
    examType: new FormControl<string>(''),
    examTypeId: new FormControl<string>(''),
    isSpeakingRequired: new FormControl<boolean>(false),
  });
}

function createSpeakingTestForm(tenant: Tenant): FormGroup<SpeakingTestDto> {
  if (tenant.id === TENANT_ID.GOETHE) {
    return new FormGroup<SpeakingTestDto>({
      interviewDateCrmId: new FormControl<string>(''),
    });
  } else {
    return new FormGroup<SpeakingTestDto>({
      interviewDateCrmId: new FormControl<string>(''),
      partnerFirstName: new FormControl<string>(''),
      partnerLastName: new FormControl<string>(''),
      desiredTime: new FormControl<boolean>(false),
      desiredTimeComment: new FormControl<string>(''),
    });
  }
}

function createSchoolForm(): FormGroup<SchoolDataDto> {
  const form = new FormGroup<SchoolDataDto>({
    schoolCrmId: new FormControl<string>(''),
    schoolName: new FormControl<string>('', Validators.maxLength(200)),
    teacherFirstName: new FormControl<string>('', Validators.maxLength(200)),
    teacherLastName: new FormControl<string>('', Validators.maxLength(200)),
  });
  form.controls.schoolCrmId.setValidators(
    requiredWhen(() => form && !form.value.schoolName, 'school name is empty'),
  );
  form.controls.schoolName.setValidators([
    Validators.maxLength(200),
    requiredWhen(() => form && !form.value.schoolCrmId, 'school dropdown is empty'),
  ]);
  return form;
}

function createEroForm(
  examTypeProvider: ExamTypeProvider,
  registrationApi: RegistrationApi,
  examDataForm: FormGroup<ExamDataDto>,
): FormGroup<EroDto> {
  const form = new FormGroup<EroDto>({
    eroSelected: new FormControl<boolean>(false),
    targetLevel: new FormControl<string>(),
  });

  examDataForm.controls.examCrmId.valueChanges.subscribe((examCrmId) => {
    if (examCrmId) {
      registrationApi
        .getRegistrationExam(examCrmId)
        .pipe(distinctUntilChanged())
        .subscribe((examData) => {
          const isLinguaskillExam =
            examTypeProvider.getExamTypeById(examData.examTypeId) === ExamTypes.LINGUASKILL;
          form.controls.targetLevel.setValidators([
            requiredWhen(
              () => isLinguaskillExam && form && form.value.eroSelected,
              'when ERO is checked',
            ),
          ]);
        });
    }
  });
  return form;
}

function createAddressForm(requiredWhenPredicate?: () => boolean): FormGroup<AddressDto> {
  const requiredValidator = requiredWhenPredicate
    ? requiredWhen(requiredWhenPredicate, 'billing address is different')
    : Validators.required;
  // TODO: Max length when
  return new FormGroup<AddressDto>({
    salutation: new FormControl<AddressDto.SalutationEnum>(
      '' as AddressDto.SalutationEnum,
      requiredValidator,
    ),
    companyName: new FormControl<string>('', Validators.maxLength(100)),
    firstName: new FormControl<string>('', [requiredValidator, Validators.maxLength(50)]),
    lastName: new FormControl<string>('', [requiredValidator, Validators.maxLength(100)]),
    street: new FormControl<string>('', [requiredValidator, Validators.maxLength(100)]),
    streetAddition: new FormControl<string>('', [Validators.maxLength(100)]),
    city: new FormControl<string>('', [requiredValidator, Validators.maxLength(100)]),
    postalCode: new FormControl<string>('', [
      requiredValidator,
      Validators.maxLength(5),
      // at least 4 to 5 numbers
      Validators.pattern(/^\d{4}$|^\d{5}$/),
    ]),
    country: new FormControl<string>('', [requiredValidator, Validators.maxLength(100)]),
  });
}

function createPersonalDataForm(
  addressForm: FormGroup<AddressDto>,
  tenant: Tenant,
  registrationApi: RegistrationApi,
  examTypeProvider: ExamTypeProvider,
  examDataForm: FormGroup<ExamDataDto>,
): FormGroup<PersonalDto> {
  const form = new FormGroup<PersonalDto>({
    dateOfBirth: new FormControl<string>('', [
      Validators.required,
      momentMinDateValidator(new Date(1900, 0, 1)),
      momentMaxDateValidator(new Date()),
    ]),
    placeOfBirth: new FormControl<string>(''),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(100),
    ]),
    emailConfirmation: new FormControl<string>(''),
    phonePrivate: new FormControl<string>(''),
    phoneBusiness: new FormControl<string>(''),
    address: addressForm,
    confirmPersonalData: new FormControl<boolean>(false),
    confirmTCFBaseModule: new FormControl<boolean>(false),
    nationality: new FormControl<string>(''),
    countryOfBirth: new FormControl<string>(''),
    nativeLanguage: new FormControl<string>(''),
    identityCardNumber: new FormControl<string>(''),
  });

  examDataForm.controls.examCrmId.valueChanges.subscribe((examCrmId) => {
    if (examCrmId) {
      form.controls.emailConfirmation.setValidators([
        matchesValidator(() => (form && form.controls && form.controls.email) || null, 'Email'),
      ]);
      form.controls.phonePrivate.setValidators([
        requiredWhen(() => form && !form.value.phoneBusiness, 'business phone is empty'),
        phoneNumberValidator(),
        Validators.maxLength(50),
      ]);
      form.controls.phoneBusiness.setValidators([
        requiredWhen(() => form && !form.value.phonePrivate, 'private phone is empty'),
        phoneNumberValidator(),
        Validators.maxLength(50),
      ]);
      if (tenant.requiresPlaceOfBirth) {
        form.controls.placeOfBirth.setValidators([Validators.required, Validators.maxLength(100)]);
      }
      if (tenant.id === TENANT_ID.GOETHE) {
        form.controls.confirmPersonalData.setValidators(Validators.requiredTrue);
      }

      if (tenant.id === TENANT_ID.TCF) {
        form.controls.confirmPersonalData.setValidators(Validators.requiredTrue);
        form.controls.countryOfBirth.setValidators(Validators.required);
        form.controls.nativeLanguage.setValidators(Validators.required);
        form.controls.nationality.setValidators([
          Validators.required,
          inject(NationalityValidator).getValidator(),
        ]);
        form.controls.confirmTCFBaseModule.setValidators(Validators.requiredTrue);
      }
      const nationalityValidator = inject(NationalityValidator).getValidator();
      registrationApi
        .getRegistrationExam(examCrmId)
        .pipe(distinctUntilChanged())
        .subscribe((examData) => {
          const examType = examTypeProvider.getExamTypeById(examData.examTypeId);
          if (examType === ExamTypes.LINGUASKILL) {
            form.controls.identityCardNumber.setValidators([
              Validators.required,
              Validators.maxLength(100),
            ]);
            form.controls.nationality.setValidators([Validators.required, nationalityValidator]);
          } else {
            form.controls.identityCardNumber.clearValidators();
            form.controls.nationality.clearValidators();
          }
        });
    }
  });
  return form;
}

function createBillingForm(): FormGroup<BillingDto> {
  let form: FormGroup<BillingDto>;
  const address = createAddressForm(() => form && form.value.billingAddressDiffers);
  form = new FormGroup<BillingDto>({
    billingAddressDiffers: new FormControl<boolean>(false),
    address,
  });
  return form;
}

function createTermsAndConditionsForm(): FormGroup<TermsAndConditionsDto> {
  const form = new FormGroup<TermsAndConditionsDto>({
    termsAndConditionsAccepted: new FormControl<boolean>(false, Validators.requiredTrue),
  });
  form.setValidators(tcoValidator());
  return form;
}

function createSpecialArrangementsForm(): FormGroup<SpecialArrangementsDto> {
  const form = new FormGroup<SpecialArrangementsDto>({
    requiresSpecialArrangements: new FormControl<boolean>(false),
    // TODO: Should have drop down with reasons?
    comment: new FormControl<string>(''),
  });
  form.controls.comment.setValidators([
    requiredWhen(
      () => form && form.value.requiresSpecialArrangements,
      'special arrangements need to be made',
    ),
    Validators.maxLength(2000),
  ]);
  return form;
}

function createPaymentForm(): FormGroup<PaymentDto> {
  const form = new FormGroup<PaymentDto>({
    method: new FormControl<PaymentDto.MethodEnum>(
      '' as PaymentDto.MethodEnum,
      Validators.required,
    ),
    eroCode: new FormControl<string>(''),
    collectiveInvoiceCode: new FormControl<string>(''),
    eroLatePaymentMethod: new FormControl<PaymentDto.EroLatePaymentMethodEnum>(
      '' as PaymentDto.EroLatePaymentMethodEnum,
    ),
  });
  form.controls.eroCode.setValidators(
    requiredWhen(
      () => form && form.value.method === PaymentDto.MethodEnum.ExamRetakeOption,
      'retake option is selected for payment',
    ),
  );
  form.controls.collectiveInvoiceCode.setValidators(
    requiredWhen(
      () => form && form.value.method === PaymentDto.MethodEnum.CollectiveInvoice,
      'collective invoice is selected for payment',
    ),
  );
  form.controls.method.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
    setTimeout(() => {
      form.controls.eroCode.updateValueAndValidity();
      form.controls.collectiveInvoiceCode.updateValueAndValidity();
    }, 0);
  });
  return form;
}

export function setKnownValuesIfPresent<ModelType extends object>(
  form: FormGroup<ModelType>,
  data?: Partial<ModelType>,
) {
  if (!data) {
    return;
  }
  const { controls } = form;
  Object.keys(controls)
    .map((key) => ({ control: form.controls[key], value: data[key] }))
    .filter(
      ({ control, value }) =>
        !!value && (control instanceof FormControl || control instanceof FormControl),
    )
    .forEach(({ control, value }: { control: FormControl | FormControl; value: any }) =>
      control.setValue(value),
    );
}

function tcoValidator() {
  return (control) => {
    const errors = [];
    traverse(control, {
      onFormControl: (fc) => {
        if (fc.invalid) {
          errors.push(fc.errors);
        }
      },
    });
    return errors.length ? { tcoRequired: true } : null;
  };
}
