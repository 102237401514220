import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EmptyComponent } from './empty.component';
import { LegacyFilterGuard } from './legacy-filter.guard';

const ROUTES: Routes = [
  { path: '', component: EmptyComponent, canActivate: [LegacyFilterGuard] },
  { path: 'de', component: EmptyComponent, canActivate: [LegacyFilterGuard] },
  { path: 'en', component: EmptyComponent, canActivate: [LegacyFilterGuard] },
];
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class AppWildcardRoutingModule {}
