<form [formGroup]="form">
  <div class="split" formGroupName="address">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Salutation' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <mat-select formControlName="salutation" name="salutation">
          <mat-option *ngFor="let salutation of salutations" [value]="salutation">
            {{ '' + salutation | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <sx-validation-messages [control]="addressForm.controls.salutation"></sx-validation-messages>
    </div>
    <div class="split__cell"></div>
  </div>

  <div class="split" formGroupName="address">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'First Name' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="firstName" formControlName="firstName" />
      </mat-form-field>
      <sx-validation-messages [control]="addressForm.controls.firstName"></sx-validation-messages>
    </div>
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Last Name' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="lastName" formControlName="lastName" />
      </mat-form-field>
      <sx-validation-messages [control]="addressForm.controls.lastName"></sx-validation-messages>
    </div>
  </div>

  <div class="split" formGroupName="address">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Street / NR' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="street" formControlName="street" />
      </mat-form-field>
      <sx-validation-messages [control]="addressForm.controls.street"></sx-validation-messages>
    </div>
    <div class="split__cell">
      <mat-form-field>
        <mat-label>{{ 'Additional Address Line' | translate }}</mat-label>
        <input matInput name="streetAddition" formControlName="streetAddition" />
      </mat-form-field>
      <sx-validation-messages
        [control]="addressForm.controls.streetAddition"
      ></sx-validation-messages>
    </div>
  </div>
  <div class="split" formGroupName="address">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Postal Code' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="postalCode" formControlName="postalCode" />
      </mat-form-field>
      <sx-validation-messages [control]="addressForm.controls.postalCode"></sx-validation-messages>
    </div>
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'City' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="city" formControlName="city" />
      </mat-form-field>
      <sx-validation-messages [control]="addressForm.controls.city"></sx-validation-messages>
    </div>
  </div>

  <div class="split">
    <div class="split__cell" formGroupName="address">
      <mat-form-field>
        <mat-label>
          {{ 'Country' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <mat-select formControlName="country" name="salutation">
          <mat-option [value]="''"></mat-option>
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ 'Countries.' + country | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <sx-validation-messages [control]="addressForm.controls.country"></sx-validation-messages>
    </div>
    <div class="split__cell">
      <ng-container *ngIf="tenantService.isTcfTenant || isLinguaskill">
        <mat-form-field>
          <mat-label>
            {{ 'Nationality' | translate }}
            <span class="label--required">*</span>
          </mat-label>
          <input
            type="text"
            [formControl]="nationality"
            name="nationality"
            matInput
            [matAutocomplete]="auto"
          />

          <mat-autocomplete
            name="nationality"
            autoActiveFirstOption
            #auto="matAutocomplete"
            [displayWith]="displaySelectedItem.bind(this)"
          >
            @for (nation of filteredNationalities$ | async; track nation) {
              <mat-option [value]="nation.isoCode">
                {{ nation.displayName }} ({{ nation.isoCode }})
              </mat-option>
            }
          </mat-autocomplete>
          <div class="input-suffix" matSuffix>
            <div
              class="input-suffix__info"
              [matTooltipClass]="'tooltip-lg'"
              matTooltip="{{ 'Nationality Info' | translate }}"
              matTooltipPosition="above"
            >
              <mat-icon class="input-suffix__info__icon input-suffix__info__icon--vertical-center">
                info
              </mat-icon>
            </div>
          </div>
        </mat-form-field>
        <sx-validation-messages [control]="form.controls.nationality"></sx-validation-messages>
      </ng-container>
    </div>
  </div>
  <div class="split" *ngIf="isLinguaskill">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'IdentityCardNumber' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="identityCardNumber" formControlName="identityCardNumber" />
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.identityCardNumber"></sx-validation-messages>
    </div>
  </div>
  <div class="split">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Date of Birth' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input
          autocomplete="off"
          matInput
          name="dateOfBirth"
          formControlName="dateOfBirth"
          [matDatepicker]="datePicker"
        />
        <div class="input-suffix" matSuffix>
          <div class="input-suffix__date-picker">
            <mat-datepicker-toggle [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </div>
          <div
            *ngIf="tenantService.isGoetheTenant || tenantService.isTcfTenant"
            class="input-suffix__info"
            [matTooltipClass]="'tooltip-lg'"
            matTooltip="{{ 'Date of Birth Info' | translate }}"
            matTooltipPosition="above"
          >
            <mat-icon class="input-suffix__info__icon input-suffix__info__icon--vertical-center">
              info
            </mat-icon>
          </div>
        </div>
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.dateOfBirth"></sx-validation-messages>
    </div>
    <div class="split__cell">
      <ng-container *ngIf="tenantService.isTcfTenant">
        <mat-form-field>
          <mat-label>
            {{ 'Country Of Birth' | translate }}
            <span class="label--required">*</span>
          </mat-label>
          <input matInput name="countryOfBirth" formControlName="countryOfBirth" />
          <div class="input-suffix" matSuffix>
            <div
              class="input-suffix__info"
              [matTooltipClass]="'tooltip-lg'"
              matTooltip="{{ 'Country Of Birth Info' | translate }}"
              matTooltipPosition="above"
            >
              <mat-icon class="input-suffix__info__icon input-suffix__info__icon--vertical-center">
                info
              </mat-icon>
            </div>
          </div>
        </mat-form-field>
        <sx-validation-messages [control]="form.controls.countryOfBirth"></sx-validation-messages>
      </ng-container>
      <ng-container *ngIf="tenantService.isCambridgeTenant || tenantService.isGoetheTenant">
        <mat-form-field>
          <mat-label>
            {{ 'Place of Birth' | translate }}
            <span *ngIf="tenantService.isGoetheTenant" class="label--required">*</span>
          </mat-label>
          <input autocomplete="off" matInput name="placeOfBirth" formControlName="placeOfBirth" />
          <div *ngIf="tenantService.isGoetheTenant" class="input-suffix" matSuffix>
            <div
              class="input-suffix__info"
              [matTooltipClass]="'tooltip-lg'"
              matTooltip="{{ 'Place of Birth Info' | translate }}"
              matTooltipPosition="above"
            >
              <mat-icon class="input-suffix__info__icon input-suffix__info__icon--vertical-center">
                info
              </mat-icon>
            </div>
          </div>
        </mat-form-field>
        <sx-validation-messages [control]="form.controls.placeOfBirth"></sx-validation-messages>
      </ng-container>
    </div>
  </div>

  <div class="split" *ngIf="tenantService.isTcfTenant">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Native Language' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="nativeLanguage" formControlName="nativeLanguage" />
        <div class="input-suffix" matSuffix>
          <div
            class="input-suffix__info"
            [matTooltipClass]="'tooltip-lg'"
            matTooltip="{{ 'Native Language Info' | translate }}"
            matTooltipPosition="above"
          >
            <mat-icon class="input-suffix__info__icon input-suffix__info__icon--vertical-center">
              info
            </mat-icon>
          </div>
        </div>
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.nativeLanguage"></sx-validation-messages>
    </div>
    <div class="split__cell"></div>
  </div>

  <div class="split">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Phone Number (Private or Mobile)' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="phonePrivate" formControlName="phonePrivate" />
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.phonePrivate"></sx-validation-messages>
    </div>
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Work Phone Number' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="phoneBusiness" formControlName="phoneBusiness" />
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.phoneBusiness"></sx-validation-messages>
    </div>
  </div>

  <div class="split">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Email' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="email" formControlName="email" />
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.email"></sx-validation-messages>
    </div>
    <div class="split__cell">
      <mat-form-field>
        <mat-label>
          {{ 'Confirm Email' | translate }}
          <span class="label--required">*</span>
        </mat-label>
        <input matInput name="emailConfirmation" formControlName="emailConfirmation" />
      </mat-form-field>
      <sx-validation-messages [control]="form.controls.emailConfirmation"></sx-validation-messages>
    </div>
  </div>
  <br />
  <div *ngIf="tenantService.isGoetheTenant">
    <p>
      {{ 'Confirmation' | translate }}
      <span class="label--required">*</span>
    </p>
    <div class="checkbox-wrapper">
      <mat-checkbox
        formControlName="confirmPersonalData"
        color="primary"
        name="confirmPersonalData"
      >
        <span [innerHTML]="'Personal Data Confirmation Description' | translate"></span>
      </mat-checkbox>
    </div>
    <div>
      <sx-validation-messages
        [control]="form.controls.confirmPersonalData"
        [omitMargin]="true"
      ></sx-validation-messages>
    </div>
  </div>
  <ng-container *ngIf="tenantService.isTcfTenant">
    <div>
      <p>
        {{ 'Confirmation' | translate }}
        <span class="label--required">*</span>
      </p>
      <ng-container>
        <div class="checkbox-wrapper">
          <mat-checkbox
            formControlName="confirmPersonalData"
            color="primary"
            name="confirmPersonalData"
          >
            <span [innerHTML]="'Personal Data Confirmation Description TCF' | translate"></span>
          </mat-checkbox>
        </div>
        <div>
          <sx-validation-messages
            [control]="form.controls.confirmPersonalData"
            [omitMargin]="true"
          ></sx-validation-messages>
        </div>
      </ng-container>
      <ng-container *ngIf="requiresTCFBaseModule">
        <div class="checkbox-wrapper">
          <mat-checkbox
            formControlName="confirmTCFBaseModule"
            color="primary"
            name="confirmTCFBaseModule"
          >
            <span [innerHTML]="'Base Module Confirmation Description TCF' | translate"></span>
          </mat-checkbox>
        </div>
        <div>
          <sx-validation-messages
            [control]="form.controls.confirmTCFBaseModule"
            [omitMargin]="true"
          ></sx-validation-messages>
        </div>
      </ng-container>
    </div>
  </ng-container>
</form>
