import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export const UNLOAD_TRACKER_API_TOKEN = new InjectionToken<string>('UNLOAD_TRACKER_API_TOKEN');

export interface UnloadTrackerRouteGroup {
  paths: string[];
}

export interface UnloadTrackerApi {
  routeGroups: UnloadTrackerRouteGroup[];

  translate(key: string): string;

  showAppDialog(messages: string[]): Observable<boolean>;
}

export class DefaultUnloadTrackerApi implements UnloadTrackerApi {
  // TODO: This should be app specific
  routeGroups: UnloadTrackerRouteGroup[] = [];

  translate(key: string): string {
    return key;
  }

  showAppDialog(messages: string[]): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      observer.next(confirm(messages.join('\n')));
      observer.complete();
    });
  }
}
