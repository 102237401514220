<sx-content-card-section-title [icon]="titleIcon">
  {{ 'Terms and Conditions' | translate }}
</sx-content-card-section-title>
<div *ngFor="let item of termsAndConditions; let i = index" class="checkbox-wrapper">
  <mat-checkbox
    color="primary"
    [(ngModel)]="termsAndConditionsCheckboxes[i].checked"
    [value]="termsAndConditionsCheckboxes[i].value"
    (change)="onChange()"
  >
    <span [innerHTML]="item.label | translate"></span>
    <span class="label--required">*</span>
  </mat-checkbox>
  <a
    *ngIf="!!item.url"
    class="info-icon-button"
    [href]="item.url | translate"
    [target]="item.openInNewTab"
  >
    <mat-icon color="primary">info</mat-icon>
  </a>
</div>
<div>
  <sx-validation-messages [control]="form" [omitMargin]="true"></sx-validation-messages>
</div>
