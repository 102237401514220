<sx-content-card-title [icon]="titleIcon">{{ 'Price' | translate }}</sx-content-card-title>
<div *ngFor="let item of items$ | async" class="payment-item">
  <span class="payment-item__position">
    {{ item.position | translate }}{{ item.annotation || '' }}:
  </span>
  <span class="payment-item__currency">{{ item.currency }}</span>
  <span class="payment-item__amount">{{ item.price | currency: item.currency : '' }}</span>
</div>
<div class="payment-item payment-item--total">
  <span class="payment-item__position">{{ (total$ | async)?.position | translate }}:</span>
  <span class="payment-item__currency">{{ (total$ | async)?.currency }}</span>
  <span class="payment-item__amount">
    {{ (total$ | async)?.price | currency: (total$ | async).currency : '' }}
  </span>
</div>
<div class="margin-top info-message" *ngIf="showAnnotation$ | async">
  <div class="info-message__content">* {{ 'Late Fee Info Text' | translate }}</div>
</div>
