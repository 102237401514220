import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sx-content-card-inner',
  template: `
    <ng-content></ng-content>
  `,
})
export class ContentCardInnerComponent {
  @HostBinding('class')
  cssClass = 'content-card__inner';
}
