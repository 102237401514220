import { ALL_PAYMENT_METHODS, Tenant, TENANT_ID } from './tenant';
import { PaymentDto } from '../../../../generated/api';
import { COUNTRIES } from '../../util/countries';

export const TCF_TENANT: Tenant = {
  paymentMethods: [
    PaymentDto.MethodEnum.CreditCardPostcard,
    PaymentDto.MethodEnum.CollectiveInvoice,
    PaymentDto.MethodEnum.OnlineInvoice,
  ],
  latePaymentMethods: [
    PaymentDto.MethodEnum.CreditCardPostcard,
    PaymentDto.MethodEnum.CollectiveInvoice,
  ],
  id: TENANT_ID.TCF,
  title: 'TCF Exams',
  tagline: 'TCF Tagline',
  contactLogoUrl: 'assets/tcf/lo_swex_autorisedprov_tcf.png',
  contactLogoAlt: 'Swiss Exams - Authorised provider for TCF Exams',
  contactEmail: 'info@swiss-exams.ch',
  contactAddress: 'Swiss Exams\nTCF Centre de passation\n\nZürcherstrasse 46\n8400 Winterthur',
  contactPhone: '052 209 04 50',
  contactEmergencyHelpdesk: `0900 884 884 (-.70/min)`,
  entryUrls: [
    'http://localhost:4203',
    'http://localhost:4003',
    'http://tcf-or-test.swiss-exams.ch',
    'https://tcf-or-test.swiss-exams.ch',
    'http://inscription-tcf.swiss-exams.ch',
    'https://inscription-tcf.swiss-exams.ch',
  ],
  prodUrls: ['http://inscription-tcf.swiss-exams.ch', 'https://inscription-tcf.swiss-exams.ch'],
  googleTagId: 'GTM-N23CZGB',
  googleTagIdForTest: 'GTM-MH585KTB',
  languages: ['en', 'de', 'fr'],
  sxLogoUrl: 'assets/tcf/lo_swex_quer_tcf.png',
  sxLogoAlt: 'Swiss Exams',
  examsLogoUrl: 'assets/tcf/france-education-international-swiss-exams-tcf.png',
  examsLogoLinkEn: 'https://tcf.swiss-exams.ch/en/exams-and-certifications/tcf',
  examsLogoLinkDe: 'https://tcf.swiss-exams.ch/de/exams-and-certifications/tcf',
  examsLogoLinkFr: 'https://tcf.swiss-exams.ch/fr/exams-and-certifications/tcf',
  examsLogoAlt: 'TCF — Test de connaissance du français',
  infoBoxes: [
    {
      type: 'box',
      color: 'red',
      title: 'Compulsory Examinations',
      content: 'Compulsory Examinations Info Box Content',
    },
    {
      type: 'box',
      color: 'gold',
      title: 'Optional Examinations',
      content: 'Optional Examinations Info Box Content',
    },
  ],
  footerHelpDeskColumnOne: `
    <strong>Swiss Exams</strong><br>
    Office Winterthur<br>
    Zürcherstrasse 46<br>
    8400 Winterthur
  `,
  footerLinks: [
    {
      label: 'swiss-exams.ch',
      url: 'https://swiss-exams.ch',
      openInNewTab: true,
    },
    {
      label: 'Imprint',
      url: 'imprint',
    },
    {
      label: 'Privacy Policy',
      url: 'privacy',
    },
  ],
  footerTermsAndConditions: {
    text: 'Read our General Terms and Conditions online TCF',
    link: {
      label: 'General Terms and Conditions',
      url: 'https://www.france-education-international.fr/sites/default/files/medias/file/2021/04/manuel_candidat_toutes-declinaisons-tcf.pdf',
      openInNewTab: true,
    },
  },
  termsAndConditions: [
    {
      label: 'I have read and understood the Privacy Policy',
      url: 'TCF Terms and Conditions URL',
      openInNewTab: true,
    },
    {
      label:
        'I confirm that I am in agreement that my photo is taken on the day of my Speaking Test for security reasons.',
    },
    {
      label:
        'I understand that I can only take the exam if I present valid identification on both exam days (written & speaking test).',
    },
  ],
  shopSupport: false,
  supportedCountries: [...COUNTRIES],
  showSpecialArrangementSection: false,
  showAdditionalInformationOnRegistrationCompletePage: true,
  registrationCompleteAdditionalInformation: 'Registration Complete Additional Information TCF',
  tenantBasedTranslationsKeys: {
    'School Description': 'School Description TCF',
  },
  howToRegisterLinkEn:
    'https://tcf.swiss-exams.ch/exams-and-certifications/tcf/registration/how-to-register',
  howToRegisterLinkDe:
    'https://tcf.swiss-exams.ch/de/exams-and-certifications/tcf/registration/how-to-register',
  howToRegisterLinkFr:
    'https://tcf.swiss-exams.ch/fr/exams-and-certifications/tcf/registration/how-to-register',
};
