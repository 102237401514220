import { Component, HostBinding, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { flatten } from 'ramda';
import * as moment from 'moment';
import { isDate, isMoment } from 'moment';

import { TenantFiltersDto, TenantFilterValuesDto } from '../../../../generated/api';
import { Destroyable } from '../../util/destroyable';
import { FilterService } from '../../shared/providers/filter.service';
import { LocationService } from '../../shared/providers/location.service';
import { ICONS } from '../../shared/fontawesome.module';

@Component({
  selector: 'sx-filter',
  templateUrl: './filter.component.html',
})
export class FilterComponent extends Destroyable {
  readonly FilterTypes = TenantFilterValuesDto.TypeEnum;

  @HostBinding('class')
  cssClass = 'content-card__inner filter';

  faFilter = faFilter;

  filters$: Observable<TenantFiltersDto>;
  queryFilters$: Observable<any>;
  linkToFilter$: Observable<SafeUrl>;

  permalinkIcon = ICONS.faExternalLinkAlt;

  form: UntypedFormGroup;

  constructor(
    private filter: FilterService,
    private location: LocationService,
    private domSanitizer: DomSanitizer,
  ) {
    super();
    this.form = filter.form;
    this.filters$ = filter.filters$;
    this.queryFilters$ = filter.queryFilters$;
    const filtersAndQuery$ = combineLatest([this.filters$, this.queryFilters$]);
    filtersAndQuery$
      .pipe(this.takeUntilDestroyed())
      .subscribe(this.filter.setupForm.bind(this.filter));

    this.linkToFilter$ = combineLatest([
      this.filters$,
      filter.filterChanged$.pipe(startWith(this.filter.form.value)),
    ]).pipe(
      map(getUrlForFilter(this.location, this.domSanitizer)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.form.valueChanges
      .pipe(distinctUntilChanged(), this.takeUntilDestroyed())
      .subscribe((value) => {
        this.filter.onFilterChanged(value);
      });
  }

  onClearFilters() {
    this.form.reset();
  }

  getJoinedMultiselectFilterValueNames(tenantFilterValueDto: TenantFilterValuesDto): string {
    const formValues = this.form.get(tenantFilterValueDto.key)?.value as string[];

    if (!formValues?.length) {
      return;
    }

    // We perform this lookup only if the filter key is 'level' because in case
    // of a level-filter the saved form value is the id (GUID) compared to the other
    // filters (ex. location) where the saved form value is the readable name and
    // can therefore be returned directly.
    if (tenantFilterValueDto.key === 'level') {
      const filterValueNames: string[] = formValues.map((formValue) => {
        const match = tenantFilterValueDto.filterValues.find(
          (filterValue) => filterValue.id === formValue,
        );

        if (match) {
          return match.name;
        }
      });

      return filterValueNames.join(', ');
    }

    return formValues.join(', ');
  }
}

export function getUrlForFilter(location: LocationService, domSanitizer: DomSanitizer) {
  return function ([filters, query]) {
    const origin = location.getOrigin();
    const allFilters: TenantFilterValuesDto[] = flatten(
      filters.filterRows.map((row) => row.filterValues),
    );
    const queryParams = allFilters.reduce((acc, f) => {
      let value = query[f.key];
      const param = getValueForFilter(query[f.key], f);
      if (!param) {
        return acc;
      }
      return [...acc, `${f.key}=${param}`];
    }, []);
    return queryParams.length
      ? domSanitizer.bypassSecurityTrustUrl(`${origin}/list?${queryParams.join('&')}`)
      : null;
  };
}

function getValueForFilter(value, f: TenantFilterValuesDto) {
  if (Array.isArray(value)) {
    return getValueForArrayFilter(value, f);
  } else if (isDate(value)) {
    return moment(value).format('YYYY-MM-DD');
  } else if (isMoment(value)) {
    return value.format('YYYY-MM-DD');
  } else if (value) {
    return encodeURIComponent(value);
  }
}
function getValueForArrayFilter(value: any[], f: TenantFilterValuesDto) {
  value = value.filter((v) => !!v);
  const labels = value
    .map((v) => {
      let filterName = f.filterValues.find((fv) => fv.id === v)?.name;
      if (filterName.includes('%year')) {
        filterName = filterName.replace('%year', moment(Date.now()).format('YYYY'));
      }
      return filterName;
    })
    .filter((l) => !!l);
  return labels.map(encodeURIComponent).join(';');
}
