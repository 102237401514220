import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
}

if (environment.enableSentry) {
  const SWISS_EXAMS_SUBDOMAINS_REGEX = /[a-z-]+\.swiss-exams\.ch/i;

  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.env,
    release: environment.sentryVersion,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: environment.sentryTracesSampleRate,
    tracePropagationTargets: ['localhost', SWISS_EXAMS_SUBDOMAINS_REGEX],
  });
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, {})
    .catch((error) => console.error(error));
});
