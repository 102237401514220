import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { InitializerService } from './initializer/initializer.service';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class ConfigInitializedGuard {
  constructor(private initializer: InitializerService) {}

  canActivate(): Observable<boolean> {
    return this.initializer.initialized$.pipe(
      filter((value) => !!value),
      first(),
    );
  }
}
