<sx-content-card-inner>
  <sx-content-card-section>
    <sx-content-card-title [icon]="iconTitle">
      {{ 'Speaking Test' | translate }}
    </sx-content-card-title>
    <sx-content-card-sub-title *ngIf="tenantService.isCambridgeTenant" [innerHTML]="'Speaking Test Description' | translate">
    </sx-content-card-sub-title>
    <sx-content-card-sub-title *ngIf="tenantService.isGoetheTenant" [innerHTML]="'Speaking Test Goethe Description' | translate">
    </sx-content-card-sub-title>
    <sx-content-card-sub-title *ngIf="tenantService.isTcfTenant" [innerHTML]="'Speaking Test TCF Description' | translate">
    </sx-content-card-sub-title>
  </sx-content-card-section>
  <sx-content-card-section>
    <sx-speaking-form></sx-speaking-form>
  </sx-content-card-section>
</sx-content-card-inner>
