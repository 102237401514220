import { Component, HostBinding, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'sx-content-card-sub-title',
  template: `
    <ng-content></ng-content>
  `,
})
export class ContentCardSubTitleComponent {
  @HostBinding('class')
  cssClass = 'content-card__sub-title ';
}
