import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImprintComponent } from './imprint.component';
import { SharedModule } from '../shared/shared.module';
import { PrivacyComponent } from './privacy.component';
import { StaticPagesRoutingModule } from './static-pages-routing.module';

const DECLARE_AND_EXPORT = [ImprintComponent, PrivacyComponent];

@NgModule({
  declarations: [...DECLARE_AND_EXPORT],
  imports: [CommonModule, SharedModule, StaticPagesRoutingModule],
  exports: [...DECLARE_AND_EXPORT],
})
export class StaticPagesModule {}
