import { Injectable } from '@angular/core';
import { CacheBase } from '../../../util/cache-base';
import { RegistrationExamProductDto, RegistrationApi } from '../../../../../generated/api';
import { Observable } from 'rxjs';

@Injectable()
export class ProductCache extends CacheBase<
  RegistrationExamProductDto,
  { productId: string; examId: string }
> {
  constructor(private api: RegistrationApi) {
    super();
  }

  protected fetch({ productId, examId }): Observable<RegistrationExamProductDto> {
    return this.api.getProduct(productId, examId);
  }

  protected getIdentifier({ productId, examId }): string {
    return `${productId}:${examId}`;
  }
}
