import { Inject, Injectable, Optional } from '@angular/core';
import { EntryReservationDto } from '../../../../../generated/api';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrationProcessService } from '../registration-process.service';
import { ReservationCache } from '../cache/reservation.cache';
import { REQUEST } from '../../../../express.tokens';

@Injectable()
export class EntryReservationResolver {
  constructor(
    private cache: ReservationCache,
    private process: RegistrationProcessService,
    @Optional()
    @Inject(REQUEST)
    private request,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<EntryReservationDto> | null {
    if (this.request) {
      return null;
    }
    const reservationId = this.process.registration.examData.reservationCrmId;
    if (!reservationId) {
      return null;
    }
    return this.cache.get(reservationId);
  }
}
