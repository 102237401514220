import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { RegistrationApi } from '../../../generated/api';
import { RegistrationProcessService } from '../shared/providers/registration-process.service';

@Component({
  selector: `sx-init-view`,
  template: '',
})
export class InitViewComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private process: RegistrationProcessService,
    private api: RegistrationApi,
  ) {
    route.params.pipe(first()).subscribe((params) => {
      process.initEntry(params.examId, params.productId, params.reservationId);
      router.navigate(['/', 'candidate'], { replaceUrl: true });
    });
  }
}
