<div class="registration-process__back-link">
  <a [routerLink]="['/', 'list']">
    <fa-icon [icon]="backIcon"></fa-icon>
    {{ 'Back to Exams List' | translate }}
  </a>
</div>

<p class="registration-process__status-message">{{ 'Registration successful' | translate }}</p>

<div
  *ngIf="tenantService.tenant.showAdditionalInformationOnRegistrationCompletePage"
  class="additional-information__container"
>
  <p [innerHTML]="tenantService.tenant?.registrationCompleteAdditionalInformation | translate"></p>
</div>

<div class="content-card" *ngIf="tenantService.tenant.shopSupport">
  <sx-content-card-inner>
    <sx-content-card-section>
      <sx-content-card-title [icon]="icon">{{ 'Online Shop' | translate }}</sx-content-card-title>
      <sx-content-card-sub-title>
        {{ 'Online Shop Description' | translate }}
      </sx-content-card-sub-title>
    </sx-content-card-section>

    <sx-content-card-section>
      <img class="img-responsive" [src]="voucherImageUrl" alt="Online Shop Voucher" />
    </sx-content-card-section>

    <sx-content-card-section>
      <div class="voucher-section">
        <span class="voucher-section__label">{{ 'Voucher Code' | translate }}</span>
        <span class="voucher-section__voucher">{{ voucher }}</span>
        <a [href]="callToActionLink" mat-flat-button color="primary" target="_blank">
          {{ callToActionLabel | translate }}
        </a>
      </div>
    </sx-content-card-section>
  </sx-content-card-inner>
</div>
