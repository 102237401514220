import { DatePipe } from './date.pipe';
import { DateTimePipe } from './date-time.pipe';
import { TimePipe } from './time.pipe';
import { ToStringIfNonePipe } from './to-string-if-none.pipe';

/**
 * Time zone offset for CET
 */
export const CET_TIMEZONE = '+0100';

export const pipes = [
  // Pipes
  DatePipe,
  DateTimePipe,
  TimePipe,
  ToStringIfNonePipe,
];
