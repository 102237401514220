export const countries: { code: string; name_en: string; name_fr: string; name_de: string }[] = [
  { code: 'AF', name_en: 'Afghanistan', name_fr: 'Afghanistan', name_de: 'Afghanistan' },
  { code: 'AX', name_en: 'Aland Islands', name_fr: 'Îles Åland', name_de: 'Åland-Inseln' },
  { code: 'AL', name_en: 'Albania', name_fr: 'Albanie', name_de: 'Albanien' },
  { code: 'DZ', name_en: 'Algeria', name_fr: 'Algérie', name_de: 'Algerien' },
  {
    code: 'AS',
    name_en: 'American Samoa',
    name_fr: 'Samoa américaines',
    name_de: 'Amerikanisch-Samoa',
  },
  { code: 'AD', name_en: 'Andorra', name_fr: 'Andorre', name_de: 'Andorra' },
  { code: 'AO', name_en: 'Angola', name_fr: 'Angola', name_de: 'Angola' },
  { code: 'AI', name_en: 'Anguilla', name_fr: 'Anguilla', name_de: 'Anguilla' },
  { code: 'AQ', name_en: 'Antarctica', name_fr: 'Antarctique', name_de: 'Antarktis' },
  {
    code: 'AG',
    name_en: 'Antigua and Barbuda',
    name_fr: 'Antigua-et-Barbuda',
    name_de: 'Antigua und Barbuda',
  },
  { code: 'AR', name_en: 'Argentina', name_fr: 'Argentine', name_de: 'Argentinien' },
  { code: 'AM', name_en: 'Armenia', name_fr: 'Arménie', name_de: 'Armenien' },
  { code: 'AW', name_en: 'Aruba', name_fr: 'Aruba', name_de: 'Aruba' },
  { code: 'AU', name_en: 'Australia', name_fr: 'Australie', name_de: 'Australien' },
  { code: 'AT', name_en: 'Austria', name_fr: 'Autriche', name_de: 'Österreich' },
  { code: 'AZ', name_en: 'Azerbaijan', name_fr: 'Azerbaïdjan', name_de: 'Aserbaidschan' },
  { code: 'BS', name_en: 'Bahamas', name_fr: 'Bahamas', name_de: 'Bahamas' },
  { code: 'BH', name_en: 'Bahrain', name_fr: 'Bahreïn', name_de: 'Bahrain' },
  { code: 'BD', name_en: 'Bangladesh', name_fr: 'Bangladesh', name_de: 'Bangladesch' },
  { code: 'BB', name_en: 'Barbados', name_fr: 'Barbade', name_de: 'Barbados' },
  { code: 'BY', name_en: 'Belarus', name_fr: 'Biélorussie', name_de: 'Weißrussland' },
  { code: 'BE', name_en: 'Belgium', name_fr: 'Belgique', name_de: 'Belgien' },
  { code: 'BZ', name_en: 'Belize', name_fr: 'Belize', name_de: 'Belize' },
  { code: 'BJ', name_en: 'Benin', name_fr: 'Bénin', name_de: 'Benin' },
  { code: 'BM', name_en: 'Bermuda', name_fr: 'Bermudes', name_de: 'Bermuda' },
  { code: 'BT', name_en: 'Bhutan', name_fr: 'Bhoutan', name_de: 'Bhutan' },
  { code: 'BO', name_en: 'Bolivia', name_fr: 'Bolivie', name_de: 'Bolivien' },
  {
    code: 'BQ',
    name_en: 'Bonaire, Sint Eustatius and Saba',
    name_fr: 'Bonaire, Saint-Eustache et Saba',
    name_de: 'Bonaire, Sint Eustatius und Saba',
  },
  {
    code: 'BA',
    name_en: 'Bosnia and Herzegovina',
    name_fr: 'Bosnie-Herzégovine',
    name_de: 'Bosnien und Herzegowina',
  },
  { code: 'BW', name_en: 'Botswana', name_fr: 'Botswana', name_de: 'Botsuana' },
  { code: 'BV', name_en: 'Bouvet Island', name_fr: 'Île Bouvet', name_de: 'Bouvetinsel' },
  { code: 'BR', name_en: 'Brazil', name_fr: 'Brésil', name_de: 'Brasilien' },
  {
    code: 'IO',
    name_en: 'British Indian Ocean Territory',
    name_fr: "Territoire britannique de l'océan Indien",
    name_de: 'Britisches Territorium im Indischen Ozean',
  },
  {
    code: 'BN',
    name_en: 'Brunei Darussalam',
    name_fr: 'Brunei Darussalam',
    name_de: 'Brunei Darussalam',
  },
  { code: 'BG', name_en: 'Bulgaria', name_fr: 'Bulgarie', name_de: 'Bulgarien' },
  { code: 'BF', name_en: 'Burkina Faso', name_fr: 'Burkina Faso', name_de: 'Burkina Faso' },
  { code: 'BI', name_en: 'Burundi', name_fr: 'Burundi', name_de: 'Burundi' },
  { code: 'KH', name_en: 'Cambodia', name_fr: 'Cambodge', name_de: 'Kambodscha' },
  { code: 'CM', name_en: 'Cameroon', name_fr: 'Cameroun', name_de: 'Kamerun' },
  { code: 'CA', name_en: 'Canada', name_fr: 'Canada', name_de: 'Kanada' },
  { code: 'CV', name_en: 'Cape Verde', name_fr: 'Cap-Vert', name_de: 'Kap Verde' },
  { code: 'KY', name_en: 'Cayman Islands', name_fr: 'Îles Caïmans', name_de: 'Kaimaninseln' },
  {
    code: 'CF',
    name_en: 'Central African Republic',
    name_fr: 'République centrafricaine',
    name_de: 'Zentralafrikanische Republik',
  },
  { code: 'TD', name_en: 'Chad', name_fr: 'Tchad', name_de: 'Tschad' },
  { code: 'CL', name_en: 'Chile', name_fr: 'Chili', name_de: 'Chile' },
  { code: 'CN', name_en: 'China', name_fr: 'Chine', name_de: 'China' },
  { code: 'CX', name_en: 'Christmas Island', name_fr: 'Île Christmas', name_de: 'Weihnachtsinsel' },
  {
    code: 'CC',
    name_en: 'Cocos (Keeling) Islands',
    name_fr: 'Îles Cocos (Keeling)',
    name_de: 'Kokosinseln',
  },
  { code: 'CO', name_en: 'Colombia', name_fr: 'Colombie', name_de: 'Kolumbien' },
  { code: 'KM', name_en: 'Comoros', name_fr: 'Comores', name_de: 'Komoren' },
  { code: 'CG', name_en: 'Congo', name_fr: 'Congo', name_de: 'Kongo' },
  {
    code: 'CD',
    name_en: 'Congo, The Democratic Republic of',
    name_fr: 'République démocratique du Congo',
    name_de: 'Demokratische Republik Kongo',
  },
  { code: 'CK', name_en: 'Cook Islands', name_fr: 'Îles Cook', name_de: 'Cookinseln' },
  { code: 'CR', name_en: 'Costa Rica', name_fr: 'Costa Rica', name_de: 'Costa Rica' },
  { code: 'CI', name_en: "Cote d'Ivoire", name_fr: "Côte d'Ivoire", name_de: 'Elfenbeinküste' },
  { code: 'HR', name_en: 'Croatia', name_fr: 'Croatie', name_de: 'Kroatien' },
  { code: 'CU', name_en: 'Cuba', name_fr: 'Cuba', name_de: 'Kuba' },
  { code: 'CW', name_en: 'Curaçao', name_fr: 'Curaçao', name_de: 'Curaçao' },
  { code: 'CY', name_en: 'Cyprus', name_fr: 'Chypre', name_de: 'Zypern' },
  { code: 'CZ', name_en: 'Czechia', name_fr: 'Tchéquie', name_de: 'Tschechien' },
  { code: 'DK', name_en: 'Denmark', name_fr: 'Danemark', name_de: 'Dänemark' },
  { code: 'DJ', name_en: 'Djibouti', name_fr: 'Djibouti', name_de: 'Dschibuti' },
  { code: 'DM', name_en: 'Dominica', name_fr: 'Dominique', name_de: 'Dominica' },
  {
    code: 'DO',
    name_en: 'Dominican Republic',
    name_fr: 'République dominicaine',
    name_de: 'Dominikanische Republik',
  },
  { code: 'EC', name_en: 'Ecuador', name_fr: 'Équateur', name_de: 'Ecuador' },
  { code: 'EG', name_en: 'Egypt', name_fr: 'Égypte', name_de: 'Ägypten' },
  { code: 'SV', name_en: 'El Salvador', name_fr: 'Salvador', name_de: 'El Salvador' },
  {
    code: 'GQ',
    name_en: 'Equatorial Guinea',
    name_fr: 'Guinée équatoriale',
    name_de: 'Äquatorialguinea',
  },
  { code: 'ER', name_en: 'Eritrea', name_fr: 'Érythrée', name_de: 'Eritrea' },
  { code: 'EE', name_en: 'Estonia', name_fr: 'Estonie', name_de: 'Estland' },
  { code: 'ET', name_en: 'Ethiopia', name_fr: 'Éthiopie', name_de: 'Äthiopien' },
  {
    code: 'FK',
    name_en: 'Falkland Islands (Malvinas)',
    name_fr: 'Îles Falkland (Malouines)',
    name_de: 'Falklandinseln (Malvinas)',
  },
  { code: 'FO', name_en: 'Faroe Islands', name_fr: 'Îles Féroé', name_de: 'Färöer-Inseln' },
  { code: 'FJ', name_en: 'Fiji', name_fr: 'Fidji', name_de: 'Fidschi' },
  { code: 'FI', name_en: 'Finland', name_fr: 'Finlande', name_de: 'Finnland' },
  { code: 'FR', name_en: 'France', name_fr: 'France', name_de: 'Frankreich' },
  {
    code: 'GF',
    name_en: 'French Guiana',
    name_fr: 'Guyane française',
    name_de: 'Französisch-Guayana',
  },
  {
    code: 'PF',
    name_en: 'French Polynesia',
    name_fr: 'Polynésie française',
    name_de: 'Französisch-Polynesien',
  },
  {
    code: 'TF',
    name_en: 'French Southern Territories',
    name_fr: 'Terres australes françaises',
    name_de: 'Französische Süd- und Antarktisgebiete',
  },
  { code: 'GA', name_en: 'Gabon', name_fr: 'Gabon', name_de: 'Gabun' },
  { code: 'GM', name_en: 'Gambia', name_fr: 'Gambie', name_de: 'Gambia' },
  { code: 'GE', name_en: 'Georgia', name_fr: 'Géorgie', name_de: 'Georgien' },
  { code: 'DE', name_en: 'Germany', name_fr: 'Allemagne', name_de: 'Deutschland' },
  { code: 'GH', name_en: 'Ghana', name_fr: 'Ghana', name_de: 'Ghana' },
  { code: 'GI', name_en: 'Gibraltar', name_fr: 'Gibraltar', name_de: 'Gibraltar' },
  { code: 'GR', name_en: 'Greece', name_fr: 'Grèce', name_de: 'Griechenland' },
  { code: 'GL', name_en: 'Greenland', name_fr: 'Groenland', name_de: 'Grönland' },
  { code: 'GD', name_en: 'Grenada', name_fr: 'Grenade', name_de: 'Grenada' },
  { code: 'GP', name_en: 'Guadeloupe', name_fr: 'Guadeloupe', name_de: 'Guadeloupe' },
  { code: 'GU', name_en: 'Guam', name_fr: 'Guam', name_de: 'Guam' },
  { code: 'GT', name_en: 'Guatemala', name_fr: 'Guatemala', name_de: 'Guatemala' },
  { code: 'GG', name_en: 'Guernsey', name_fr: 'Guernesey', name_de: 'Guernsey' },
  { code: 'GN', name_en: 'Guinea', name_fr: 'Guinée', name_de: 'Guinea' },
  { code: 'GW', name_en: 'Guinea-Bissau', name_fr: 'Guinée-Bissau', name_de: 'Guinea-Bissau' },
  { code: 'GY', name_en: 'Guyana', name_fr: 'Guyana', name_de: 'Guyana' },
  { code: 'HT', name_en: 'Haiti', name_fr: 'Haïti', name_de: 'Haiti' },
  {
    code: 'HM',
    name_en: 'Heard and Mc Donald Islands',
    name_fr: 'Îles Heard-et-MacDonald',
    name_de: 'Heard und McDonaldinseln',
  },
  {
    code: 'VA',
    name_en: 'Holy See (Vatican City State)',
    name_fr: 'Saint-Siège (État de la Cité du Vatican)',
    name_de: 'Heiliger Stuhl (Vatikanstadt)',
  },
  { code: 'HN', name_en: 'Honduras', name_fr: 'Honduras', name_de: 'Honduras' },
  { code: 'HK', name_en: 'Hong Kong', name_fr: 'Hong Kong', name_de: 'Hongkong' },
  { code: 'HU', name_en: 'Hungary', name_fr: 'Hongrie', name_de: 'Ungarn' },
  { code: 'IS', name_en: 'Iceland', name_fr: 'Islande', name_de: 'Island' },
  { code: 'IN', name_en: 'India', name_fr: 'Inde', name_de: 'Indien' },
  { code: 'ID', name_en: 'Indonesia', name_fr: 'Indonésie', name_de: 'Indonesien' },
  {
    code: 'IR',
    name_en: 'Iran, Islamic Republic of',
    name_fr: "Iran, République islamique d'",
    name_de: 'Iran (Islamische Republik)',
  },
  { code: 'IQ', name_en: 'Iraq', name_fr: 'Irak', name_de: 'Irak' },
  { code: 'IE', name_en: 'Ireland', name_fr: 'Irlande', name_de: 'Irland' },
  { code: 'IM', name_en: 'Isle of Man', name_fr: 'Île de Man', name_de: 'Isle of Man' },
  { code: 'IL', name_en: 'Israel', name_fr: 'Israël', name_de: 'Israel' },
  { code: 'IT', name_en: 'Italy', name_fr: 'Italie', name_de: 'Italien' },
  { code: 'JM', name_en: 'Jamaica', name_fr: 'Jamaïque', name_de: 'Jamaika' },
  { code: 'JP', name_en: 'Japan', name_fr: 'Japon', name_de: 'Japan' },
  { code: 'JE', name_en: 'Jersey', name_fr: 'Jersey', name_de: 'Jersey' },
  { code: 'JO', name_en: 'Jordan', name_fr: 'Jordanie', name_de: 'Jordanien' },
  { code: 'KZ', name_en: 'Kazakhstan', name_fr: 'Kazakhstan', name_de: 'Kasachstan' },
  { code: 'KE', name_en: 'Kenya', name_fr: 'Kenya', name_de: 'Kenia' },
  { code: 'KI', name_en: 'Kiribati', name_fr: 'Kiribati', name_de: 'Kiribati' },
  {
    code: 'KP',
    name_en: "Korea, Democratic People's Republic of",
    name_fr: 'Corée, République populaire démocratique de',
    name_de: 'Korea, Demokratische Volksrepublik',
  },
  {
    code: 'KR',
    name_en: 'Korea, Republic of',
    name_fr: 'Corée, République de',
    name_de: 'Korea, Republik',
  },
  { code: 'XK', name_en: 'Kosovo', name_fr: 'Kosovo', name_de: 'Kosovo' },
  { code: 'KW', name_en: 'Kuwait', name_fr: 'Koweït', name_de: 'Kuwait' },
  { code: 'KG', name_en: 'Kyrgyzstan', name_fr: 'Kirghizistan', name_de: 'Kirgisistan' },
  {
    code: 'LA',
    name_en: "Lao, People's Democratic Republic",
    name_fr: 'Laos, République démocratique populaire',
    name_de: 'Laos, Demokratische Volksrepublik',
  },
  { code: 'LV', name_en: 'Latvia', name_fr: 'Lettonie', name_de: 'Lettland' },
  { code: 'LB', name_en: 'Lebanon', name_fr: 'Liban', name_de: 'Libanon' },
  { code: 'LS', name_en: 'Lesotho', name_fr: 'Lesotho', name_de: 'Lesotho' },
  { code: 'LR', name_en: 'Liberia', name_fr: 'Libéria', name_de: 'Liberia' },
  {
    code: 'LY',
    name_en: 'Libyan Arab Jamahiriya',
    name_fr: 'Libye',
    name_de: 'Libysch-Arabische Dschamahirija',
  },
  { code: 'LI', name_en: 'Liechtenstein', name_fr: 'Liechtenstein', name_de: 'Liechtenstein' },
  { code: 'LT', name_en: 'Lithuania', name_fr: 'Lituanie', name_de: 'Litauen' },
  { code: 'LU', name_en: 'Luxembourg', name_fr: 'Luxembourg', name_de: 'Luxemburg' },
  { code: 'MO', name_en: 'Macao', name_fr: 'Macao', name_de: 'Macao' },
  {
    code: 'MK',
    name_en: 'North Macedonia',
    name_fr: 'Macédoine du Nord',
    name_de: 'Nordmazedonien',
  },
  { code: 'MG', name_en: 'Madagascar', name_fr: 'Madagascar', name_de: 'Madagaskar' },
  { code: 'MW', name_en: 'Malawi', name_fr: 'Malawi', name_de: 'Malawi' },
  { code: 'MY', name_en: 'Malaysia', name_fr: 'Malaisie', name_de: 'Malaysia' },
  { code: 'MV', name_en: 'Maldives', name_fr: 'Maldives', name_de: 'Malediven' },
  { code: 'ML', name_en: 'Mali', name_fr: 'Mali', name_de: 'Mali' },
  { code: 'MT', name_en: 'Malta', name_fr: 'Malte', name_de: 'Malta' },
  { code: 'MH', name_en: 'Marshall Islands', name_fr: 'Îles Marshall', name_de: 'Marshallinseln' },
  { code: 'MQ', name_en: 'Martinique', name_fr: 'Martinique', name_de: 'Martinique' },
  { code: 'MR', name_en: 'Mauritania', name_fr: 'Mauritanie', name_de: 'Mauretanien' },
  { code: 'MU', name_en: 'Mauritius', name_fr: 'Maurice', name_de: 'Mauritius' },
  { code: 'YT', name_en: 'Mayotte', name_fr: 'Mayotte', name_de: 'Mayotte' },
  { code: 'MX', name_en: 'Mexico', name_fr: 'Mexique', name_de: 'Mexiko' },
  {
    code: 'FM',
    name_en: 'Micronesia, Federated States of',
    name_fr: 'Micronésie, États fédérés de',
    name_de: 'Mikronesien, Föderierte Staaten von',
  },
  {
    code: 'MD',
    name_en: 'Moldova, Republic of',
    name_fr: 'Moldova, République de',
    name_de: 'Moldau, Republik',
  },
  { code: 'MC', name_en: 'Monaco', name_fr: 'Monaco', name_de: 'Monaco' },
  { code: 'MN', name_en: 'Mongolia', name_fr: 'Mongolie', name_de: 'Mongolei' },
  { code: 'ME', name_en: 'Montenegro', name_fr: 'Monténégro', name_de: 'Montenegro' },
  { code: 'MS', name_en: 'Montserrat', name_fr: 'Montserrat', name_de: 'Montserrat' },
  { code: 'MA', name_en: 'Morocco', name_fr: 'Maroc', name_de: 'Marokko' },
  { code: 'MZ', name_en: 'Mozambique', name_fr: 'Mozambique', name_de: 'Mosambik' },
  { code: 'MM', name_en: 'Myanmar', name_fr: 'Myanmar', name_de: 'Myanmar' },
  { code: 'NA', name_en: 'Namibia', name_fr: 'Namibie', name_de: 'Namibia' },
  { code: 'NR', name_en: 'Nauru', name_fr: 'Nauru', name_de: 'Nauru' },
  { code: 'NP', name_en: 'Nepal', name_fr: 'Népal', name_de: 'Nepal' },
  { code: 'NL', name_en: 'Netherlands', name_fr: 'Pays-Bas', name_de: 'Niederlande' },
  {
    code: 'AN',
    name_en: 'Netherlands Antilles',
    name_fr: 'Antilles néerlandaises',
    name_de: 'Niederländische Antillen',
  },
  { code: 'NC', name_en: 'New Caledonia', name_fr: 'Nouvelle-Calédonie', name_de: 'Neukaledonien' },
  { code: 'NZ', name_en: 'New Zealand', name_fr: 'Nouvelle-Zélande', name_de: 'Neuseeland' },
  { code: 'NI', name_en: 'Nicaragua', name_fr: 'Nicaragua', name_de: 'Nicaragua' },
  { code: 'NE', name_en: 'Niger', name_fr: 'Niger', name_de: 'Niger' },
  { code: 'NG', name_en: 'Nigeria', name_fr: 'Nigéria', name_de: 'Nigeria' },
  { code: 'NU', name_en: 'Niue', name_fr: 'Niue', name_de: 'Niue' },
  { code: 'NF', name_en: 'Norfolk Island', name_fr: 'Île Norfolk', name_de: 'Norfolkinsel' },
  {
    code: 'MP',
    name_en: 'Northern Mariana Islands',
    name_fr: 'Îles Mariannes du Nord',
    name_de: 'Nördliche Marianen',
  },
  { code: 'NO', name_en: 'Norway', name_fr: 'Norvège', name_de: 'Norwegen' },
  { code: 'OM', name_en: 'Oman', name_fr: 'Oman', name_de: 'Oman' },
  { code: 'PK', name_en: 'Pakistan', name_fr: 'Pakistan', name_de: 'Pakistan' },
  { code: 'PW', name_en: 'Palau', name_fr: 'Palaos', name_de: 'Palau' },
  {
    code: 'PS',
    name_en: 'State of Palestine',
    name_fr: 'État de Palestine',
    name_de: 'Staat Palästina',
  },
  { code: 'PA', name_en: 'Panama', name_fr: 'Panama', name_de: 'Panama' },
  {
    code: 'PG',
    name_en: 'Papua New Guinea',
    name_fr: 'Papouasie-Nouvelle-Guinée',
    name_de: 'Papua-Neuguinea',
  },
  { code: 'PY', name_en: 'Paraguay', name_fr: 'Paraguay', name_de: 'Paraguay' },
  { code: 'PE', name_en: 'Peru', name_fr: 'Pérou', name_de: 'Peru' },
  { code: 'PH', name_en: 'Philippines', name_fr: 'Philippines', name_de: 'Philippinen' },
  { code: 'PN', name_en: 'Pitcairn', name_fr: 'Pitcairn', name_de: 'Pitcairn' },
  { code: 'PL', name_en: 'Poland', name_fr: 'Pologne', name_de: 'Polen' },
  { code: 'PT', name_en: 'Portugal', name_fr: 'Portugal', name_de: 'Portugal' },
  { code: 'PR', name_en: 'Puerto Rico', name_fr: 'Porto Rico', name_de: 'Puerto Rico' },
  { code: 'QA', name_en: 'Qatar', name_fr: 'Qatar', name_de: 'Katar' },
  { code: 'RE', name_en: 'Réunion', name_fr: 'La Réunion', name_de: 'Réunion' },
  { code: 'RO', name_en: 'Romania', name_fr: 'Roumanie', name_de: 'Rumänien' },
  {
    code: 'RU',
    name_en: 'Russian Federation',
    name_fr: 'Fédération de Russie',
    name_de: 'Russische Föderation',
  },
  { code: 'RW', name_en: 'Rwanda', name_fr: 'Rwanda', name_de: 'Ruanda' },
  { code: 'SH', name_en: 'Saint Helena', name_fr: 'Sainte-Hélène', name_de: 'Sankt Helena' },
  {
    code: 'KN',
    name_en: 'Saint Kitts and Nevis',
    name_fr: 'Saint-Kitts-et-Nevis',
    name_de: 'St. Kitts und Nevis',
  },
  { code: 'LC', name_en: 'Saint Lucia', name_fr: 'Sainte-Lucie', name_de: 'St. Lucia' },
  {
    code: 'PM',
    name_en: 'Saint Pierre and Miquelon',
    name_fr: 'Saint-Pierre-et-Miquelon',
    name_de: 'St. Pierre und Miquelon',
  },
  {
    code: 'VC',
    name_en: 'Saint Vincent and the Grenadines',
    name_fr: 'Saint-Vincent-et-les Grenadines',
    name_de: 'St. Vincent und die Grenadinen',
  },
  { code: 'WS', name_en: 'Samoa', name_fr: 'Samoa', name_de: 'Samoa' },
  { code: 'SM', name_en: 'San Marino', name_fr: 'Saint-Marin', name_de: 'San Marino' },
  {
    code: 'ST',
    name_en: 'Sao Tome and Principe',
    name_fr: 'Sao Tomé-et-Principe',
    name_de: 'São Tomé und Príncipe',
  },
  { code: 'SA', name_en: 'Saudi Arabia', name_fr: 'Arabie saoudite', name_de: 'Saudi-Arabien' },
  { code: 'SN', name_en: 'Senegal', name_fr: 'Sénégal', name_de: 'Senegal' },
  { code: 'RS', name_en: 'Serbia', name_fr: 'Serbie', name_de: 'Serbien' },
  { code: 'SC', name_en: 'Seychelles', name_fr: 'Seychelles', name_de: 'Seychellen' },
  { code: 'SL', name_en: 'Sierra Leone', name_fr: 'Sierra Leone', name_de: 'Sierra Leone' },
  { code: 'SG', name_en: 'Singapore', name_fr: 'Singapour', name_de: 'Singapur' },
  { code: 'SK', name_en: 'Slovakia', name_fr: 'Slovaquie', name_de: 'Slowakei' },
  { code: 'SI', name_en: 'Slovenia', name_fr: 'Slovénie', name_de: 'Slowenien' },
  { code: 'SB', name_en: 'Solomon Islands', name_fr: 'Îles Salomon', name_de: 'Salomonen' },
  { code: 'SO', name_en: 'Somalia', name_fr: 'Somalie', name_de: 'Somalia' },
  { code: 'ZA', name_en: 'South Africa', name_fr: 'Afrique du Sud', name_de: 'Südafrika' },
  {
    code: 'GS',
    name_en: 'South Georgia and the South Sandwich Islands',
    name_fr: 'Géorgie du Sud et îles Sandwich du Sud',
    name_de: 'Südgeorgien und die Südlichen Sandwichinseln',
  },
  { code: 'SS', name_en: 'South Sudan', name_fr: 'Soudan du Sud', name_de: 'Südsudan' },
  { code: 'ES', name_en: 'Spain', name_fr: 'Espagne', name_de: 'Spanien' },
  { code: 'LK', name_en: 'Sri Lanka', name_fr: 'Sri Lanka', name_de: 'Sri Lanka' },
  { code: 'SD', name_en: 'Sudan', name_fr: 'Soudan', name_de: 'Sudan' },
  { code: 'SR', name_en: 'Suriname', name_fr: 'Suriname', name_de: 'Suriname' },
  {
    code: 'SJ',
    name_en: 'Svalbard and Jan Mayen',
    name_fr: 'Svalbard et Jan Mayen',
    name_de: 'Svalbard und Jan Mayen',
  },
  { code: 'SZ', name_en: 'Swaziland', name_fr: 'Eswatini', name_de: 'Swasiland' },
  { code: 'SE', name_en: 'Sweden', name_fr: 'Suède', name_de: 'Schweden' },
  { code: 'CH', name_en: 'Switzerland', name_fr: 'Suisse', name_de: 'Schweiz' },
  {
    code: 'SY',
    name_en: 'Syrian Arab Republic',
    name_fr: 'République arabe syrienne',
    name_de: 'Syrien, Arabische Republik',
  },
  {
    code: 'TW',
    name_en: 'Taiwan, Province of China',
    name_fr: 'Taïwan, Province de Chine',
    name_de: 'Taiwan, Provinz von China',
  },
  { code: 'TJ', name_en: 'Tajikistan', name_fr: 'Tadjikistan', name_de: 'Tadschikistan' },
  {
    code: 'TZ',
    name_en: 'Tanzania, United Republic of',
    name_fr: 'Tanzanie, République-Unie de',
    name_de: 'Tansania, Vereinigte Republik',
  },
  { code: 'TH', name_en: 'Thailand', name_fr: 'Thaïlande', name_de: 'Thailand' },
  { code: 'TL', name_en: 'Timor-Leste', name_fr: 'Timor-Leste', name_de: 'Timor-Leste' },
  { code: 'TG', name_en: 'Togo', name_fr: 'Togo', name_de: 'Togo' },
  { code: 'TK', name_en: 'Tokelau', name_fr: 'Tokelau', name_de: 'Tokelau' },
  { code: 'TO', name_en: 'Tonga', name_fr: 'Tonga', name_de: 'Tonga' },
  {
    code: 'TT',
    name_en: 'Trinidad and Tobago',
    name_fr: 'Trinité-et-Tobago',
    name_de: 'Trinidad und Tobago',
  },
  { code: 'TN', name_en: 'Tunisia', name_fr: 'Tunisie', name_de: 'Tunesien' },
  { code: 'TR', name_en: 'Turkey', name_fr: 'Turquie', name_de: 'Türkei' },
  { code: 'TM', name_en: 'Turkmenistan', name_fr: 'Turkménistan', name_de: 'Turkmenistan' },
  {
    code: 'TC',
    name_en: 'Turks and Caicos Islands',
    name_fr: 'Îles Turques-et-Caïques',
    name_de: 'Turks- und Caicosinseln',
  },
  { code: 'TV', name_en: 'Tuvalu', name_fr: 'Tuvalu', name_de: 'Tuvalu' },
  { code: 'UG', name_en: 'Uganda', name_fr: 'Ouganda', name_de: 'Uganda' },
  { code: 'UA', name_en: 'Ukraine', name_fr: 'Ukraine', name_de: 'Ukraine' },
  {
    code: 'AE',
    name_en: 'United Arab Emirates',
    name_fr: 'Émirats arabes unis',
    name_de: 'Vereinigte Arabische Emirate',
  },
  {
    code: 'GB',
    name_en: 'United Kingdom',
    name_fr: 'Royaume-Uni',
    name_de: 'Vereinigtes Königreich',
  },
  {
    code: 'US',
    name_en: 'United States',
    name_fr: 'États-Unis',
    name_de: 'Vereinigte Staaten von Amerika',
  },
  {
    code: 'UM',
    name_en: 'United States Minor Outlying Islands',
    name_fr: 'Îles mineures éloignées des États-Unis',
    name_de: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
  },
  { code: 'UY', name_en: 'Uruguay', name_fr: 'Uruguay', name_de: 'Uruguay' },
  { code: 'UZ', name_en: 'Uzbekistan', name_fr: 'Ouzbékistan', name_de: 'Usbekistan' },
  { code: 'VU', name_en: 'Vanuatu', name_fr: 'Vanuatu', name_de: 'Vanuatu' },
  {
    code: 'VE',
    name_en: 'Venezuela, Bolivarian Republic of',
    name_fr: 'Venezuela, République bolivarienne du',
    name_de: 'Venezuela, Bolivarische Republik',
  },
  { code: 'VN', name_en: 'Viet Nam', name_fr: 'Viet Nam', name_de: 'Vietnam' },
  {
    code: 'VG',
    name_en: 'Virgin Islands, British',
    name_fr: 'Îles Vierges britanniques',
    name_de: 'Britische Jungferninseln',
  },
  {
    code: 'VI',
    name_en: 'Virgin Islands, U.S.',
    name_fr: 'Îles Vierges américaines',
    name_de: 'Amerikanische Jungferninseln',
  },
  {
    code: 'WF',
    name_en: 'Wallis and Futuna',
    name_fr: 'Wallis-et-Futuna',
    name_de: 'Wallis und Futuna',
  },
  { code: 'EH', name_en: 'Western Sahara', name_fr: 'Sahara occidental', name_de: 'Westsahara' },
  { code: 'YE', name_en: 'Yemen', name_fr: 'Yémen', name_de: 'Jemen' },
  { code: 'ZM', name_en: 'Zambia', name_fr: 'Zambie', name_de: 'Sambia' },
  { code: 'ZW', name_en: 'Zimbabwe', name_fr: 'Zimbabwe', name_de: 'Simbabwe' },
];
