import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PageNotFoundViewComponent } from './page-not-found.view';
import { ConfigInitializedGuard } from '../core/config-initialized.guard';

const ROUTES: Routes = [
  {
    path: 'page-not-found',
    component: PageNotFoundViewComponent,
    canActivate: [ConfigInitializedGuard],
  },
  { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' },
];
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class PageNotFoundRoutingModule {}
