<div class="registration-process__back-link">
  <a [routerLink]="['/', 'list']">
    <fa-icon [icon]="backIcon"></fa-icon>
    {{ 'Back to Exams List' | translate }}
  </a>
</div>

<div class="container">
  <div class="container__two-cells">
    <div class="content-card">
      <sx-steps [highlightedStep]="3"></sx-steps>

      <div class="content-card__inner">
        <sx-exam [exam]="exam$ | async" [selectedProduct]="(product$ | async)?.name"></sx-exam>
      </div>

      <sx-content-card-inner>
        <sx-content-card-section>
          <sx-price></sx-price>

          <div class="margin-top--quadruple">
            <sx-payment-method></sx-payment-method>
          </div>
        </sx-content-card-section>
      </sx-content-card-inner>

      <sx-server-validation-errors [errors]="errors$ | async"></sx-server-validation-errors>

      <div class="registration-process__action">
        <a [routerLink]="['/', 'summary']" mat-button color="primary">
          {{ 'Previous' | translate }}
        </a>
        <button
          mat-flat-button
          color="primary"
          (click)="onSubmit()"
          [disabled]="submitButtonDisabled"
        >
          {{ 'Complete' | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- Sidebar on desktop -->
  <div *isDesktop class="container__one-cell padding-left">
    <sx-sidebar [onlyShowContact]="true"></sx-sidebar>
  </div>

  <!-- Buttons shown on mobile devices only -->
  <div *isBelowDesktop>
    <button
      mat-fab
      color="primary"
      class="mobile-only__fab-button-right"
      aria-label="Information"
      (click)="onShowMobileInfo()"
    >
      <mat-icon>info</mat-icon>
    </button>
  </div>
</div>
