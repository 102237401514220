import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Configuration } from '../../../../generated/api';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { getUrlFromRequest, TenantService } from '../tenant/tenant.service';
import { REQUEST } from '../../../express.tokens';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  private readonly initializedSubject = new BehaviorSubject<boolean>(false);
  readonly initialized$: Observable<boolean> = this.initializedSubject.asObservable();

  get initialized() {
    return this.initializedSubject.getValue();
  }

  set initialized(initialized: boolean) {
    this.initializedSubject.next(initialized);
  }

  constructor(
    private configuration: Configuration,
    private http: HttpClient,
    @Inject(PLATFORM_ID)
    private platformId,
    private tenantService: TenantService,
    private meta: Meta,
  ) {}

  async initialize() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        await this.initializeInBrowser();
      } else {
        await this.initializeInServer();
      }
    } catch (error) {
      console.log('Failed to load config.');
      console.error(error);
      this.configuration.basePath = 'http://localhost:3501';
    }
  }

  async initializeInBrowser() {
    const config: any = (await this.http.get('assets/config.json').toPromise()) || {};
    this.configuration.basePath = config.api || 'http://localhost:3501';
    this.meta.removeTag('name="robots"');
    this.meta.addTag({
      name: 'robots',
      content: this.tenantService.isProduction ? 'index, follow' : 'noindex, nofollow',
    });
    this.initialized = true;
  }

  async initializeInServer() {
    const config: any = (await lastValueFrom(this.http.get('assets/server.config.json'))) || {};
    this.configuration.basePath = config.api;
    this.initialized = true;
  }
}
