import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { InitRoutingModule } from './init-routing.module';
import { InitViewComponent } from './init.view';
import { LegacyRedirectGuard } from './legacy-redirect.guard';
import { RedirectFailedComponent } from './redirect-failed.component';

const DECLARE_AND_EXPORT = [InitViewComponent];

@NgModule({
  imports: [SharedModule, InitRoutingModule],
  declarations: [...DECLARE_AND_EXPORT, RedirectFailedComponent],
  providers: [LegacyRedirectGuard],
  exports: [...DECLARE_AND_EXPORT],
})
export class InitModule {}
