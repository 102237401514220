import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CandidatePersonalDataComponent } from './components/candidate-personal-data.component';
import { CandidateBillingAddressComponent } from './components/candidate-billing-address.component';
import { CandidateSpeakingTestComponent } from './components/candidate-speaking-test.component';
import { CandidateSchoolComponent } from './components/candidate-school.component';
import { CandidateRetakeOptionComponent } from './components/candidate-retake-option.component';
import { CandidateSpecialArrangementsComponent } from './components/candidate-special-arrangements.component';
import { CandidateViewComponent } from './candidate.view';
import { CandidateRoutingModule } from './candidate-routing.module';

const DECLARE_AND_EXPORT = [CandidateViewComponent];

@NgModule({
  imports: [SharedModule, CandidateRoutingModule],
  declarations: [
    ...DECLARE_AND_EXPORT,
    CandidatePersonalDataComponent,
    CandidateBillingAddressComponent,
    CandidateSpeakingTestComponent,
    CandidateSchoolComponent,
    CandidateRetakeOptionComponent,
    CandidatePersonalDataComponent,
    CandidateBillingAddressComponent,
    CandidateSpecialArrangementsComponent,
  ],
  providers: [],
  exports: [...DECLARE_AND_EXPORT],
})
export class CandidateModule {}
