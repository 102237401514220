import { Inject, Injectable, Optional } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrationProcessService } from '../providers/registration-process.service';
import { map } from 'rxjs/operators';
import { REQUEST } from '../../../express.tokens';

@Injectable()
export class RegistrationInitializedGuard {
  constructor(
    private process: RegistrationProcessService,
    private router: Router,
    @Optional()
    @Inject(REQUEST)
    private request,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | boolean {
    // In SSR mode we want to ignore the checks
    if (this.request) {
      return true;
    }
    return this.process.registration$.pipe(
      map(
        (entry) =>
          !!entry &&
          !!entry.examData &&
          !!entry.examData.productCrmId &&
          !!entry.examData.examCrmId,
      ),
      map((canActivate) => canActivate || this.router.createUrlTree(['/', 'list'])),
    );
  }
}
