import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'sx-content-card-section',
  template: `
    <div class="content-card__section-content">
      <ng-content></ng-content>
    </div>
  `,
})
export class ContentCardSectionComponent {
  @HostBinding('class')
  cssClass = 'content-card__section';
}
