import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RegistrationProcessService } from '../providers/registration-process.service';
import { traverse } from '../../util/form/traverse';
import { REQUEST } from '../../../express.tokens';

@Injectable()
export class EntryValidationGuard {
  constructor(
    private process: RegistrationProcessService,
    private router: Router,
    @Optional()
    @Inject(REQUEST)
    private request,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): UrlTree | boolean {
    // In SSR mode we want to ignore the checks
    if (this.request) {
      return true;
    }
    const steps = route.data.stepsToBeValid;
    const fallback = route.data.fallbackRegistrationStep;
    if (!steps || !steps.length) {
      console.warn('No steps defined for validation guard');
      return true;
    }
    this.process.forms.onCandidate();
    for (const step of steps) {
      const form = this.process.forms.getForm(step);
      traverse(form, {
        onControl: (control) => {
          control.updateValueAndValidity();
          control.markAsTouched();
        },
      });
      const isValid = form.valid;
      if (!isValid) {
        return this.router.createUrlTree(['/', fallback]);
      }
    }
    return true;
  }
}
