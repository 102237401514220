import { Component } from '@angular/core';
import { TenantService } from 'src/app/core/tenant/tenant.service';
import { ICONS } from '../../shared/fontawesome.module';

@Component({
  selector: 'sx-candidate-speaking-test',
  templateUrl: 'candidate-speaking-test.component.html',
})
export class CandidateSpeakingTestComponent {
  iconTitle = ICONS.faMicrophone;

  constructor(public tenantService: TenantService) {}
}
