<sx-content-card-inner>
  <sx-content-card-section>
    <sx-content-card-title [icon]="iconTitle">
      {{ 'Personal Data' | translate }}
    </sx-content-card-title>
    <sx-content-card-sub-title>
      {{ 'Personal Data Description' | translate }}
    </sx-content-card-sub-title>
  </sx-content-card-section>
  <sx-content-card-section>
    <sx-personal-data-form
      [examTypeId]="examTypeId"
      [examSubType]="examSubType"
    ></sx-personal-data-form>
  </sx-content-card-section>
</sx-content-card-inner>
