import { Component, Inject, OnInit, Optional } from '@angular/core';
import { RESPONSE } from '../../express.tokens';
import { Response } from 'express';
import { ICONS } from '../shared/fontawesome.module';

@Component({
  selector: 'sx-page-not-found-view',
  template: `
    <div class="page-not-found__container">
      <h1 class="page-not-found__title">404</h1>
      <h2 class="page-not-found__subtitle">{{ 'Page not found' | translate }}</h2>
      <p class="page-not-found__paragraph">
        {{ 'The page you are looking for does not exist.' | translate }}
      </p>
      <p class="page-not-found__paragraph">
        <a [routerLink]="['/', 'list']">
          <fa-icon [icon]="backIcon"></fa-icon>
          {{ 'Back to Exams List' | translate }}
        </a>
      </p>
    </div>
  `,
  styles: [
    `
      .page-not-found__container {
        text-align: center;
      }
      .page-not-found__title {
        margin-bottom: 1.5rem;
      }
      .page-not-found__subtitle {
        margin-bottom: 2rem;
      }
      .page-not-found__paragraph {
        padding: 2rem;
      }
    `,
  ],
})
export class PageNotFoundViewComponent implements OnInit {
  backIcon = ICONS.faArrowLeft;

  constructor(
    @Optional()
    @Inject(RESPONSE)
    private response: Response,
  ) {}

  ngOnInit() {
    if (this.response) {
      // response will only be if we have express
      // this.response.statusCode = 404;
      this.response.status(404);
    }
  }
}
