import { Component } from '@angular/core';
import { ICONS } from '../shared/fontawesome.module';
import { Tenant } from '../core/tenant/tenant';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { TenantService } from '../core/tenant/tenant.service';

@Component({
  selector: 'sx-redirect-failed',
  template: `
    <div class="redirect-failed__container">
      <h1 class="redirect-failed__title">:(</h1>
      <h2 class="redirect-failed__subtitle">{{ 'Exam not found' | translate }}</h2>
      <p class="redirect-failed__paragraph">
        {{ 'Your exam link may be misspelled or broken.' | translate }}
      </p>
      <p class="redirect-failed__paragraph">
        {{ 'Please contact our Help Desk, so we can send you a new link:' | translate }}
      </p>
      <div>
        <div *ngIf="contactPhoneUrl" class="sidebar__section-content-part">
          <div>{{ 'Weekday Help-Desk' | translate }}</div>
          <p>
            <a [href]="contactPhoneUrl">{{ tenant.contactPhone }}</a>
          </p>
          <p>
            <a [href]="contactEmailUrl">{{ tenant.contactEmail }}</a>
          </p>
        </div>
      </div>
      <p class="redirect-failed__paragraph">
        <a [routerLink]="['/', 'list']">
          <fa-icon [icon]="backIcon"></fa-icon>
          {{ 'Back to Exams List' | translate }}
        </a>
      </p>
    </div>
  `,
  styles: [
    `
      .redirect-failed__container {
        text-align: center;
      }
      .redirect-failed__title {
        margin-bottom: 1.5rem;
      }
      .redirect-failed__subtitle {
        margin-bottom: 2rem;
      }
      .redirect-failed__paragraph {
        padding: 2rem;
      }
    `,
  ],
})
export class RedirectFailedComponent {
  backIcon = ICONS.faArrowLeft;
  contactIcon = ICONS.faEnvelope;

  tenant: Tenant;
  contactEmailUrl: SafeUrl;
  contactPhoneUrl: SafeUrl;

  constructor(
    private tenantService: TenantService,
    private sanitizer: DomSanitizer,
  ) {
    const { tenant } = tenantService;
    this.tenant = tenant;
    if (tenant.contactEmail) {
      this.contactEmailUrl = sanitizer.bypassSecurityTrustUrl(`mailto:${tenant.contactEmail}`);
    }
    if (tenant.contactPhone) {
      this.contactPhoneUrl = sanitizer.bypassSecurityTrustUrl(
        `tel:${this.removeWhitespaces(tenant.contactPhone)}`,
      );
    }
  }

  private removeWhitespaces(val: string): string {
    return val.replace(/\s/g, '');
  }
}
