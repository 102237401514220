import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CompleteRoutingModule } from './complete-routing.module';
import { RegistrationCompleteViewComponent } from './registration-complete.view';

const DECLARE_AND_EXPORT = [RegistrationCompleteViewComponent];

@NgModule({
  imports: [SharedModule, CompleteRoutingModule],
  declarations: [...DECLARE_AND_EXPORT],
  providers: [],
  exports: [...DECLARE_AND_EXPORT],
})
export class CompleteModule {}
