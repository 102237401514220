import { Component, Input, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGroup } from '@ng-stack/forms';
import { ExamTypes } from '../../../util/exam-types';
import { EroLevelProvider } from '../../providers/ero-level-provider';
import { ExamTypeProvider } from '../../providers/exam-type-provider';
import { RegistrationProcessService } from '../../providers/registration-process.service';
import { SelectItem } from '../../utils/select-item-interface';

@Component({
  selector: 'sx-ero-form',
  template: `
    <form [formGroup]="eroForm">
      <div class="split">
        <div class="split__cell">
          <div class="checkbox-wrapper">
            <mat-checkbox
              (change)="updateRequiredField($event)"
              formControlName="eroSelected"
              color="primary"
              name="eroSelected"
            >
              {{ 'Exam Retake Option ' | translate }} ({{ price | currency: 'CHF' : 'CHF ' }})
            </mat-checkbox>
            <a *ngIf="infoUrl" class="info-icon-button" [href]="infoUrl" target="_blank">
              <mat-icon color="primary">info</mat-icon>
            </a>
          </div>
        </div>
        <div class="split__cell"></div>
      </div>
      <div *ngIf="isLinguaskillExam" class="split">
        <div class="split__cell">
          <mat-form-field>
            <mat-label>Target Level</mat-label>
            <mat-select formControlName="targetLevel" name="targetLevel" #levelSelection>
              @for (level of levels; track level) {
                <mat-option [value]="level.value">{{ level.viewValue }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
          <sx-validation-messages [control]="eroForm.controls.targetLevel"></sx-validation-messages>
        </div>
        <div class="split__cell"></div>
      </div>
    </form>
  `,
})
export class EroFormComponent {
  eroForm: FormGroup;
  levels: SelectItem[];
  isLinguaskillExam: boolean;

  @ViewChild('levelSelection') levelSelection: MatSelect;

  @Input() price: number;
  @Input() infoUrl: string;

  @Input('examTypeId')
  set _examTypeId(examTypeId: string) {
    this.isLinguaskillExam =
      this.examTypeProvider.getExamTypeById(examTypeId) === ExamTypes.LINGUASKILL;
  }

  constructor(
    private process: RegistrationProcessService,
    private examTypeProvider: ExamTypeProvider,
    private eroLevelProvider: EroLevelProvider,
  ) {
    this.eroForm = process.forms.ero;
    this.levels = this.eroLevelProvider.getLevels();
  }

  updateRequiredField(event: MatCheckboxChange) {
    if (!event.checked) {
      this.levelSelection?.options.forEach((data: MatOption) => data.deselect());
    }
    this.eroForm.controls.targetLevel.markAsTouched();
    this.eroForm.controls.targetLevel.updateValueAndValidity();
  }
}
