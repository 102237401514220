import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { Observable, merge } from 'rxjs';
import {
  RegistrationExamProductDto,
  RegistrationDto,
  RegistrationExamDto,
  ValidationErrorDto,
  RegisterResponseDto,
} from '../../../generated/api';
import { RegistrationProcessService } from '../shared/providers/registration-process.service';
import { RegistrationDataViewFacade } from '../shared/providers/registration-data.view-facade';
import { traverse } from '../util/form/traverse';
import { ICONS } from '../shared/fontawesome.module';
import { SidebarComponent } from '../shared/components/sidebar/sidebar.component';
import { BottomSheetService } from '../shared/providers/bottom-sheet.service';
import { Destroyable } from '../util/destroyable';
import { path, equals, anyPass } from 'ramda';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  scan,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PaymentDto } from '../../../generated/api/model/paymentDto';
import { DialogService } from '../shared/providers/dialog.service';

const getPaymentMethod = path(['payment', 'method']);
const isCollectiveInvoice = equals(PaymentDto.MethodEnum.CollectiveInvoice);
const isEro = equals(PaymentDto.MethodEnum.ExamRetakeOption);
const isCollectiveInvoiceOrEro = anyPass([isCollectiveInvoice, isEro]);

const confirmSwitchToFlexTitle = 'Change Package to Flex?';
const confirmSwitchToFlexMessage =
  'Collective Invoice and Exam Retake Option are only possible with the Flex Package (standard). Do you agree to change to Flex?';

@Component({
  selector: 'sx-payment-view',
  templateUrl: 'payment.view.html',
  encapsulation: ViewEncapsulation.None,
  providers: [RegistrationDataViewFacade],
})
export class PaymentViewComponent extends Destroyable {
  @HostBinding('class')
  cssClass = 'registration-process';

  submitButtonDisabled = false;
  registration$: Observable<Partial<RegistrationDto>>;
  backIcon = ICONS.faArrowLeft;
  selectedProduct: RegistrationExamProductDto;
  readonly exam$: Observable<RegistrationExamDto>;
  readonly product$: Observable<RegistrationExamProductDto>;
  readonly errors$: Observable<ValidationErrorDto[] | string | undefined>;

  private originalProduct: RegistrationExamProductDto;
  private hadEroOption: boolean;

  constructor(
    public process: RegistrationProcessService,
    private facade: RegistrationDataViewFacade,
    private bottomSheetService: BottomSheetService,
    private dialog: DialogService,
  ) {
    super();
    this.exam$ = facade.exam$;
    this.product$ = facade.product$;
    this.errors$ = process.validationErrors$;
    facade.product$
      .pipe(
        tap((p) => (this.selectedProduct = p)),
        this.takeUntilDestroyed(),
      )
      .subscribe();
    this.registration$ = process.registration$;
    this.registration$.pipe(first()).subscribe((reg) => {
      this.hadEroOption = reg.candidateData.ero.eroSelected;
    });
    const paymentMethod$ = process.registration$.pipe(
      map(getPaymentMethod),
      distinctUntilChanged(),
    );
    // Handle Flex Case
    paymentMethod$
      .pipe(
        scan((a, b) => {
          if (!isCollectiveInvoiceOrEro(a) && isCollectiveInvoiceOrEro(b)) {
            this.onCollectiveInvoiceOrEroSelected(a);
          } else if (isCollectiveInvoiceOrEro(a) && !isCollectiveInvoiceOrEro(b)) {
            this.onCollectiveInvoiceOrEroDeselected();
          }
          return b;
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe();
    // Handle ERO Case
    paymentMethod$.pipe(this.takeUntilDestroyed()).subscribe((method) => {
      if (method === PaymentDto.MethodEnum.ExamRetakeOption) {
        this.facade.changeEroOptionTo(false);
      } else {
        this.facade.changeEroOptionTo(this.hadEroOption);
      }
    });
  }

  onSubmit() {
    this.submitButtonDisabled = true;
    const form = this.process.forms.main;
    traverse(form, {
      onControl: (control) => {
        control.updateValueAndValidity();
        control.markAsTouched();
      },
    });
    if (!form.valid) {
      this.submitButtonDisabled = false;
      return false;
    }

    // TODO: All code above is synchronous, so we can simply set the button to disabled here and remove the above assignments.

    // If payment method is not collective invoice we can continue the registration
    if (
      this.process.forms.payment.controls.method.value !== PaymentDto.MethodEnum.CollectiveInvoice
    ) {
      this.process.onRegister().subscribe({
        error: this.onRegisterFail.bind(this),
        complete: () => {
          console.log('Registration complete');
          this.persistAnalyticsDataToLocalStorage();
        },
      });
      return;
    }
    if (this.selectedProduct.name === 'Flex') {
      this.process.onRegister().subscribe({
        error: this.onRegisterFail.bind(this),
        complete: () => {
          console.log('Registration complete');
          this.persistAnalyticsDataToLocalStorage();
        },
      });
      return;
    }
    this.onCollectiveInvoiceOrEroSelected(undefined);
  }

  private onRegisterFail() {
    this.submitButtonDisabled = false;
  }

  onShowMobileInfo() {
    this.bottomSheetService.open({
      component: SidebarComponent,
      title: 'Information',
      matIcon: 'info',
      onInstantiated: (component) => (component.onlyShowContact = true),
    });
  }

  onCollectiveInvoiceOrEroSelected(oldPaymentMethod) {
    if (this.selectedProduct.name === 'Flex') {
      return;
    }
    this.dialog
      .confirm(confirmSwitchToFlexTitle, confirmSwitchToFlexMessage)
      .pipe(
        map((confirmed) => (confirmed ? this.selectedProduct : null)),
        // If product was not passed, we need to set back the payment method to the original
        // value.
        tap((product) => {
          if (!product) {
            setTimeout(
              () => this.process.forms.payment.controls.method.setValue(oldPaymentMethod),
              0,
            );
          }
        }),
        // If there is no product user has not confirmed, so we can stop here
        filter(Boolean),
        // If we continue we need the latest exam
        withLatestFrom(this.exam$),
      )
      .subscribe(([originalProduct, exam]: [RegistrationExamProductDto, RegistrationExamDto]) => {
        // Find valid flex product
        const newProduct = exam.products.find((p) => p.name === 'Flex');
        // TODO: What to do if we don't have a flex product? Maybe then the collective
        //        invoice payment method should not be available.
        if (newProduct) {
          // Remember the original product (in case we have to switch back) and change
          // the current product.
          this.originalProduct = originalProduct;
          this.facade.changeProductTo(newProduct);
        }
      });
  }

  onCollectiveInvoiceOrEroDeselected() {
    if (this.originalProduct) {
      this.facade.changeProductTo(this.originalProduct);
      this.originalProduct = null;
    }
  }

  /**
   * SEMP-1319: Online Registration - Tracking Update
   * Persists the current exam, product and registration data to the local storage.
   * This is used to get the data back in the registration-complete view to send the
   * data to the Google Analytics.
   */
  private persistAnalyticsDataToLocalStorage() {
    // We need to use the merge operator here to get the values
    // because one of the obervables never completes and forkJoin would not work here.
    merge(this.exam$, this.product$, this.registration$)
      .pipe(
        tap((value) => {
          if (value.hasOwnProperty('registrationDeadline')) {
            // value is of type exam
            window.localStorage.setItem('GAEVENT-EXAM', JSON.stringify(value));
          }

          if (value.hasOwnProperty('hasFreeSeats')) {
            // value is of type product
            window.localStorage.setItem('GAEVENT-PRODUCT', JSON.stringify(value));
          }

          if (value.hasOwnProperty('candidateData')) {
            // value is of type registration
            window.localStorage.setItem('GAEVENT-REGISTRATION', JSON.stringify(value));
          }
        }),
      )
      .subscribe();
  }
}
