import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class Destroyable implements OnDestroy {
  protected readonly destroyed$ = new Subject<any>();

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  protected takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.destroyed$);
  }
}
