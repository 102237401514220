import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@ng-stack/forms';
import { count, filter, map, Observable, startWith } from 'rxjs';
import { PersonalDto } from '../../../../../generated/api';
import { AddressDto } from '../../../../../generated/api/model/addressDto';
import { TenantService } from '../../../core/tenant/tenant.service';
import { getAllRecordValues } from '../../../util/get-all-record-values';
import { Country } from '../../providers/countries/country';
import { CountryProvider } from '../../providers/countries/country-provider';
import { RegistrationProcessService } from '../../providers/registration-process.service';
import { ExamTypes } from 'src/app/util/exam-types';
import { ExamTypeProvider } from '../../providers/exam-type-provider';

@Component({
  selector: 'sx-personal-data-form',
  templateUrl: 'personal-data-form.component.html',
})
export class PersonalDataFormComponent implements OnInit {
  @Input() private examSubType: string;
  @Input() examTypeId: string;
  form: FormGroup<PersonalDto>;
  addressForm: FormGroup<AddressDto>;
  salutations = getAllRecordValues(AddressDto.SalutationEnum);
  countries: string[];
  nationality: FormControl<string>;
  nationalities: Country[];
  filteredNationalities$: Observable<Country[]>;
  isLinguaskill: boolean;

  constructor(
    private process: RegistrationProcessService,
    private countryProvider: CountryProvider,
    public tenantService: TenantService,
    private examTypeProvider: ExamTypeProvider,
  ) {}

  ngOnInit(): void {
    this.countries = this.tenantService.tenant.supportedCountries;
    this.nationalities = this.countryProvider.countries;
    this.form = this.process.forms.personalData;
    this.addressForm = this.form.controls.address;
    this.nationality = this.form.controls.nationality;
    this.isLinguaskill =
      this.examTypeProvider.getExamTypeById(this.examTypeId) === ExamTypes.LINGUASKILL;
    this.filteredNationalities$ = this.nationality.valueChanges.pipe(
      filter((value) => typeof value === 'string'),
      startWith(''),
      map((searchTerm) => this.filterValue(searchTerm)),
    );

    this.setConfirmTCFBaseModule();
  }

  private setConfirmTCFBaseModule(): void {
    if (this.tenantService.isTcfTenant && !this.requiresTCFBaseModule) {
      this.form.controls.confirmTCFBaseModule.setValue(true);
    }
  }

  // NOTE: this solution is string based and maybe
  // obsolete when the client changes the examSubType label
  get requiresTCFBaseModule(): boolean {
    // pattern for the Compulsory examniations
    const FULL_EXAM_PATTERN = 'CO, SL, CE';

    return !this.examSubType?.includes(FULL_EXAM_PATTERN);
  }

  private filterValue(searchTerm: string): Country[] {
    const filterValue = searchTerm.toLowerCase();
    return this.nationalities.filter(
      (option) =>
        option.displayName.toLowerCase().includes(filterValue) ||
        option.isoCode.toLowerCase().includes(filterValue),
    );
  }

  displaySelectedItem(isoCode: string): string {
    const foundCountry = this.countryProvider.countries.find((value) => value.isoCode === isoCode);
    if (isoCode && foundCountry) {
      let countryDisplayName = foundCountry.displayName;
      let countryIsoCode = foundCountry.isoCode;
      return `${countryDisplayName} (${countryIsoCode})`;
    }
    return '';
  }
}
