import { Component, HostListener } from '@angular/core';
import { ListViewFacade } from './list.view-facade';
import { RegistrationExamsPageDto } from '../../../generated/api';
import { Observable } from 'rxjs';
import { TenantService } from '../core/tenant/tenant.service';
import { Tenant } from '../core/tenant/tenant';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { BottomSheetService } from '../shared/providers/bottom-sheet.service';
import { SidebarComponent } from '../shared/components/sidebar/sidebar.component';
import { ICONS } from '../shared/fontawesome.module';
import { FilterComponent } from './components/filter.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'sx-list-view',
  template: `
    <h1 class="page-title">{{ tenant.title | translate }}</h1>
    <h2 *ngIf="tenant?.subTitle" class="sub-title">{{ tenant.subTitle | translate }}</h2>
    <div class="tag-line" *ngIf="tenant.tagline" [innerHTML]="tenant.tagline | translate"></div>

    <div *ngIf="tenant?.importantNotice" class="important-notice">
      <h3 class="box__title">{{ tenant?.importantNotice.title | translate }}</h3>
      <div [innerHTML]="tenant?.importantNotice.text | translate"></div>
    </div>
    <sx-info-box *ngIf="tenant?.infoBoxes?.length"></sx-info-box>

    <div class="container">
      <div class="container__two-cells">
        <div class="content-card">
          <sx-filter *isDesktop></sx-filter>
          <div class="content-card__inner">
            <sx-exams></sx-exams>
            <div class="flow" *ngIf="isLoading$ | async">
              <div class="flow__resizing-cell"></div>
              <div class="flow__fixed-cell">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
              </div>
              <div class="flow__resizing-cell"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Info box on desktop -->
      <div *isDesktop class="container__one-cell padding-left">
        <sx-sidebar></sx-sidebar>
      </div>
    </div>

    <div id="lazy-load-hint"></div>

    <!-- Buttons shown on mobile devices only -->
    <div *isBelowDesktop>
      <button
        mat-fab
        color="primary"
        class="mobile-only__fab-button-left"
        aria-label="Information"
        (click)="onShowMobileFilter()"
      >
        <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <button
        mat-fab
        color="primary"
        class="mobile-only__fab-button-right"
        aria-label="Information"
        (click)="onShowMobileInfo()"
      >
        <mat-icon>info</mat-icon>
      </button>
    </div>
  `,
  providers: [ListViewFacade],
})
export class ListViewComponent {
  pages$: Observable<RegistrationExamsPageDto[]>;
  tenant: Tenant;
  faFilter = faFilter;
  isLoading$: Observable<boolean>;

  constructor(
    public facade: ListViewFacade,
    private tenantService: TenantService,
    private bottomSheetService: BottomSheetService,
    private titleService: Title,
  ) {
    this.setTitle();
    this.pages$ = facade.pages$;
    this.tenant = tenantService.tenant;
    this.isLoading$ = facade.isLoading$.asObservable();
  }

  setTitle() {
    if (this.tenantService.isCambridgeTenant) {
      this.titleService.setTitle('Cambridge English Exams - Official registration');
    } else if (this.tenantService.isTcfTenant) {
      this.titleService.setTitle('TCF Tout Public - Official registration in Switzerland');
    } else if (this.tenantService.isGoetheTenant) {
      this.titleService.setTitle('Goethe Exams - Official registration in Switzerland');
    }
  }

  onShowMobileFilter() {
    this.bottomSheetService.open({
      component: FilterComponent,
      title: 'Filter',
      faIcon: ICONS.faFilter,
    });
  }

  onShowMobileInfo() {
    this.bottomSheetService.open({
      component: SidebarComponent,
      title: 'Information',
      matIcon: 'info',
      // Example on how to set input
      // onInstantiated: component => component.onlyShowContact = true,
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.facade.onScrolledOrResized();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.facade.onScrolledOrResized();
  }
}
