import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, skip, switchMap, tap } from 'rxjs/operators';
import {
  CompleteResponseDto,
  RegisterResponseDto,
  RegistrationApi,
  RegistrationDto,
  ValidationErrorDto,
} from '../../../../generated/api';
import { TenantService } from '../../core/tenant/tenant.service';
import { isValidationErrors } from '../../util/guards/validation-error-dto.guard';
import { sanitizeRegistrationDates } from '../../util/sanitize-registration-dates';
import { UnloadTracker } from '../unload-tracker/unload-tracker';
import { LocalStorageService } from './local-storage.service';
import { NotificationService } from './notification.service';
import { RegistrationForms } from './registration-forms';
import { ExamTypeProvider } from './exam-type-provider';

export const REGISTRATION_ENTRY_STORAGE_KEY = 'OR.RegistrationEntry';

@Injectable()
export class RegistrationProcessService {
  private readonly registrationSubject = new BehaviorSubject<Partial<RegistrationDto>>({});
  readonly registration$ = this.registrationSubject.asObservable();

  private readonly validationErrorsSubject = new ReplaySubject<
    ValidationErrorDto[] | string | undefined
  >(undefined);

  readonly validationErrors$ = this.validationErrorsSubject.asObservable();

  set validationErrors(validationErrors: ValidationErrorDto[] | undefined) {
    this.validationErrorsSubject.next(validationErrors);
  }

  set errorMessage(validationErrors: string) {
    this.validationErrorsSubject.next(validationErrors);
  }

  get registration(): Partial<RegistrationDto> {
    return this.registrationSubject.getValue();
  }

  set registration(entry: Partial<RegistrationDto>) {
    this.registrationSubject.next(entry);
  }

  readonly tracker = new UnloadTracker('Do you really want to leave the registration?');
  readonly forms: RegistrationForms;

  constructor(
    private storage: LocalStorageService,
    private api: RegistrationApi,
    private router: Router,
    private examTypeProvider: ExamTypeProvider,
    private notifications: NotificationService,
    private tenantService: TenantService,
  ) {
    this.forms = new RegistrationForms(this.tenantService.tenant, this.examTypeProvider, api);
    this.init();
  }

  init() {
    this.registration = this.storage.getAs<Partial<RegistrationDto>>(
      REGISTRATION_ENTRY_STORAGE_KEY,
    );
    this.forms.initFormData(this.registration);

    // Persist entry if changes
    this.registrationSubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe(this.onEntryChange.bind(this));

    this.registrationSubject
      .pipe(
        skip(1),
        map((record) => ({
          examId: (record && record.examData && record.examData.examCrmId) || null,
          reservationId: (record && record.examData && record.examData.reservationCrmId) || null,
        })),
        distinctUntilChanged(
          (a, b) => a.reservationId === b.reservationId && a.examId === b.examId,
        ),
      )
      .subscribe(this.checkIfCanBeRegistered.bind(this));

    this.initForms();
    this.tracker.dirty = true;
  }

  onEntryChange(entry: RegistrationDto) {
    this.storage.put(REGISTRATION_ENTRY_STORAGE_KEY, entry);
  }

  updateValidation(hasSpeakingDate: boolean, isLate: boolean) {
    this.forms.updateValidation(hasSpeakingDate, isLate);
  }

  updateLatePaymentMethodValidation(isLate: boolean) {
    this.forms.updateLatePaymentMethodValidation(isLate);
  }

  private checkIfCanBeRegistered({
    examId,
    reservationId,
  }: {
    examId?: string;
    reservationId?: string;
  }) {
    if (examId) {
      of(true)
        .pipe(
          // Delaying, otherwise config might not be applied yet
          // TODO: Check why this runs before app initializer is finished
          delay(500),
          switchMap(() =>
            reservationId
              ? this.api.canRegisterForExamAndReservation(examId, reservationId)
              : this.api.canRegisterForExam(examId),
          ),
        )
        .subscribe((response) => {
          if (!response.canRegister) {
            this.tracker.dirty = false;
            response.messages.forEach((message) => this.notifications.error(message));
            this.router.navigate(['/']);
          }
        });
    }
  }

  initEntry(examCrmId: string, productCrmId: string, reservationCrmId?: string) {
    this.forms.main.reset();
    try {
      this.forms.examData.setValue({
        examCrmId,
        productCrmId,
        reservationCrmId: reservationCrmId || '',
        examType: '',
        examTypeId: '',
        isSpeakingRequired: false,
      });
      this.tracker.dirty = true;
    } catch (error) {
      console.error(error);
      console.warn('Failed to reload form state. Resetting registration');
      this.forms.main.reset();
    }
  }

  changeProduct(productCrmId: string) {
    this.forms.examData.controls.productCrmId.setValue(productCrmId);
  }

  private initForms() {
    this.forms.main.valueChanges
      .pipe(
        map(() => this.forms.main.getRawValue()),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.registration = value;
      });
  }

  onRegister() {
    this.validationErrors = undefined;
    return this.api.register(sanitizeRegistrationDates(this.forms.main.getRawValue())).pipe(
      tap(this.onRegistrationComplete.bind(this)),
      catchError((error) => {
        this.onRegistrationFailed(error);
        throw error;
      }),
    );
  }

  private onRegistrationComplete(response: RegisterResponseDto) {
    this.tracker.dirty = false;
    if (response.redirectTo === RegisterResponseDto.RedirectToEnum.Result) {
      this.router.navigate(['/', 'complete', response.recordId]);
    } else if (response.redirectTo === RegisterResponseDto.RedirectToEnum.CreditCardProcessor) {
      // Here we have to redirect the user to the external Saferpay page
      window.open(response.redirectUrl, '_self');
    }
  }

  private onRegistrationFailed(response: any) {
    console.error(response);
    if (response && response.error && isValidationErrors(response.error.errors)) {
      this.validationErrors = response.error.errors;
    } else {
      this.errorMessage =
        (response && response.error && response.error.message) || 'An error occurred';
    }
  }

  clear() {
    this.initEntry('', '', '');
  }
}
