import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platform: any) {}

  getAs<T>(key: string, defaultValue?: T): T | undefined {
    if (isPlatformBrowser(this.platform)) {
      const value = localStorage.getItem(key);
      return value !== undefined ? <T>JSON.parse(value) : defaultValue;
    }
  }

  put(key: string, value: any) {
    if (isPlatformBrowser(this.platform)) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  clear(key: string) {
    if (isPlatformBrowser(this.platform)) {
      localStorage.removeItem(key);
    }
  }
}
