import { ValidationErrorDto } from '../../../../generated/api';
import { isString } from './isString';

export function isValidationErrors(errors: any): errors is ValidationErrorDto[] {
  return (
    Array.isArray(errors) && errors.length && !errors.some((error) => !isValidationError(error))
  );
}

export function isValidationError(error: any): error is ValidationErrorDto {
  return !!error && isString(error.path) && isString(error.field) && isString(error.error);
}
