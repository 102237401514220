import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { TenantService } from './tenant.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TenantInterceptor implements HttpInterceptor {
  constructor(private tenantService: TenantService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.tenantService.tenant) {
      return next.handle(req);
    }
    return next.handle(
      req.clone({
        setHeaders: {
          'sx-or-tenant': this.tenantService.tenant.id,
        },
      }),
    );
  }
}
