import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SummaryViewComponent } from './summary.view';
import { SummaryComponent } from './components/summary.component';
import { TermsAndConditionComponent } from './components/terms-and-condition.component';
import { SummaryRoutingModule } from './summary-routing.module';

const DECLARE_AND_EXPORT = [SummaryViewComponent];

@NgModule({
  imports: [SharedModule, SummaryRoutingModule],
  declarations: [...DECLARE_AND_EXPORT, SummaryComponent, TermsAndConditionComponent],
  providers: [],
  exports: [...DECLARE_AND_EXPORT],
})
export class SummaryModule {}
