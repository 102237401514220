<ng-template [ngIf]="!(hasPages$ | async)">
  <div class="no-exams-message">
    <div class="container">
      <div class="container__two-cells">
        <p class="container__title container__title--text-center"
          [innerHTML]="'Did not find your preferred exam date?' | translate"></p>
        <div class="container">
          <div class="container__one-cell">
            <div class="box box--full-height box--bordered">
              <h3 class="box__title">
                <fa-icon [icon]="candidateIcon"></fa-icon>
                {{ 'For Candidates' | translate }}
              </h3>
              <p class="box__link box__link--bold" [innerHTML]="'No results filter info for candidates' | translate">
              </p>
            </div>
          </div>
          <div class="container__one-cell">
            <div class="box box--full-height box--bordered">
              <h3 class="box__title">
                <fa-icon [icon]="schoolIcon"></fa-icon>
                {{ 'For Schools' | translate }}
              </h3>
              <p class="box__link box__link--bold" [innerHTML]="'No results filter info for schools' | translate"></p>
            </div>
          </div>
        </div>
        <div class="container container--centered" *ngIf="isExamFilterIELTS">
          <div class="box box--full-height box--bordered">
            <h3 class="box__title">
              <img draggable="false" src="../../../assets/ielts/ielts-logo.jpg" alt="IELTS" />
              {{ 'For IELTS' | translate }}
            </h3>
            <p class="box__link box__link--bold" [innerHTML]="'For IELTS Description' | translate"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template ngFor let-page [ngForOf]="pages$ | async">
  <ng-template ngFor let-exam [ngForOf]="page.exams">
    <sx-exam [exam]="exam" itemprop="event" itemscope itemtype="https://schema.org/Event"
      [ngClass]="{ 'exam--disabled': exam.openFrom > today }"></sx-exam>
  </ng-template>
</ng-template>