<form [formGroup]="form">
  <div>
    <mat-form-field>
      <mat-label>{{ 'School' | translate }} <span class="label--required">*</span></mat-label>
      <mat-select formControlName="schoolCrmId" name="school">
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let school of schools$ | async" [value]="school.crmId">
          {{ school.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <sx-validation-messages [control]="form.controls.schoolCrmId"></sx-validation-messages>
  </div>
  <div>
    <mat-form-field>
      <mat-label>{{ 'School Name (if not listed)' | translate }}</mat-label>
      <input matInput name="schoolName" formControlName="schoolName" />
    </mat-form-field>
    <sx-validation-messages [control]="form.controls.schoolName"></sx-validation-messages>
  </div>
  <div class="split">
    <div class="split__cell">
      <mat-form-field>
        <mat-label>{{ 'Teacher First Name' | translate }}</mat-label>
        <input matInput name="teacherFirstName" formControlName="teacherFirstName" />
      </mat-form-field>
    </div>
    <div class="split__cell">
      <mat-form-field>
        <mat-label>{{ 'Teacher Last Name' | translate }}</mat-label>
        <input matInput name="teacherLastName" formControlName="teacherLastName" />
      </mat-form-field>
    </div>
  </div>
</form>
