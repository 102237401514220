import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { RegistrationDataViewFacade } from '../shared/providers/registration-data.view-facade';
import { Observable } from 'rxjs';
import {
  RegistrationExamProductDto,
  RegistrationDto,
  RegistrationExamDto,
} from '../../../generated/api';
import { RegistrationProcessService } from '../shared/providers/registration-process.service';
import { traverse } from '../util/form/traverse';
import { ICONS } from '../shared/fontawesome.module';
import { SidebarComponent } from '../shared/components/sidebar/sidebar.component';
import { BottomSheetService } from '../shared/providers/bottom-sheet.service';
import { TenantService } from '../core/tenant/tenant.service';
import { Tenant } from '../core/tenant/tenant';
import { map } from 'rxjs/operators';

@Component({
  selector: 'sx-summary-view',
  templateUrl: 'summary.view.html',
  styleUrls: ['summary.view.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RegistrationDataViewFacade],
})
export class SummaryViewComponent {
  @HostBinding('class')
  cssClass = 'registration-process';

  backIcon = ICONS.faArrowLeft;

  readonly product$: Observable<RegistrationExamProductDto>;
  readonly hasSpeakingDate$: Observable<boolean>;
  exam: RegistrationExamDto;
  registration$: Observable<Partial<RegistrationDto>>;
  submitted = false;
  tenant: Tenant;

  constructor(
    public process: RegistrationProcessService,
    private facade: RegistrationDataViewFacade,
    private bottomSheetService: BottomSheetService,
    private tenantService: TenantService,
  ) {
    this.process.forms.onSummary();
    this.product$ = facade.product$;
    this.registration$ = process.registration$;
    facade.exam$.subscribe((exam: RegistrationExamDto) => {
      this.exam = exam;
    });
    this.hasSpeakingDate$ = facade.exam$.pipe(
      map((exam) => exam && !!(exam.speakingDates || []).length),
    );
    this.tenant = tenantService.tenant;
  }

  onSubmit() {
    this.submitted = true;
    const form = this.process.forms.termsAndCondition;
    traverse(form, {
      onControl: (control) => {
        control.updateValueAndValidity();
        control.markAsTouched();
      },
    });
    if (!form.valid) {
      return false;
    }
  }

  onShowMobileInfo() {
    this.bottomSheetService.open({
      component: SidebarComponent,
      title: 'Information',
      matIcon: 'info',
      onInstantiated: (component) => (component.onlyShowContact = true),
    });
  }
}
