import { Component } from '@angular/core';
import { TenantService } from '../tenant/tenant.service';
import { Tenant } from '../tenant/tenant';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sx-footer',
  template: `
    <div class="footer-container">
      <div *ngIf="tenant?.footerHelpDeskColumnOne" class="footer-container__column">
        <h6 class="footer-container__heading">{{ 'Help Desk' | translate }}</h6>
        <div class="footer-container__content">
          <p [innerHTML]="tenant?.footerHelpDeskColumnOne"></p>
          <p class="footer-container__content__assistance">
            <a [href]="assistanceLink" target="_blank">
              {{ 'Assistance for Candidates' | translate }}
            </a>
          </p>
        </div>
      </div>
      <div
        *ngIf="tenant?.footerLinks && tenant?.footerLinks.length > 0"
        class="footer-container__column"
      >
        <h6 class="footer-container__heading">{{ 'Links' | translate }}</h6>
        <div class="footer-container__content">
          <ul class="footer-container__list">
            <li *ngFor="let link of tenant?.footerLinks" class="footer-container__list__item">
              <a
                class="footer-container__link"
                [href]="link?.url"
                target="{{ link?.openInNewTab ? '_blank' : '_self' }}"
              >
                {{ link?.label | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="tenant?.footerTermsAndConditions" class="footer-container__column">
        <h6 class="footer-container__heading">{{ 'Terms and Conditions' | translate }}</h6>
        <div class="footer-container__content">
          <p
            *ngIf="tenant?.id !== 'goethe'"
            [innerHTML]="
              tenant?.footerTermsAndConditions.text
                | translate: tenant?.footerTermsAndConditions.link
            "
          ></p>
          <p
            *ngIf="tenant?.id === 'goethe'"
            [innerHTML]="tenant?.footerTermsAndConditions.text | translate"
          ></p>
        </div>
      </div>
    </div>
  `,
})
export class FooterComponent {
  tenant: Tenant;
  assistanceLink = environment.assistanceLink;

  constructor(private tenantService: TenantService) {
    this.tenant = tenantService.tenant;
  }
}
