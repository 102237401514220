import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export abstract class CacheBase<Type, RequestParam> {
  protected records: Record<string, Type> = {};

  protected abstract fetch(id: RequestParam): Observable<Type>;

  protected abstract getIdentifier(param: RequestParam): string;

  get(param: RequestParam, force = false): Observable<Type> {
    const identifier = this.getIdentifier(param);
    return !force && this.records[identifier]
      ? of(this.records[identifier])
      : this.fetch(param).pipe(tap((value) => (this.records[identifier] = value)));
  }

  populate(param: RequestParam, item: Type) {
    this.records[this.getIdentifier(param)] = item;
  }
}
