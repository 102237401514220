import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ExamsApi } from '../../../../../generated/api';
import { map, shareReplay } from 'rxjs/operators';

interface StatsData {
  total: number;
  open: number;
  openPercentage: number;
  fullyBooked: number;
  fullyBookedPercentage: number;
}

@Component({
  selector: 'sx-sidebar-stats',
  template: `
    <ng-template [ngIf]="stats$ | async">
      <div class="stat-row">
        <div class="stat-row__circle">
          <circle-progress [percent]="100" [title]="(stats$ | async)?.total"></circle-progress>
        </div>
        <label class="stat-row__text">{{ 'Total' | translate }}</label>
      </div>
      <div class="stat-row">
        <div class="stat-row__circle">
          <circle-progress
            [percent]="(stats$ | async)?.fullyBookedPercentage"
            [title]="(stats$ | async)?.fullyBooked"
          ></circle-progress>
        </div>
        <label class="stat-row__text">{{ 'Fully Booked' | translate }}</label>
      </div>
      <div class="stat-row">
        <div class="stat-row__circle">
          <circle-progress
            [percent]="(stats$ | async)?.openPercentage"
            [title]="(stats$ | async)?.open"
          ></circle-progress>
        </div>
        <label class="stat-row__text">{{ 'Open' | translate }}</label>
      </div>
    </ng-template>
  `,
  styleUrls: ['sidebar-stats.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarStatsComponent {
  stats$: Observable<StatsData>;

  constructor(private examsApi: ExamsApi) {
    this.stats$ = this.examsApi.getStats().pipe(
      map((stats) => {
        const total = stats.totalExams;
        const open = stats.openExams;
        const fullyBooked = stats.totalExams - stats.openExams;
        let openPercentage = 0;
        let fullyBookedPercentage = 0;
        if (total > 0) {
          openPercentage = (100 / total) * open;
          fullyBookedPercentage = (100 / total) * fullyBooked;
        }
        return {
          total,
          open,
          openPercentage,
          fullyBooked,
          fullyBookedPercentage,
        };
      }),
      shareReplay({ refCount: true }),
    );
  }
}
