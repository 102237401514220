import { ValidatorFn } from '@ng-stack/forms';
import { AbstractControl } from '@angular/forms';

export function requiredWhen(
  predicate: () => boolean,
  message: string = 'requiredWhen',
): ValidatorFn {
  return function requiredWhenValidator(control: AbstractControl) {
    if (!predicate()) {
      return null;
    }

    return control.value
      ? null
      : {
          requiredWhen: message,
        };
  };
}
