import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SummaryViewComponent } from './summary.view';
import { RegistrationInitializedGuard } from '../shared/guards/registration-initialized.guard';
import { EntryValidationGuard } from '../shared/guards/entry-validation.guard';
import { RegistrationStep } from '../shared/providers/registration-forms';
import { ExamResolver } from '../shared/providers/resolvers/exam.resolver';
import { EntryProductResolver } from '../shared/providers/resolvers/entry-product.resolver';
import { EntrySchoolsResolver } from '../shared/providers/resolvers/entry-schools.resolver';
import { EntryReservationResolver } from '../shared/providers/resolvers/entry-reservation.resolver';
import { ConfigInitializedGuard } from '../core/config-initialized.guard';

const ROUTES: Routes = [
  {
    path: 'summary',
    component: SummaryViewComponent,
    canActivate: [ConfigInitializedGuard, RegistrationInitializedGuard, EntryValidationGuard],
    data: {
      stepsToBeValid: [RegistrationStep.Candidate],
      fallbackRegistrationStep: 'candidate',
    },
    resolve: {
      exam: ExamResolver,
      product: EntryProductResolver,
      schools: EntrySchoolsResolver,
      reservation: EntryReservationResolver,
    },
  },
];
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class SummaryRoutingModule {}
