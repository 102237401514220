import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../utils/languages.enum';
import { countries } from './countries';
import { Country } from './country';

@Injectable({ providedIn: 'root' })
export class CountryProvider {
  countries: Country[];

  constructor(private translateService: TranslateService) {
    this.countries = countries.map(
      (country) => new Country(country.code, this.getDisplayName(country)),
    );
  }

  private getDisplayName(object: {
    code: string;
    name_en: string;
    name_fr: string;
    name_de: string;
  }): string {
    if (this.translateService.currentLang === Language.EN) {
      return object.name_en;
    } else if (this.translateService.currentLang === Language.DE) {
      return object.name_de;
    } else if (this.translateService.currentLang === Language.FR) {
      return object.name_fr;
    }
    return object.name_en;
  }

  public isCountry(isoCode: string): boolean {
    return this.countries.find((country) => country.isoCode === isoCode) !== undefined;
  }
}
