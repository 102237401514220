import { PaymentDto } from '../../../../generated/api';
import { getAllRecordValues } from '../../util/get-all-record-values';

export interface Tenant {
  id: string;
  title: string;
  subTitle?: string;
  tagline?: string;
  entryUrls: string[];
  prodUrls: string[];
  googleTagId?: string;
  googleTagIdForTest?: string;
  sxLogoUrl: string;
  sxLogoAlt: string;
  examsLogoUrl: string;
  examsLogoLinkEn: string;
  examsLogoLinkDe: string;
  examsLogoLinkFr: string;
  examsLogoAlt: string;
  languages: string[];
  importantNotice?: ImportantNotice;
  infoBoxes?: InfoBoxBox[];
  infoBoxImage?: InfoBoxImage;
  contactLogoUrl?: string;
  contactLogoAlt?: string;
  contactEmail: string;
  contactAddress?: string;
  contactPhone?: string;
  contactEmergencyHelpdesk?: string;
  footerHelpDeskColumnOne?: string;
  footerHelpDeskColumnTwo?: string;
  footerLinks?: Link[];
  footerTermsAndConditions?: { text: string; link: Link };
  paymentMethods: PaymentDto.MethodEnum[];
  latePaymentMethods: PaymentDto.MethodEnum[];
  termsAndConditions: Link[];
  specialArrangementsInfoUrl?: string;
  examRetakeOptionInfoUrl?: string;
  shopSupport: boolean;
  shopVoucherImageUrl?: string;
  shopVoucherCode?: string;
  callToActionUrl?: string;
  callToActionLabel?: string;
  supportedCountries: string[];
  requiresPlaceOfBirth?: boolean;
  showSpecialArrangementSection?: boolean;
  showAdditionalInformationOnRegistrationCompletePage: boolean;
  registrationCompleteAdditionalInformation?: string;
  tenantBasedTranslationsKeys: {
    'School Description': string;
  };
  howToRegisterLinkDe?: string;
  howToRegisterLinkEn?: string;
  howToRegisterLinkFr?: string;
}

export interface ImportantNotice {
  title: string;
  text: string;
}

export interface Link {
  label: string;
  url?: string;
  openInNewTab?: boolean;
}

export interface InfoBoxBase {
  type: string;
}
export interface InfoBoxImage {
  type: 'image';
  url: string;
  alt: string;
}

export interface InfoBoxBox {
  type: 'box';
  color: 'red' | 'gold';
  title: string;
  content: string;
}
export type InfoBox = InfoBoxImage | InfoBoxBox;

export enum TENANT_ID {
  CAMBRIDGE = 'cambridge',
  GOETHE = 'goethe',
  TCF = 'tcf',
}

export const ALL_PAYMENT_METHODS = getAllRecordValues(PaymentDto.MethodEnum);
