import { Component } from '@angular/core';
import { RegistrationProcessService } from '../../providers/registration-process.service';
import { FormGroup } from '@ng-stack/forms';
import { Destroyable } from '../../../util/destroyable';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { AddressDto, BillingDto } from '../../../../../generated/api';
import { getAllRecordValues } from '../../../util/get-all-record-values';
import { Tenant } from '../../../core/tenant/tenant';
import { TenantService } from '../../../core/tenant/tenant.service';

@Component({
  selector: 'sx-billing-form',
  templateUrl: 'billing-form.component.html',
})
export class BillingFormComponent extends Destroyable {
  form: FormGroup<BillingDto>;
  addressForm: FormGroup<AddressDto>;
  expanded$: Observable<boolean>;
  salutations = getAllRecordValues(AddressDto.SalutationEnum);
  countries: string[];

  constructor(
    private process: RegistrationProcessService,
    tenantService: TenantService,
  ) {
    super();
    this.countries = tenantService.tenant.supportedCountries;
    this.form = process.forms.billing;
    this.addressForm = this.form.controls.address;
    this.expanded$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((value: BillingDto) => !!value.billingAddressDiffers),
      distinctUntilChanged(),
      this.takeUntilDestroyed(),
    );
  }
}
