import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {
  BottomSheetContainerComponent,
  BottomSheetContainerData,
} from '../components/bottom-sheet-container/bottom-sheet-container.component';

@Injectable()
export class BottomSheetService {
  constructor(private bottomSheet: MatBottomSheet) {}

  open<T>(data: BottomSheetContainerData<T>): MatBottomSheetRef<BottomSheetContainerComponent> {
    return this.bottomSheet.open(BottomSheetContainerComponent, {
      data,
    });
  }
}
