import { ValidatorFn } from '@ng-stack/forms';

const regex = /^[+]?[0-9 ]*$/;

export function phoneNumberValidator(): ValidatorFn {
  return (control) => {
    const value = (control && control.value) || null;
    if (!value) {
      return null;
    }
    if (typeof value !== 'string') {
      return { phoneNumber: 'isNotAString' };
    }

    if (!regex.test(value)) {
      return { phoneNumber: 'invalidCharacters' };
    }
    return null;
  };
}
