import { Injectable } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { RegistrationExamsPageDto } from '../../../generated/api';

export class StripTranslateHttpLoader extends TranslateHttpLoader {
  constructor(
    private transferState: TransferState,
    private platformId,
    http: HttpClient,
    prefix?: string,
    suffix?: string,
  ) {
    super(http, prefix, suffix);
  }

  private getTransferKey(lang) {
    return makeStateKey<Object>(`translations.${lang}`);
  }

  getTranslation(lang: string): Observable<Object> {
    const isServer = isPlatformServer(this.platformId);
    const stateKey = this.getTransferKey(lang);
    if (!isServer && this.transferState.hasKey(stateKey)) {
      const savedState = this.transferState.get(stateKey, undefined);
      if (savedState) {
        return of(savedState);
      }
    }
    return super.getTranslation(lang).pipe(
      map(stripEmptyTranslations),
      tap((translations) => {
        if (isServer) {
          this.transferState.set(stateKey, translations);
        }
      }),
    );
  }
}

function stripEmptyTranslations(object: Object | string) {
  if (typeof object === 'string') {
    return object.trim() === '' ? undefined : object;
  }
  return Object.keys(object).reduce(
    (acc, key) => ({
      ...acc,
      [key]: stripEmptyTranslations(object[key]),
    }),
    {},
  );
}
